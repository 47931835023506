import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
/* Constants */
import {
  PRIVATE_PARTY_CREATIVE_ID,
  PRIVATE_PARTY_THANK_YOU,
} from 'site-modules/shared/constants/private-party/private-party';
import { PRIVATE_PARTY_EVENTS } from 'site-modules/shared/constants/private-party/private-party-events';
import ModalBgImg from 'client/images/inline-svgs/private-party/modal-bg.svg';
/* Utils */
import { getBgHeight } from 'site-modules/shared/utils/private-party/private-party';
import { EventToolbox } from 'client/utils/event-toolbox';
/* Components */
import { PrivatePartyModalStep } from 'site-modules/shared/components/private-party/private-party-modal/components/private-party-modal-step/private-party-modal-step';

import './private-party-modal.scss';

export function PrivatePartyModal({ isModalOpened, setIsOpened, isMobile, initialStep }) {
  const [currentStep, setCurrentStep] = useState(initialStep);

  useEffect(() => {
    if (currentStep !== PRIVATE_PARTY_THANK_YOU && isModalOpened) {
      EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.WIDGET_VIEW, {
        creativeId: PRIVATE_PARTY_CREATIVE_ID[currentStep],
      });
    }
  }, [currentStep, isModalOpened]);

  const toggleModal = useCallback(() => {
    EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.CLOSE_POPUP, {
      creativeId: PRIVATE_PARTY_CREATIVE_ID[currentStep],
    });
    setIsOpened(!isModalOpened);
  }, [currentStep, isModalOpened, setIsOpened]);

  const handleStepChange = step => setCurrentStep(step);

  return (
    <Modal
      className="private-party-modal"
      contentClassName="p-1_5 rounded-20 private-party-modal-wrapper bg-blue-100 pos-r"
      isOpen={isModalOpened}
      toggle={toggleModal}
      aria-labelledby="private-party-modal-label"
    >
      <img
        className="private-party-modal-bg pos-a top-0 left-0 w-100"
        src={ModalBgImg}
        height={getBgHeight(currentStep, isMobile)}
        alt=""
        loading="lazy"
      />

      <button
        className="p-0 btn btn-link size-20 close-btn pos-a"
        onClick={toggleModal}
        data-testid="toggle-button"
        aria-label="Close dialog"
      >
        <span className="icon-cross2 close-icon text-white" aria-hidden />
      </button>
      <span className="d-none" id="private-party-modal-label">
        List My Car To Sell
      </span>
      <PrivatePartyModalStep step={currentStep} onClick={handleStepChange} isMobile={isMobile} />
    </Modal>
  );
}

PrivatePartyModal.propTypes = {
  setIsOpened: PropTypes.func.isRequired,
  initialStep: PropTypes.string.isRequired,
  isModalOpened: PropTypes.bool,
  isMobile: PropTypes.bool,
};

PrivatePartyModal.defaultProps = {
  isModalOpened: false,
  isMobile: false,
};
