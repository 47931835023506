import React from 'react';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';
import {
  BINARY,
  INPUT,
  RADIO_LIST,
  ZIP,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-types';
import { InputWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/input-wizard-question';
import { ZipInputWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/zip-input-wizard-question';
import { RadioWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/radio-wizard-question';
import { BinaryWizardQuestion } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/binary-wizard-question';

/* eslint-disable react/prop-types */
function createQuestion({
  stepConfig,
  stepMeta,
  answers,
  eligibleIncentiveIds,
  incentives,
  allIncentives,
  onAnswer,
  onBack,
  context,
  flowSteps,
  creativeId,
  activeCategory,
}) {
  let StepComponent;

  switch (stepConfig.type) {
    case INPUT:
      StepComponent = InputWizardQuestion;
      break;
    case ZIP:
      StepComponent = ZipInputWizardQuestion;
      break;
    case RADIO_LIST:
      StepComponent = RadioWizardQuestion;
      break;
    case BINARY:
      StepComponent = BinaryWizardQuestion;
      break;
    default:
      StepComponent = BaseWizardStep;
  }

  return (
    <StepComponent
      stepConfig={stepConfig}
      stepMeta={stepMeta}
      answers={answers}
      eligibleIncentiveIds={eligibleIncentiveIds}
      incentives={incentives}
      allIncentives={allIncentives}
      onAnswer={onAnswer}
      onBack={onBack}
      context={context}
      flowSteps={flowSteps}
      creativeId={creativeId}
      key={stepConfig.id}
      activeCategory={activeCategory}
    />
  );
}

export const incentivesWizardQuestionFactory = {
  createQuestion,
};
