export const UPDATE_INCENTIVES_ACTION = 'UPDATE_INCENTIVES_ACTION';
export const SET_FLOW_STEPS_ACTION = 'SET_FLOW_STEPS_ACTION';
export const SET_CURRENT_STEP_ACTION = 'SET_CURRENT_STEP_ACTION';
export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';
export const ADD_ANSWER_ACTION = 'ADD_ANSWER_ACTION';
export const REMOVE_ANSWER_ACTION = 'REMOVE_ANSWER_ACTION';
export const UPDATE_META_DATA_ACTION = 'UPDATE_META_DATA_ACTION';
export const UPDATE_ACTIVE_CATEGORY_ACTION = 'UPDATE_ACTIVE_CATEGORY_ACTION';
export const UPDATE_ELIGIBLE_INCENTIVES_IDS_ACTION = 'UPDATE_ELIGIBLE_INCENTIVES_IDS_ACTION';
export const REMOVE_ELIGIBLE_INCENTIVES_IDS_ACTION = 'REMOVE_ELIGIBLE_INCENTIVES_IDS_ACTION';
export const ELIGIBILITY_QUESTIONS_QUALIFIER_MAP_ACTION = 'ELIGIBILITY_QUESTIONS_QUALIFIER_MAP_ACTION';
export const REMEMBER_NEW_FLOW_INCENTIVES = 'REMEMBER_NEW_FLOW_INCENTIVES';
export const START_OVER = 'START_OVER';

export const updateIncentives = incentives => ({
  type: UPDATE_INCENTIVES_ACTION,
  incentives,
});

export const setFlowSteps = flowSteps => ({
  type: SET_FLOW_STEPS_ACTION,
  flowSteps,
});

export const setCurrentStepIndex = nextStepIndex => ({
  type: SET_CURRENT_STEP_ACTION,
  nextStepIndex,
});

export const setLoading = loading => ({
  type: SET_LOADING_ACTION,
  loading,
});

export const addAnswer = (id, answer) => ({
  type: ADD_ANSWER_ACTION,
  id,
  answer,
});

export const removeAnswer = id => ({
  type: REMOVE_ANSWER_ACTION,
  id,
});

export const updateMeta = stepMeta => ({
  type: UPDATE_META_DATA_ACTION,
  stepMeta,
});

export const updateActiveCategory = activeCategory => ({
  type: UPDATE_ACTIVE_CATEGORY_ACTION,
  activeCategory,
});

export const addUniqIncentivesIds = ids => ({
  type: UPDATE_ELIGIBLE_INCENTIVES_IDS_ACTION,
  ids,
});

export const removeIncentivesIds = ids => ({
  type: REMOVE_ELIGIBLE_INCENTIVES_IDS_ACTION,
  ids,
});

export const setEligibilityQuestionsQualifierMap = eligibilityQuestionsQualifiersMap => ({
  type: ELIGIBILITY_QUESTIONS_QUALIFIER_MAP_ACTION,
  eligibilityQuestionsQualifiersMap,
});

export const rememberNewFlowIncentives = incentives => ({
  type: REMEMBER_NEW_FLOW_INCENTIVES,
  incentives,
});

export const startOver = initialState => ({
  type: START_OVER,
  initialState,
});
