import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingConstant } from 'client/tracking/constant';
import { AppointmentSurveyMap } from 'client/tracking/maps/appointment-survey/appointment-survey';
import { IS_APPOINTMENT_SURVEY_APPEARED_STORAGE_KEY } from 'site-modules/shared/constants/appointment-survey';
import { modalMousedownTrackingFix } from 'site-modules/shared/utils/modal-tracking-fix';
import { EDMUNDS_LOGO_NO_TEXT } from 'client/images/inline-svgs/edmunds-logo';
import { Link } from 'site-modules/shared/components/link/link';

import './appointment-survey.scss';

const APPOINTMENT_LINK = 'https://calendar.app.google/moLqh9tpjdVSPmMz8';
const QUESTION_ID = 'appointment-question';
const TEXT_AREA_NAME = 'appointment-text';
const STEP = {
  TEXT: 'text',
  APPOINTMENT: 'appointment',
};
const CREATIVE_ID = {
  [STEP.TEXT]: 'phev_survey_screen1',
  [STEP.APPOINTMENT]: 'phev_survey_screen2',
};

export function AppointmentSurvey({ localStorage }) {
  const [isOpen, toggleOpen] = useToggle(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [currentStep, setStep] = useState(STEP.TEXT);
  const isTextChangeTracked = useRef(false);

  useEffect(() => {
    TrackingHandler.tryAddMap(AppointmentSurveyMap);
  }, []);

  useEffect(() => localStorage.set(IS_APPOINTMENT_SURVEY_APPEARED_STORAGE_KEY, true), [localStorage]);

  function onTextChange({ target: { value } }) {
    setSubmitDisabled(!value.trim());

    if (!isTextChangeTracked.current) {
      isTextChangeTracked.current = true;

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
        event_data: {
          action_name: TrackingConstant.ENGAGE_SURVEY,
          subaction_name: 'begin_phev_survey',
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          creative_id: CREATIVE_ID[STEP.TEXT],
        },
      });
    }
  }

  function onTextSubmit(event) {
    event.preventDefault();

    if (!isSubmitDisabled) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ENGAGE_SURVEY,
          subaction_name: 'phev_survey_response',
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          creative_id: CREATIVE_ID[STEP.TEXT],
          value: event.target?.[TEXT_AREA_NAME]?.value,
        },
      });

      setStep(STEP.APPOINTMENT);

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
        event_data: {
          action_name: TrackingConstant.ACTION_WIDGET_VIEW,
          action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
          creative_id: CREATIVE_ID[STEP.APPOINTMENT],
        },
      });
    }
  }

  return (
    <Modal
      modalClassName="p-1 p-md-3_5"
      className="my-0 mx-auto appointment-survey"
      contentClassName="px-1 py-1_5 px-md-2_5 rounded-12"
      isOpen={isOpen}
      toggle={toggleOpen}
      onMouseDown={modalMousedownTrackingFix}
    >
      <Button
        color="link"
        size="lg"
        className="pos-a top-0 right-0 p-0_5 rounded-12"
        aria-label="Close"
        onClick={toggleOpen}
      >
        <i className="icon-cross3 size-20" aria-hidden />
      </Button>
      <div className="text-center mb-0_75">{EDMUNDS_LOGO_NO_TEXT({ fillColor: '#2070e8', width: '32px' })}</div>

      {currentStep === STEP.TEXT && (
        <Form className="pos-r" onSubmit={onTextSubmit} data-tracking-parent={CREATIVE_ID[STEP.TEXT]}>
          <div id={QUESTION_ID} className="size-20 mb-1">
            What information specific to a Plug-In Hybrid are you looking for?
          </div>
          <Input
            className="text-area-input rounded-12 size-16 py-0_5 ps-0_5 pe-3"
            name={TEXT_AREA_NAME}
            type="textarea"
            onChange={onTextChange}
            maxLength={200}
            rows={3}
          />
          <Button
            color="success"
            size="sm"
            type="submit"
            className="submit-btn rounded-8 pos-a bottom-0 right-0 me-0_5 mb-0_5 p-0_5 d-flex justify-content-center align-items-center"
            aria-label="Submit"
            disabled={isSubmitDisabled}
          >
            <i className="icon-arrow-right8 icon text-white size-16" aria-hidden />
          </Button>
        </Form>
      )}

      {currentStep === STEP.APPOINTMENT && (
        <div className="size-20" data-tracking-parent={CREATIVE_ID[STEP.APPOINTMENT]}>
          We’d love to hear more! Please use{' '}
          <Link
            to={APPOINTMENT_LINK}
            target="_blank"
            className="text-underline text-blue-50"
            data-tracking-id="phev_survey_appointment"
          >
            this link
          </Link>{' '}
          to schedule a 30-minute Zoom interview in exchange for a $40 Amazon gift card.
        </div>
      )}

      <div className="text-center mt-2">
        <Button onClick={toggleOpen} color="link" size="lg" className="text-blue-50 text-transform-none fw-normal p-0">
          close
        </Button>
      </div>
    </Modal>
  );
}

AppointmentSurvey.propTypes = {
  localStorage: PropTypes.shape({
    set: PropTypes.func,
  }).isRequired,
};
