import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { Link } from 'site-modules/shared/components/link/link';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';

import './invoke-action.scss';

export function InvokeActionElement({
  onClick,
  href,
  children,
  classes,
  dataTrackingId,
  dataTrackingValue,
  trackingDisabled,
  newTab,
  disabled,
  ...props
}) {
  const tabTarget = newTab ? '_blank' : '_self';

  return href ? (
    <Link
      className={classNames(classes, { disabled })}
      data-tracking-id={dataTrackingId}
      data-tracking-value={dataTrackingValue}
      data-tracking-disabled={trackingDisabled}
      target={tabTarget}
      to={href}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <Button
      onClick={onClick}
      className={classNames(classes, { disabled })}
      data-tracking-id={dataTrackingId}
      data-tracking-value={dataTrackingValue}
      data-tracking-disabled={trackingDisabled}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
}

InvokeActionElement.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.string,
  dataTrackingId: PropTypes.string.isRequired,
  dataTrackingValue: PropTypes.string,
  newTab: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  trackingDisabled: PropTypes.bool,
};

InvokeActionElement.defaultProps = {
  onClick: null,
  href: '',
  children: null,
  classes: '',
  disabled: false,
  trackingDisabled: false,
  dataTrackingValue: null,
};

/**
 * Show CTA block with some action triggered by click.
 * @param {function} onClick
 * @param {string} href
 * @param {string} icon Icon class.
 * @param {string} iconColor Icon color class.
 * @param {string} image Image url.
 * @param {string} imageClassName Image class name.
 * @param {string} primaryText Text for the first line.
 * @param {string} secondaryText Text for the second line.
 * @param {string} dataTrackingId data-tracking-id.
 * @param {boolean} [newTab] Open in new tab
 * @param {boolean} [disabled] is button/link should has 'disabled' class
 * @param {boolean} [trackingDisabled] is tracking should be disabled
 * @param {any} [props]
 * @returns {ReactElement}
 */
export function InvokeAction({
  onClick,
  href,
  icon,
  iconClassName,
  arrowClassName,
  imageClassName,
  iconColor,
  image,
  primaryText,
  primaryTextClassName,
  secondaryText,
  secondaryTextClassName,
  dataTrackingId,
  dataTrackingValue,
  trackingDisabled,
  newTab,
  disabled,
  primaryColor,
  classes,
  widthAuto,
  children,
  arrowIcon,
  badge,
  withIconWrapper,
  iconWrapperClassName,
  textWrapperClassName,
  ...props
}) {
  return (
    <InvokeActionElement
      href={href}
      onClick={onClick}
      classes={classNames('invoke-action rounded vdp-text-decoration-none-hover bg-white', classes, {
        'w-100': !widthAuto,
      })}
      dataTrackingId={dataTrackingId}
      dataTrackingValue={dataTrackingValue}
      trackingDisabled={trackingDisabled}
      newTab={newTab}
      disabled={disabled}
      {...props}
    >
      <div className="d-flex align-items-center">
        {icon && !withIconWrapper && <span className={classNames(iconClassName, iconColor, icon)} aria-hidden />}
        {icon && withIconWrapper && (
          <div className={classNames(iconWrapperClassName)}>
            <i className={classNames(iconClassName, iconColor, icon)} aria-hidden />
          </div>
        )}
        {image && (
          <ClientSideRender>
            <img className={classNames('invoke-action-image', imageClassName)} src={image} alt={primaryText} />
          </ClientSideRender>
        )}
        <div className={classNames(textWrapperClassName, { 'd-flex flex-column': badge })}>
          {badge}
          <div className={classNames(primaryTextClassName, primaryColor)}>{primaryText || children}</div>
          {secondaryText && <div className={secondaryTextClassName}>{secondaryText}</div>}
        </div>
      </div>
      <span className={classNames('mb-0', arrowIcon, primaryColor, arrowClassName)} aria-hidden />
    </InvokeActionElement>
  );
}

InvokeAction.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  iconColor: PropTypes.string,
  image: PropTypes.string,
  imageClassName: PropTypes.string,
  primaryText: PropTypes.string,
  primaryTextClassName: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryTextClassName: PropTypes.string,
  dataTrackingId: PropTypes.string.isRequired,
  dataTrackingValue: PropTypes.string,
  newTab: PropTypes.bool,
  disabled: PropTypes.bool,
  trackingDisabled: PropTypes.bool,
  children: PropTypes.node,
  primaryColor: PropTypes.string,
  classes: PropTypes.string,
  widthAuto: PropTypes.bool,
  arrowIcon: PropTypes.string,
  badge: PropTypes.node,
  withIconWrapper: PropTypes.bool,
  iconWrapperClassName: PropTypes.string,
  textWrapperClassName: PropTypes.string,
};

InvokeAction.defaultProps = {
  onClick: null,
  primaryText: '',
  href: '',
  icon: '',
  iconClassName: 'h4 mb-0 me-1',
  arrowClassName: '',
  iconColor: 'text-gray',
  image: '',
  imageClassName: 'me-1',
  primaryTextClassName: 'mb-0',
  secondaryText: '',
  secondaryTextClassName: 'medium mb-0 fw-normal text-gray-darker',
  newTab: false,
  disabled: false,
  trackingDisabled: false,
  dataTrackingValue: null,
  children: null,
  primaryColor: 'text-gray-darker',
  classes: '',
  widthAuto: false,
  arrowIcon: 'icon-arrow-right4',
  badge: undefined,
  withIconWrapper: undefined,
  iconWrapperClassName: undefined,
  textWrapperClassName: 'text-start',
};
