import React from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { getWizardIncentivesPath, IncentiveModel } from 'client/data/models/incentives';
import { VisitorModel } from 'client/data/models/visitor';
import { IncentivesWizardEntities } from 'client/data/models/incentives-wizard';
import { INVENTORY_TYPES } from 'client/constants/inventory-types';
import { LOAN_PAYMENT } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { IncentivesWizardWidget } from 'site-modules/shared/components/incentives/incentives-wizard/incentives-wizard-widget';
import { CoreIncentivesWizardContent } from './core-incentives-wizard-content';

export function CoreIncentivesWizardUI({
  incentives,
  setModelValue,
  zipCode,
  styleId,
  makeName,
  modelName,
  makeSlug,
  modelSlug,
  year,
  engineType,
  isUsed,
  isMobile,
}) {
  return (
    <IncentivesWizardWidget
      availableIncentives={incentives || []}
      zipCode={zipCode}
      inventoryType={isUsed ? INVENTORY_TYPES.USED : INVENTORY_TYPES.NEW}
      paymentType={LOAN_PAYMENT}
      engineTypes={[engineType?.toLowerCase()]}
    >
      {({ wizardState, incentives: eligibleIncentives, onFinish, onStartOver }) => (
        <CoreIncentivesWizardContent
          incentives={eligibleIncentives}
          setModelValue={setModelValue}
          styleId={styleId}
          makeName={makeName}
          modelName={modelName}
          makeSlug={makeSlug}
          modelSlug={modelSlug}
          year={year}
          engineType={engineType}
          zipCode={zipCode}
          isUsed={isUsed}
          isMobile={isMobile}
          onFinish={onFinish}
          onStartOver={onStartOver}
          wizardState={wizardState}
        />
      )}
    </IncentivesWizardWidget>
  );
}

CoreIncentivesWizardUI.propTypes = {
  incentives: IncentivesWizardEntities.IncentivesData,
  setModelValue: PropTypes.func.isRequired,
  styleId: PropTypes.number.isRequired,
  makeName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  makeSlug: PropTypes.string.isRequired,
  modelSlug: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  engineType: PropTypes.string,
  zipCode: PropTypes.string,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

CoreIncentivesWizardUI.defaultProps = {
  incentives: null,
  engineType: null,
  zipCode: null,
  isUsed: false,
  isMobile: false,
};

export const CoreIncentivesWizard = connectToModel(CoreIncentivesWizardUI, {
  incentives: bindToPath(({ styleId, isUsed }) => getWizardIncentivesPath(styleId, isUsed), IncentiveModel),
  zipCode: bindToPath('location.zipCode', VisitorModel),
});
