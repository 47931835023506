import React, { memo, useCallback, useEffect } from 'react';
import { StepProps } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { IMAGES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/eligibility';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { getBulletsConfig } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-bullets-config';
import { checkCategoryIncentivesEligible } from 'site-modules/shared/components/incentives/incentives-wizard/utils/check-eligibility';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';
import { BulletsList } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/bullet-list';
import { fireScreenTracking } from 'site-modules/shared/components/incentives/incentives-wizard/utils/wizard-tracking';
import { Button } from 'site-modules/shared/components/inventory/button/button';

import './eligibility-screen.scss';

export const EligibilityScreen = memo(
  ({ stepConfig, flowSteps, children, context, eligibleIncentiveIds, allIncentives, onAnswer, stepMeta, ...rest }) => {
    const { title, categories, autoAnswer = [] } = stepConfig;
    const activeCategory = categories[0];
    const isEligible = checkCategoryIncentivesEligible(activeCategory, eligibleIncentiveIds, allIncentives);
    const bulletsConfig = getBulletsConfig({ stepConfig, flowSteps, eligibleIncentiveIds, allIncentives, context });
    const { creativeId } = rest;

    useEffect(() => {
      fireScreenTracking({
        stepId: stepConfig.id,
        trackingSubaction: stepConfig.trackingSubaction(stepMeta),
        isEligible,
        creativeId,
      });
    }, [stepConfig, isEligible, creativeId, stepMeta]);

    const handleClick = useCallback(() => onAnswer(autoAnswer), [onAnswer, autoAnswer]);

    return (
      <BaseWizardStep
        stepConfig={stepConfig}
        context={context}
        eligibleIncentiveIds={eligibleIncentiveIds}
        allIncentives={allIncentives}
        stepMeta={stepMeta}
        {...rest}
      >
        <div className="eligibility-screen d-flex flex-column justify-content-between">
          <div className="d-flex flex-column">
            <img
              className="my-1_5"
              src={getStaticImageUrl(IMAGES[activeCategory])}
              alt=""
              decoding="async"
              aria-hidden
              loading="lazy"
              height={152}
            />
            <h2 className="mb-1_5 text-center size-24 fw-bold">{title(context, stepMeta, isEligible)}</h2>
            <BulletsList config={bulletsConfig} withAnimation />
          </div>
          <div className="mt-0_5">
            <Button
              color="blue-50"
              size="lg"
              onClick={handleClick}
              className="text-white text-transform-none fw-medium py-1 w-100"
            >
              Next
            </Button>
          </div>
        </div>
      </BaseWizardStep>
    );
  }
);

EligibilityScreen.displayName = 'EligibilityScreen';
EligibilityScreen.propTypes = StepProps;
