import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { StepProps } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { shouldShowBackButton } from 'site-modules/shared/components/incentives/incentives-wizard/utils/should-show-back-button';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';
import { BackButton } from 'site-modules/shared/components/incentives/incentives-wizard/steps/back-button';
import { ScreenHint } from 'site-modules/shared/components/incentives/incentives-wizard/steps/components/screen-hint';

import './base-question-step.scss';

export const BaseQuestionStep = memo(props => {
  const { stepConfig, children, context, stepMeta, onBack, flowSteps, ...rest } = props;
  const { hint } = stepConfig;

  return (
    <BaseWizardStep stepConfig={stepConfig} context={context} stepMeta={stepMeta} {...rest}>
      <div className="base-question-step">
        {shouldShowBackButton(stepConfig.id, flowSteps) && <BackButton onBack={onBack} />}
        <fieldset>
          <legend>
            <h2 className="mb-1_5 text-center size-24 fw-bold">{stepConfig.title(context, stepMeta)}</h2>
          </legend>
          {children}
        </fieldset>
        {!!hint && <ScreenHint hint={hint} />}
      </div>
    </BaseWizardStep>
  );
});

BaseQuestionStep.displayName = 'BaseQuestionStep';

BaseQuestionStep.propTypes = {
  ...StepProps,
  children: PropTypes.node,
};
BaseQuestionStep.defaultProps = {
  children: null,
};
