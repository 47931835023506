import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, isEmpty } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildFuelCostsPath, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { VisitorModel } from 'client/data/models/visitor';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getMpgUrl } from 'site-modules/shared/utils/core-link-constructor';
import { Link } from 'site-modules/shared/components/link/link';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { CostPart } from './cost-part';

import './range-and-cost.scss';

const TOOLTIP_ID = {
  COST: 'cost-to-drive-tooltip',
};
const TOOLTIP_VALUE_MAP = {
  [TOOLTIP_ID.COST]: 'Cost to Drive',
};

function RangeAndCostCardUI({ className, fuelCostsData, style, selectProps, stateName, creativeId, isDefaultStyle }) {
  const onTooltipOpen = useCallback(
    ({ id }) =>
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_VIEW_CONTENT,
          subaction_name: TrackingConstant.VIEW_TOOLTIP,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
          creative_id: creativeId,
          value: TOOLTIP_VALUE_MAP[id],
        },
      }),
    [creativeId]
  );

  const hasFuelCostsData = !isEmpty(fuelCostsData);
  if (!hasFuelCostsData && isDefaultStyle) {
    return null;
  }

  const currentStateName = stateName || 'the US';
  const averageCategory = `${get(fuelCostsData, 'vehicleData.vehicleSizeClass', '')} ${get(
    fuelCostsData,
    'vehicleData.primaryBodyType',
    ''
  )}`.trim();

  return (
    <ScrollElement
      id="subnav-cost"
      className={classnames('range-and-cost pos-r p-1 d-inline-block', className)}
      data-tracking-parent={creativeId}
    >
      <div className="mb-2 cost-part d-flex flex-column">
        <CostPart
          onTooltipOpen={onTooltipOpen}
          style={style}
          fuelCostsData={fuelCostsData}
          averageCategory={averageCategory}
          stateName={currentStateName}
          tooltipId={TOOLTIP_ID.COST}
          selectProps={selectProps}
        />
      </div>
      <Link
        to={getMpgUrl({ ...style, styleId: isDefaultStyle ? null : style.id })}
        className="text-primary-darker"
        data-tracking-id="mpg_model_select"
        data-tracking-value={style.name}
      >
        Calculate my fuel costs
        <i className="icon-arrow-right4 size-10 ps-0_25" aria-hidden />
      </Link>
    </ScrollElement>
  );
}

RangeAndCostCardUI.propTypes = {
  style: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    makeName: PropTypes.string,
    modelName: PropTypes.string,
    trim: PropTypes.string,
    name: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  className: PropTypes.string,
  fuelCostsData: VehicleEntities.StyleFuelCostData,
  selectProps: PropTypes.shape({
    onChange: PropTypes.func,
    valueKey: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      })
    ),
  }),
  stateName: PropTypes.string,
  creativeId: PropTypes.string,
  isDefaultStyle: PropTypes.bool,
};

RangeAndCostCardUI.defaultProps = {
  className: null,
  fuelCostsData: null,
  selectProps: null,
  stateName: null,
  creativeId: null,
  isDefaultStyle: true,
};

export const propsAreEqual = (prevProps, { fuelCostsData }) => !fuelCostsData;
export const RangeAndCostCard = connectToModel(React.memo(RangeAndCostCardUI, propsAreEqual), {
  stateName: bindToPath('location', VisitorModel, visitorLocation => visitorLocation.state),
  fuelCostsData: bindToPath(({ style }) => buildFuelCostsPath(style.id), VehicleModel),
});
