import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';
import { noop } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { EmailInput } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/email-input';
import { EmailReviewAnswersCta } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/email-review-answers-cta';
import { wizardTrackingIds } from 'client/tracking/maps/incentives-wizard';

import './email-review-answers-section.scss';

export function EmailReviewAnswersSection({
  make,
  model,
  eligibleSubtypeIds,
  eligibleIncentiveIds,
  zip,
  creativeId,
  incentiveIdEligibilityFactorIdPairs,
  onStartOver,
}) {
  const [showEmailInput, setShowEmailInput] = useToggle(false);
  const emailButtonRef = useRef();
  const emailId = 'email-button-id';

  const handleFocus = useCallback(() => emailButtonRef?.current.focus(), []);

  return (
    <>
      <Row className="email-review-answers d-flex align-items-center text-cool-gray-40 mt-1_5" noGutters>
        <Col>
          <EmailReviewAnswersCta
            className={classnames({
              'fw-medium': showEmailInput,
              'fw-normal': !showEmailInput,
            })}
            onClick={setShowEmailInput}
            aria-controls={emailId}
            aria-expanded={showEmailInput}
            ref={emailButtonRef}
            icon={<i className="icon-envelop me-0_5 size-12" aria-hidden />}
          >
            Send results
          </EmailReviewAnswersCta>
        </Col>

        <Col className="text-end">
          <EmailReviewAnswersCta
            onClick={onStartOver}
            className="fw-normal"
            icon={<i className="icon-reset me-0_5 size-12" aria-hidden />}
            data-tracking-id={wizardTrackingIds.openIncentivesMatcher}
            data-tracking-value="Start over"
          >
            Start over
          </EmailReviewAnswersCta>
        </Col>
      </Row>

      {showEmailInput && (
        <EmailInput
          make={make}
          model={model}
          eligibleSubtypeIds={eligibleSubtypeIds}
          eligibleIncentiveIds={eligibleIncentiveIds}
          incentiveIdEligibilityFactorIdPairs={incentiveIdEligibilityFactorIdPairs}
          zip={zip}
          creativeId={creativeId}
          onRestoreFocus={handleFocus}
          id={emailId}
        />
      )}
    </>
  );
}

EmailReviewAnswersSection.propTypes = {
  zip: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  creativeId: PropTypes.string.isRequired,
  eligibleSubtypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  eligibleIncentiveIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  incentiveIdEligibilityFactorIdPairs: PropTypes.string,
  onStartOver: PropTypes.func,
};

EmailReviewAnswersSection.defaultProps = {
  incentiveIdEligibilityFactorIdPairs: '',
  onStartOver: noop,
};
