import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { noop } from 'lodash';
import {
  fireEmailTracking,
  sendMarketoRebateSavingsEmail,
} from 'site-modules/shared/components/incentives/incentives-wizard/utils/sent-email';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { WizardInput } from 'site-modules/shared/components/incentives/incentives-wizard/steps/components/wizard-input';
import { FieldError } from 'site-modules/shared/components/field-error/field-error';
import './email-input.scss';

const ERROR_VALIDATION_MESSAGE = 'Please enter a valid email address.';
const SUCCESS_SUBMIT_MESSAGE = 'Thanks! We’ve sent your results';
const ERROR_SUBMIT_MESSAGE = 'Error, please try again';

const EmailInputUI = ({
  id,
  zip,
  make,
  model,
  eligibleSubtypeIds,
  eligibleIncentiveIds,
  visitorId,
  onRestoreFocus,
  creativeId,
  incentiveIdEligibilityFactorIdPairs,
}) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isEmailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      const isValid = validation.validateEmail(email);
      if (!isValid) {
        setError(ERROR_VALIDATION_MESSAGE);
        return;
      }
      setIsLoading(true);
      setError('');

      try {
        await sendMarketoRebateSavingsEmail({
          zip,
          email,
          make,
          model,
          visitorId,
          eligibleSubtypeIds,
          eligibleIncentiveIds,
          incentiveIdEligibilityFactorIdPairs,
        });

        setEmailSent(true);
        setError('');
        onRestoreFocus();
        fireEmailTracking(creativeId);
      } catch {
        setError(ERROR_SUBMIT_MESSAGE);
      }

      setIsLoading(false);
    },
    [
      creativeId,
      eligibleIncentiveIds,
      eligibleSubtypeIds,
      email,
      incentiveIdEligibilityFactorIdPairs,
      make,
      model,
      onRestoreFocus,
      visitorId,
      zip,
    ]
  );

  const handleChange = useCallback(({ target: { value } }) => {
    setEmail(value);
  }, []);

  return (
    <div role="region" id={id} className="w-100 mt-1_5">
      {!isEmailSent && (
        <Form id="email-input-form" noValidate name="form" onSubmit={handleSubmit}>
          <label htmlFor="email" className="size-16 fw-bold text-start w-100 mb-1">
            What is your email?
          </label>

          <WizardInput
            id="email"
            name="email"
            inputClassName="w-100 ps-1"
            onChange={handleChange}
            invalid={!!error}
            aria-describedby="email-errorMessage"
            disabled={isLoading || !email}
            isLoading={isLoading}
          />

          <FieldError error={error} id="email-errorMessage" classes="mt-0_25" />
        </Form>
      )}
      {isEmailSent && (
        <div className="success-message size-16 rounded-12 text-green-30 bg-green-100 px-0_5 py-0_5">
          {SUCCESS_SUBMIT_MESSAGE}
        </div>
      )}
    </div>
  );
};

EmailInputUI.propTypes = {
  id: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  creativeId: PropTypes.string.isRequired,
  eligibleSubtypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  eligibleIncentiveIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  visitorId: PropTypes.string,
  onRestoreFocus: PropTypes.func,
  incentiveIdEligibilityFactorIdPairs: PropTypes.string,
};

EmailInputUI.defaultProps = {
  visitorId: null,
  onRestoreFocus: noop,
  incentiveIdEligibilityFactorIdPairs: '',
};

const stateToPropsConfig = {
  visitorId: bindToPath('id', VisitorModel),
};

export const EmailInput = connectToModel(EmailInputUI, stateToPropsConfig);
