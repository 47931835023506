import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { EdmundsAPI } from 'client/data/api/api-client';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';

export const sendMarketoRebateSavingsEmail = async ({
  zip,
  email,
  make,
  model,
  visitorId,
  eligibleSubtypeIds,
  eligibleIncentiveIds,
  incentiveIdEligibilityFactorIdPairs,
}) => {
  const params = {
    zip,
    email,
    make: makeNiceName(make),
    model: makeNiceName(model),
    visitorId,
    subtypeId: eligibleSubtypeIds,
    eligibleIncentiveId: eligibleIncentiveIds,
    rebateOverridesByEligibilityId: incentiveIdEligibilityFactorIdPairs,
  };
  const body = new URLSearchParams(params).toString();

  await EdmundsAPI.fetchJson('/purchasefunnel/v1/email/incentives/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body,
  });
};

export const fireEmailTracking = creativeId => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_TOOL_SELECT,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.SEND_MARKETO_LEAD_CONFIRM,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
    },
  });
};
