import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNumber } from 'lodash';

/* Components */
import { OdometerWrapperAsync } from 'site-modules/shared/components/odometer-wrapper/odometer-wrapper-async';

import './odometer-payment-value.scss';

/**
 * Renders odometer with value if value is valid, otherwise renders not valid copy.
 * @param {number} value
 * @param {string} className
 * @param {string} valueClassName
 * @param {string} invalidValueText
 * @param {object} props
 * @returns {ReactElement}
 */
export function OdometerPaymentValue({ value, valueClassName, className, invalidValueText, ...props }) {
  const isValidValue = isNumber(value);
  return (
    <div className={classNames('odm-payment-value-wrapper d-inline-block', className, { invalid: !isValidValue })}>
      <OdometerWrapperAsync
        value={isValidValue ? value : 0}
        classNames={classNames('odm-payment-value', valueClassName)}
        duration={200}
        {...props}
      />
      {!isValidValue && <span className="pe-0_5">{invalidValueText}</span>}
    </div>
  );
}

OdometerPaymentValue.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
  valueClassName: PropTypes.string,
  invalidValueText: PropTypes.string,
};

OdometerPaymentValue.defaultProps = {
  value: null,
  className: null,
  valueClassName: null,
  invalidValueText: '$ - ',
};
