import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import {
  CUSTOMER_OF_QUESTION_ID,
  INSTALL_EV_CHARGER_QUESTION_ID,
  RESULT_FEDERAL_REBATES_SCREEN_ID,
  RESULT_LOCAL_REBATES_SCREEN_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  ZIP_CODE_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

const TRACKING_VALUE = {
  true: 'eligible',
  false: 'ineligible',
};

export function fireScreenTracking({ stepId, trackingSubaction, isEligible, creativeId }) {
  if (!trackingSubaction) return;

  const value = TRACKING_VALUE[isEligible];
  const includeValue = [RESULT_FEDERAL_REBATES_SCREEN_ID, RESULT_LOCAL_REBATES_SCREEN_ID].includes(stepId);

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: trackingSubaction,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: creativeId,
      ...(includeValue ? { value } : {}),
    },
  });
}

export function fireQuestionTracking({ trackingSubaction, creativeId }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: `display_${trackingSubaction}`,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: creativeId,
    },
  });
}

export function fireAnswerTracking({ stepId, value, trackingSubaction, creativeId }) {
  const includeValue = [
    TRANSACTION_TYPE_QUESTION_ID,
    INSTALL_EV_CHARGER_QUESTION_ID,
    ZIP_CODE_QUESTION_ID,
    CUSTOMER_OF_QUESTION_ID,
  ].includes(stepId);

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: `input_${trackingSubaction}`,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: creativeId,
      ...(includeValue ? { value } : {}),
    },
  });
}
