import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import classnames from 'classnames';
import { ConsumerReviewsAspects } from 'client/data/models/consumer-reviews';

export function FilterButton({ className, aspect, polarity, handleFilterChange }) {
  const { displayName } = aspect;

  function handleClick() {
    handleFilterChange(aspect, polarity);
  }

  return (
    <Button
      outline
      className={classnames('filter-button text-gray-info text-lowercase me-1 mb-1 py-0_25', className)}
      onClick={handleClick}
      data-tracking-id="filter_consumer_review_aspect"
      data-tracking-value={displayName}
    >
      {displayName}
    </Button>
  );
}

FilterButton.propTypes = {
  aspect: ConsumerReviewsAspects.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  polarity: PropTypes.string,
};

FilterButton.defaultProps = {
  className: null,
  polarity: null,
};
