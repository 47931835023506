import React, { memo, useCallback, useEffect, useState } from 'react';
import { get, isFinite } from 'lodash';
import { isAnswerPresent } from 'site-modules/shared/components/incentives/incentives-wizard/utils/answer-status';
import { Form } from 'reactstrap';
import {
  StepProps,
  StepPropsDefaults,
} from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { BaseQuestionStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-question-step';
import { useQuestionTracking } from 'site-modules/shared/components/incentives/incentives-wizard/hooks/use-question-tracking';
import { WizardInput } from 'site-modules/shared/components/incentives/incentives-wizard/steps/components/wizard-input';

import './input-wizard-question.scss';

export const InputWizardQuestion = memo(
  ({
    stepConfig,
    stepMeta,
    answers,
    eligibleIncentiveIds,
    incentives,
    allIncentives,
    onAnswer,
    onBack,
    context,
    flowSteps,
    creativeId,
  }) => {
    const [inputValue, setInputValue] = useState('');
    const [handleAnswerTracking] = useQuestionTracking({ stepConfig, creativeId, stepMeta });
    const isRepeatedQuestion = isAnswerPresent(stepConfig.id, answers);

    useEffect(() => {
      if (isRepeatedQuestion) {
        setInputValue(answers[stepConfig.id][0]);
      }
    }, [answers, isRepeatedQuestion, stepConfig.id]);

    const handleInputChange = useCallback(({ target: { value: targetValue } }) => {
      const value = targetValue && +targetValue.replace(/,|\.|-/g, '');
      setInputValue(isFinite(+value) ? value : '');
    }, []);

    const handleSubmit = useCallback(
      e => {
        e.preventDefault();
        if (!inputValue) return;
        onAnswer([inputValue]);
        handleAnswerTracking(inputValue);
      },
      [onAnswer, inputValue, handleAnswerTracking]
    );

    const StartAdornmentComponent = get(stepConfig, 'customConfig.component', null);

    return (
      <BaseQuestionStep
        stepConfig={stepConfig}
        stepMeta={stepMeta}
        answers={answers}
        eligibleIncentiveIds={eligibleIncentiveIds}
        incentives={incentives}
        allIncentives={allIncentives}
        onAnswer={onAnswer}
        onBack={onBack}
        context={context}
        flowSteps={flowSteps}
      >
        <div className="input-wizard-question d-flex justify-content-center mb-1_5">
          <Form id="input-wizard-question-form" noValidate className="pos-r" name="form" onSubmit={handleSubmit}>
            <WizardInput
              id={stepConfig.id}
              value={inputValue && isFinite(+inputValue) ? numberWithCommas(+inputValue) : inputValue}
              onChange={handleInputChange}
              aria-label={stepConfig.inputAriaLabel}
              startAdornment={StartAdornmentComponent}
              disabled={!inputValue}
              inputMode="numeric"
              pattern="[0-9]*"
            />
          </Form>
        </div>
      </BaseQuestionStep>
    );
  }
);

InputWizardQuestion.displayName = 'InputWizardQuestion';

InputWizardQuestion.propTypes = StepProps;
InputWizardQuestion.defaultProps = StepPropsDefaults;
