import {
  ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID,
  HOUSEHOLD_SIZE_QUESTION_ID,
  TAX_FILING_STATUS_LOCAL_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

const ELIGIBILITY_FIELD_QUESTIONS_MAP = {
  maxIncome: [ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID],
  taxFilingStatus: [TAX_FILING_STATUS_LOCAL_QUESTION_ID],
  householdSize: [HOUSEHOLD_SIZE_QUESTION_ID],
};

export const mapBase = field => ELIGIBILITY_FIELD_QUESTIONS_MAP[field] || [];
