import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { getPublicationStateForMakeModel, isUsed } from 'site-modules/shared/utils/publication-states';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { PhotoflipperButtonAsync } from 'site-modules/shared/components/photoflipper-core/photoflipper-button/photoflipper-button-async';
import { Link } from 'site-modules/shared/components/link/link';

import './summary-links.scss';

export function SummaryLinks({
  vehicle: {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    submodels: { name: submodelName },
    pubStates,
    year,
  },
  allForSaleTrackingId,
  className,
  showPhotoFlipperButtonClassName,
  showAllForSaleLink,
  showPhotoFlipperButton,
}) {
  if (!(showAllForSaleLink || showPhotoFlipperButton)) {
    return null;
  }

  return (
    <div className={classnames('summary-links', className)}>
      {showAllForSaleLink && (
        <Link
          className="see-all-for-sale-link text-primary-darker p-0"
          to={
            isUsed(pubStates)
              ? getCoreUrl({ makeSlug, modelSlug, year })
              : srpLinkBuilder({
                  make: makeName,
                  model: modelName,
                  submodel: submodelName,
                  year,
                  inventorytype: getPublicationStateForMakeModel(pubStates),
                })
          }
          data-tracking-id={allForSaleTrackingId}
          rel="nofollow"
        >
          <span className="text-normal fw-normal size-16">
            Shop {modelName} {year} for sale
          </span>
          <i className="icon-arrow-right3 d-inline-block size-10 ms-0_25" aria-hidden />
        </Link>
      )}
      {showPhotoFlipperButton && (
        <PhotoflipperButtonAsync makeSlug={makeSlug} modelSlug={modelSlug} year={year}>
          <Button
            color="link"
            className={classnames(
              'p-0 size-16 text-primary-darker text-capitalize p-a-v-link border-0',
              showPhotoFlipperButtonClassName
            )}
          >
            View Photos &&nbsp;Videos
            <i className="icon-arrow-right4 d-inline-block size-10 ps-0_25" aria-hidden />
          </Button>
        </PhotoflipperButtonAsync>
      )}
    </div>
  );
}

SummaryLinks.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  allForSaleTrackingId: PropTypes.string,
  className: PropTypes.string,
  showPhotoFlipperButtonClassName: PropTypes.string,
  showAllForSaleLink: PropTypes.bool,
  showPhotoFlipperButton: PropTypes.bool,
};

SummaryLinks.defaultProps = {
  allForSaleTrackingId: null,
  className: null,
  showPhotoFlipperButtonClassName: null,
  showAllForSaleLink: false,
  showPhotoFlipperButton: false,
};
