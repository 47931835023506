import React, { memo, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  StepProps,
  StepPropsDefaults,
} from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { isAnswerPresent } from 'site-modules/shared/components/incentives/incentives-wizard/utils/answer-status';
import { RadioList } from 'site-modules/shared/components/radio-list/radio-list';
import { BaseQuestionStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-question-step';
import { useQuestionTracking } from 'site-modules/shared/components/incentives/incentives-wizard/hooks/use-question-tracking';
import { DEFAULT_CUSTOMER_OF_ANSWER } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { Button } from 'site-modules/shared/components/inventory/button/button';

import './radio-wizard-question.scss';

function getTrackingValue({ trackingValueField, options, value }) {
  const selectedOption = options?.find(({ title }) => title === value);
  const trackingValue = trackingValueField && selectedOption ? selectedOption[trackingValueField] : value;
  return value === DEFAULT_CUSTOMER_OF_ANSWER ? null : trackingValue;
}

export const RadioWizardQuestion = memo(
  ({
    stepConfig,
    stepMeta,
    answers,
    eligibleIncentiveIds,
    incentives,
    allIncentives,
    onAnswer,
    onBack,
    context,
    flowSteps,
    creativeId,
    activeCategory,
  }) => {
    const [radioValue, setRadioValue] = useState('');
    const [handleAnswerTracking] = useQuestionTracking({ stepConfig, creativeId, stepMeta });
    const { answerOptions, trackingValueField, id } = stepConfig;
    const options =
      typeof answerOptions === 'function'
        ? answerOptions(incentives, activeCategory, stepMeta, context)
        : answerOptions;
    const isRepeatedQuestion = isAnswerPresent(id, answers);

    useEffect(() => {
      if (isRepeatedQuestion) {
        setRadioValue(answers[id][0]);
      }
    }, [answers, id, isRepeatedQuestion]);

    const handleChange = useCallback(
      ({ target: { value } }) => {
        setRadioValue(value);
        if (!isRepeatedQuestion) {
          setTimeout(() => {
            onAnswer([value]);
            handleAnswerTracking(getTrackingValue({ trackingValueField, options, value }));
          }, 500);
        }
      },
      [isRepeatedQuestion, onAnswer, handleAnswerTracking, trackingValueField, options]
    );

    const handleClick = useCallback(() => {
      onAnswer([radioValue]);
      handleAnswerTracking(getTrackingValue({ trackingValueField, options, value: radioValue }));
    }, [handleAnswerTracking, onAnswer, options, radioValue, trackingValueField]);

    return (
      <BaseQuestionStep
        stepConfig={stepConfig}
        stepMeta={stepMeta}
        answers={answers}
        eligibleIncentiveIds={eligibleIncentiveIds}
        incentives={incentives}
        allIncentives={allIncentives}
        onAnswer={onAnswer}
        onBack={onBack}
        context={context}
        flowSteps={flowSteps}
      >
        <div
          className={classnames('radio-wizard-question', {
            'mb-1_5': isRepeatedQuestion,
          })}
        >
          <RadioList
            legendClassName="small mb-0_25 text-nowrap"
            key="wizard-radio-list"
            id="wizard-radio-list"
            onChange={handleChange}
            list={options}
            checked={radioValue}
            name="wizardRadioList"
            labelClassName="fw-medium ms-2_5"
            labelContainerClassName="radio-wizard-form-group bg-cool-gray-100 rounded-12 w-100 p-1 d-flex align-items-center"
            formGroupClassName="mb-1"
            textClassName="ms-2_5 fw-medium"
            tag="div"
            useInlineTags
          />

          {isRepeatedQuestion && (
            <Button
              color="blue-50"
              size="lg"
              type="submit"
              onClick={handleClick}
              className="submit-btn text-white text-transform-none fw-medium py-1 w-100"
            >
              Continue
            </Button>
          )}
        </div>
      </BaseQuestionStep>
    );
  }
);

RadioWizardQuestion.displayName = 'RadioWizardQuestion';

RadioWizardQuestion.propTypes = StepProps;
RadioWizardQuestion.defaultProps = StepPropsDefaults;
