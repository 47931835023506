import PropTypes from 'prop-types';
import { gql } from '@apollo/client'; // eslint-disable-line
import { get, isString, flatten } from 'lodash';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { VehicleModel } from 'client/data/models/vehicle';
import { withMetrics } from 'client/data/api/api-metrics';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { calculateRatingPercents } from 'site-modules/shared/utils/ratings-count';
import { transformWriteReviewData } from 'site-modules/shared/utils/consumer-reviews/write-review';
import { ThumbsStorage } from 'site-modules/consumer-reviews/utils/thumbs-storage';
import { logger } from 'client/utils/isomorphic-logger';
import { VisitorModel } from 'client/data/models/visitor';
import { filterFleetSubmodelStyles } from 'site-modules/shared/utils/core-page/fleet-trims';
import { FeatureFlagModel } from 'client/data/models/feature-flag';

export const HELPFUL_URL = '/vehiclereviews/v2/thumbsup/';
export const NOT_HELPFUL_URL = '/vehiclereviews/v2/thumbsdown/';

function getThumbsApi({ reviewId, isHelpful }) {
  return isHelpful ? `${HELPFUL_URL}${reviewId}` : `${NOT_HELPFUL_URL}${reviewId}`;
}

export const PAGE_SIZES = [5, 10, 50];

export const SORT_BY = [
  {
    name: 'Most Helpful',
    value: '{"confidence":"DESC"}',
  },
  {
    name: 'Most Recent',
    value: '{"updated":"DESC"}',
  },
  {
    name: 'Highest Rating',
    value: '{"userRating":"DESC"}',
  },
  {
    name: 'Lowest Rating',
    value: '{"userRating":"ASC"}',
  },
];

export const RATINGS_OPTIONS = [
  { value: 0, label: 'All ratings' },
  { value: 5, label: '5 stars' },
  { value: 4, label: '4 stars' },
  { value: 3, label: '3 stars' },
  { value: 2, label: '2 stars' },
  { value: 1, label: '1 star' },
];

export const DEFAULT_SORTING = SORT_BY[0].value;
export const DEFAULT_PAGE_NUM = 1;
export const DEFAULT_PAGE_SIZE = 5;
export const NO_STYLE_ID = 0;
export const DEFAULT_RATING = 0;

export const DEFAULT_FILTER = {
  sorting: DEFAULT_SORTING,
  pagenum: DEFAULT_PAGE_NUM,
  pagesize: DEFAULT_PAGE_SIZE,
  rating: DEFAULT_RATING,
  aspect: null,
};

const CORE_PAGE_ASPECT_RESULT_PAGE_SIZE = 8;

const Style = PropTypes.shape({
  styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styleName: PropTypes.string,
  reviewCount: PropTypes.number,
});

const Thumbs = PropTypes.shape({
  thumbsUp: PropTypes.number,
  thumbsDown: PropTypes.number,
});

const Review = PropTypes.shape({
  id: PropTypes.string,
  styleId: PropTypes.string,
  styleName: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  createTime: PropTypes.string,
  updateTime: PropTypes.string,
  author: PropTypes.shape({
    authorName: PropTypes.string,
  }),
  thumbsUpDownCounter: Thumbs,
  userRating: PropTypes.number,
  inferences: PropTypes.arrayOf(
    PropTypes.shape({
      span: PropTypes.shape({
        beginning: PropTypes.number,
        ending: PropTypes.number,
      }),
    })
  ),
});

const ConsumerReview = PropTypes.shape({
  id: PropTypes.string,
  styleId: PropTypes.string,
  year: PropTypes.number,
  title: PropTypes.string,
  text: PropTypes.string,
  created: PropTypes.string,
  updated: PropTypes.string,
  author: PropTypes.shape({
    authorName: PropTypes.string,
  }),
  styleName: PropTypes.string,
  spans: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    })
  ),
  vehicleRating: PropTypes.shape({
    overall: PropTypes.number,
    categorized: PropTypes.shape({
      value: PropTypes.number,
      safety: PropTypes.number,
      performance: PropTypes.number,
      reliability: PropTypes.number,
      technology: PropTypes.number,
      interior: PropTypes.number,
      comfort: PropTypes.number,
    }),
  }),
  thumbsUp: PropTypes.number,
  thumbsDown: PropTypes.number,
  city: PropTypes.string,
  state: PropTypes.string,
});

export const ConsumerReviewsAspects = PropTypes.shape({
  displayName: PropTypes.string,
  polarity: PropTypes.string,
  topic: PropTypes.string,
});

const RatingAggregation = PropTypes.shape({
  star1: PropTypes.number,
  star2: PropTypes.number,
  star3: PropTypes.number,
  star4: PropTypes.number,
  star5: PropTypes.number,
  averageStars: PropTypes.number,
});

export const ConsumerReviewsEntities = {
  Review,
  ConsumerReview,
  Style,
  Thumbs,
  Styles: PropTypes.objectOf(PropTypes.arrayOf(Style)),
  ReviewsFilter: PropTypes.shape({
    sorting: PropTypes.string,
    pagesize: PropTypes.number,
    pagenum: PropTypes.number,
    aspect: ConsumerReviewsAspects,
  }),
  VehicleFilter: PropTypes.shape({
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    submodel: PropTypes.string,
    styleId: PropTypes.number,
  }),
  AverageRating: PropTypes.shape({
    averageRating: PropTypes.number,
    reviewsCount: PropTypes.number,
  }),
  ReviewContent: PropTypes.shape({
    averageUserRating: PropTypes.number,
    results: PropTypes.arrayOf(Review).isRequired,
    totalNumber: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  ConsumerReviewsContent: PropTypes.shape({
    ratingAggregation: RatingAggregation,
    totalReviews: PropTypes.number,
    reviews: PropTypes.arrayOf(ConsumerReview).isRequired,
  }),
  RatingsSummary: PropTypes.shape({
    averageRating: PropTypes.number,
    reviewsCount: PropTypes.number,
  }),
  ConsumerReviewsRatingsCount: PropTypes.shape({
    ratingAggregation: RatingAggregation,
    totalReviews: PropTypes.number,
    percents: PropTypes.arrayOf(PropTypes.number),
  }),
  ReviewData: PropTypes.shape({
    id: PropTypes.string,
    styleId: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    comfortRatingDto: PropTypes.shape({ comfortRating: PropTypes.number }),
    interiorRatingDto: PropTypes.shape({ interiorRating: PropTypes.number }),
    performanceRatingDto: PropTypes.shape({ performanceRating: PropTypes.number }),
    reliabilityRatingDto: PropTypes.shape({ reliabilityRating: PropTypes.number }),
    safetyRatingDto: PropTypes.shape({ safetyRating: PropTypes.number }),
    technologyRatingDto: PropTypes.shape({ technologyRating: PropTypes.number }),
    valueRatingDto: PropTypes.shape({ valueRating: PropTypes.number }),
    userRating: PropTypes.number,
    userProfile: PropTypes.shape({
      age: PropTypes.string,
      milesPerYear: PropTypes.string,
      height: PropTypes.string,
      kidsUnderTwelve: PropTypes.string,
      drivingTerrain: PropTypes.string,
      gender: PropTypes.string,
      weight: PropTypes.string,
    }),
    bodyType: PropTypes.shape({ submodelName: PropTypes.string }),
  }),
};

function buildConsumerReviewsAspectsPath() {
  return 'aspects';
}

/**
 * Takes vehicle data (make, model, submodel and year), aspect topic, associated polarity, pagenum, pagesize and rating to return filtered reviews
 * @returns object consisting of review data based on aspects used in ConsumerReviewsFilteredByAspect
 * */
export async function getConsumerReviewsFilteredByAspect(vehicleFilter, reviewsAspectFilter, context) {
  const { make, model, submodel, years } = vehicleFilter;
  const { pagenum, pagesize, aspect, graphQlRatingParam, polarity } = reviewsAspectFilter;
  let pageSize = CORE_PAGE_ASPECT_RESULT_PAGE_SIZE;
  if (PAGE_SIZES.includes(pagesize)) {
    pageSize = pagesize;
  }

  const gqlVehicleFilter = {
    makeSlug: make,
    modelSlug: model,
    years,
  };

  if (submodel) {
    gqlVehicleFilter.submodel = submodel;
  }
  return withMetrics(EdmundsGraphQLFederation, context)
    .query(
      gql`
        query(
          $vehicleFilter: VehicleFilter!
          $pageRequest: PageRequest
          $sortBy: ReviewSort
          $reviewFilter: ReviewFilter
        ) {
          vehicleReviews(
            vehicleFilter: $vehicleFilter
            page: $pageRequest
            sortBy: $sortBy
            reviewFilter: $reviewFilter
          ) {
            totalReviews
            reviews {
              id
              author {
                authorName
              }
              created
              styleId
              year
              styleName
              spans {
                start
                end
              }
              title
              text
              thumbsUp
              thumbsDown
              vehicleRating {
                overall
                categorized {
                  value
                  safety
                  performance
                  reliability
                  technology
                  interior
                  comfort
                }
              }
            }
          }
        }
      `,
      {
        vehicleFilter: gqlVehicleFilter,
        pageRequest: {
          pageNum: pagenum,
          pageSize,
        },
        reviewFilter: {
          userRating: graphQlRatingParam,
          topic: aspect.topic,
          polarity,
        },
      }
    )
    .then(response => get(response, 'vehicleReviews', null));
}

function buildLatestYearSummaryPath({ make, model }) {
  return `makes["${make}"].models["${model}"].latestYear.consumerReviews.ratings.average`;
}

function buildConsumerReviewsAverageRating({ make, model, year }) {
  return `makes["${makeNiceName(make)}"].models["${makeNiceName(
    model
  )}"].years["${year}"].consumerReviews.ratings.average`;
}

function buildConsumerReviewsPath({ make, model, submodel, year, pageNum }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].consumerReviews.reviewContent[${pageNum}]`;
}

function buildConsumerReviewsPathFromSubmodel(makeModelSubmodelYear) {
  return buildConsumerReviewsPath({
    make: makeModelSubmodelYear.make.slug,
    model: makeModelSubmodelYear.model.slug,
    submodel: makeModelSubmodelYear.submodels.slug,
    year: makeModelSubmodelYear.year,
    pageNum: 1,
  });
}

function buildConsumerReviewsRatingsCountPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].consumerReviews.ratings.count`;
}

function buildSubmodelConsumerReviewsRatingsCountPath({ make, model, submodel, year }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].consumerReviews.ratings.count`;
}

function buildReviewsAggregationPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].reviewsAggregation`;
}

function buildReviewsSummaryPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].reviewsSummary`;
}

function buildConsumerReviewsRatingsCountPathFromParams({ make, model, submodel, year }) {
  return submodel
    ? buildSubmodelConsumerReviewsRatingsCountPath({ make, model, submodel, year })
    : buildConsumerReviewsRatingsCountPath({ make, model, year });
}

function buildConsumerReviewsRatingsCountPathFromFilter() {
  return 'consumerReviews.ratings.count';
}

/**
 * We don't use submodel in this function.
 * We use MMSY object, because we've already had it in /client/site-modules/core-page/pages/index/index.jsx
 */
function buildConsumerReviewsRatingsCountPathFromSubmodel(makeModelSubmodelYear) {
  return buildConsumerReviewsRatingsCountPath({
    make: get(makeModelSubmodelYear, 'make.slug'),
    model: get(makeModelSubmodelYear, 'model.slug'),
    year: get(makeModelSubmodelYear, 'year'),
  });
}

function buildSubmodelConsumerReviewsRatingsCountPathFromVehicle(vehicle) {
  const make = get(vehicle, 'make.slug');
  const model = get(vehicle, 'model.slug');
  const submodel = get(vehicle, 'submodels.slug');
  const year = get(vehicle, 'year');
  return make && model && year ? buildSubmodelConsumerReviewsRatingsCountPath({ make, model, submodel, year }) : '';
}

function buildConsumerReviewsRatingsCountPathFromVehicle(vehicle) {
  const make = get(vehicle, 'make.slug');
  const model = get(vehicle, 'model.slug');
  const year = get(vehicle, 'year');
  return make && model && year ? buildConsumerReviewsRatingsCountPath({ make, model, year }) : '';
}

function buildConsumerReviewsRatingsCountPathByStyleId(styleId) {
  return `style["${styleId}"].ratings.count`;
}

function buildReviewDataPath(reviewId) {
  return `reviews["${reviewId}"]`;
}

function buildThumbsPath(reviewId) {
  return `thumbs["${reviewId}"]`;
}

function getConsumerReviewsFilterPath() {
  return 'reviewsFilter';
}

function getConsumerReviewsPath() {
  return 'consumerReviews';
}

function getVehicleFilterPath() {
  return 'vehicleFilter';
}

function getStylesPath(isOnlyCurrentYear) {
  return isOnlyCurrentYear ? 'yearStylesList' : 'stylesList';
}

function getCreateAndUpdateReviewIdPath() {
  return 'writeReview.reviewId';
}

export const ConsumerReviewsPaths = {
  buildLatestYearSummaryPath,
  buildConsumerReviewsAverageRating,
  buildReviewsSummaryPath,
  buildConsumerReviewsPath,
  buildConsumerReviewsPathFromSubmodel,
  buildConsumerReviewsRatingsCountPathFromParams,
  buildConsumerReviewsRatingsCountPathFromFilter,
  buildConsumerReviewsRatingsCountPath,
  buildConsumerReviewsRatingsCountPathFromSubmodel,
  buildConsumerReviewsRatingsCountPathFromVehicle,
  buildConsumerReviewsRatingsCountPathByStyleId,
  buildSubmodelConsumerReviewsRatingsCountPath,
  buildSubmodelConsumerReviewsRatingsCountPathFromVehicle,
  buildConsumerReviewsAspectsPath,
  buildReviewDataPath,
  buildThumbsPath,
  buildReviewsAggregationPath,
  getConsumerReviewsFilterPath,
  getConsumerReviewsPath,
  getVehicleFilterPath,
  getStylesPath,
  getCreateAndUpdateReviewIdPath,
};

async function getReviewYears(vehicleFilter, context) {
  const { make, model, submodel, year, styleId } = vehicleFilter;

  if (make && model && year && !submodel && !styleId) {
    return get(await context.resolveValue(buildReviewsAggregationPath({ make, model, year })), 'reviewsYears');
  }

  return [parseInt(year, 10)];
}

export const ConsumerReviewsModel = createModelSegment('consumerReviews', [
  /**
   * https://www.edmunds.com/api/vehiclereviews/v3/makes/honda/models/accord/submodels/coupe/years/2017
   */
  {
    path:
      'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].consumerReviews.reviewContent[{pageNum}]',
    async resolve(match, context) {
      const apiUrl = `/vehiclereviews/v3/makes/${match.make}/models/${match.model}/submodels/${match.submodel}/years/${
        match.year
      }${match.pageNum && `?pageNum=${match.pageNum}`}`;
      const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(apiUrl, {
          timeout: 1000,
          cache: extendedApiCache ? 'force-cache' : undefined,
        })
        .then(response => response);
    },
  },
  /**
   * http://www.edmunds.com/api/vehiclereviews/v3/honda/accord/2017/ratings/average?fmt=graph
   * @return ConsumerReviewsEntities.RatingsSummary
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].consumerReviews.ratings.average',
    resolve(match, context) {
      const { makeSlug, modelSlug, year } = match;
      const url = `/vehiclereviews/v3/${makeSlug}/${modelSlug}/${year}/ratings/average?fmt=graph`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results);
    },
  },
  /**
   * @return {{ ref: ConsumerReviewsEntities.RatingsSummary }|{}}
   */
  {
    path: 'makes["{make}"].models["{model}"].latestYear.consumerReviews.ratings.average',
    resolve(match, context) {
      const { make, model } = match;
      let year = null;
      return context
        .resolveValue(`makes["${make}"].models["${model}"].latestNonPreprodVehicle`, VehicleModel)
        .then(latestVehicle => {
          if (latestVehicle && latestVehicle.ref && latestVehicle.ref.year) {
            year = latestVehicle.ref.year;
            return context
              .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].consumerReviews.ratings.average`)
              .then(result =>
                result
                  ? { ref: { $ref: `#/makes/${make}/models/${model}/years/${year}/consumerReviews/ratings/average` } }
                  : {}
              );
          }
          return {};
        });
    },
  },
  /**
   * Example: https://prod-www.edmunds.com/gateway/api/coreresearch/v1/reviewsaggregation/honda/accord/2022
   * @see buildReviewsAggregationPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].reviewsAggregation',
    async resolve({ makeSlug, modelSlug, year }, context) {
      const defaultAggregation = { reviewsYears: [parseInt(year, 10)] };
      const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/coreresearch/v1/reviewsaggregation/${makeSlug}/${modelSlug}/${year}`, {
          timeout: 1000,
          cache: extendedApiCache ? 'force-cache' : undefined,
        })
        .then(response => get(response, 'results[0]', defaultAggregation))
        .catch(() => defaultAggregation);
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/gateway/api/cr-summaries/v1/overall/audi/q5/2021
   * @see buildReviewsSummaryPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].reviewsSummary',
    async resolve({ makeSlug, modelSlug, year }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/cr-summaries/v1/overall/${makeSlug}/${modelSlug}/${year}`)
        .then(response => get(response, 'results.output', null));
    },
  },
  /**
   * Takes in styleId and returns its total reviews, and star rating breakdown
   * @see buildConsumerReviewsRatingsCountPathByStyleId
   * @return ConsumerReviewsEntities.ConsumerReviewsRatingsCount
   */
  {
    path: 'style["{styleId}"].ratings.count',
    resolve({ styleId }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($vehicleFilter: VehicleFilter!) {
              reviewsCount: vehicleReviews(vehicleFilter: $vehicleFilter) {
                ratingAggregation {
                  star1
                  star2
                  star3
                  star4
                  star5
                  averageStars
                }
                totalReviews
              }
            }
          `,
          {
            vehicleFilter: { styleId: `${styleId}` },
          }
        )
        .then(response => calculateRatingPercents(get(response, 'reviewsCount', null)));
    },
  },
  /**
   * Takes in MMY and returns its total reviews, and star rating breakdown
   * @see buildConsumerReviewsRatingsCountPath
   * @return ConsumerReviewsEntities.ConsumerReviewsRatingsCount
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].consumerReviews.ratings.count',
    async resolve({ make, model, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($vehicleFilter: VehicleFilter!) {
              reviewsCount: vehicleReviews(vehicleFilter: $vehicleFilter) {
                ratingAggregation {
                  star1
                  star2
                  star3
                  star4
                  star5
                  averageStars
                }
                totalReviews
              }
            }
          `,
          {
            vehicleFilter: {
              makeSlug: make,
              modelSlug: model,
              years: await getReviewYears({ make, model, year }, context),
            },
          }
        )
        .then(response => calculateRatingPercents(get(response, 'reviewsCount', null)));
    },
  },
  /**
   * Takes the MMY from vehicleFilter  and returns its total reviews, and star rating breakdown
   * @return ConsumerReviewsEntities.ConsumerReviewsRatingsCount
   */
  {
    path: 'consumerReviews.ratings.count',
    async resolve(match, context) {
      const vehicleFilter = await context.resolveValue('vehicleFilter');

      if (!vehicleFilter) {
        logger('info', 'consumerReviews.ratings.count resolve aborted because vehicleFilter is not defined');
        return context.abort(); // Handling loading the no server-side state
      }

      const { make, model, year } = vehicleFilter;

      if (!make || !model || !year) return null;

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($vehicleFilter: VehicleFilter!) {
              reviewsCount: vehicleReviews(vehicleFilter: $vehicleFilter) {
                ratingAggregation {
                  star1
                  star2
                  star3
                  star4
                  star5
                  averageStars
                }
                totalReviews
              }
            }
          `,
          {
            vehicleFilter: {
              makeSlug: make,
              modelSlug: model,
              years: await getReviewYears({ make, model, year }, context),
            },
          }
        )
        .then(response => calculateRatingPercents(get(response, 'reviewsCount', null)));
    },
  },
  /**
   * Takes in MMYS and returns its total reviews, and star rating breakdown
   * @return ConsumerReviewsEntities.ConsumerReviewsRatingsCount
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].consumerReviews.ratings.count',
    resolve({ make, model, submodel, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($vehicleFilter: VehicleFilter!) {
              reviewsCount: vehicleReviews(vehicleFilter: $vehicleFilter) {
                ratingAggregation {
                  star1
                  star2
                  star3
                  star4
                  star5
                  averageStars
                }
                totalReviews
              }
            }
          `,
          {
            vehicleFilter: {
              makeSlug: make,
              modelSlug: model,
              year: parseInt(year, 10),
              submodel,
            },
          }
        )
        .then(response => calculateRatingPercents(get(response, 'reviewsCount', null)));
    },
  },
  /**
   * Takes in MMYS or styleId and returns consumer reviews based on those parameter values
   *
   * @return ConsumerReviewsEntities.ConsumerReviewsContent
   * @see ConsumerReviewsPaths.getConsumerReviewsPath
   */
  {
    path: 'consumerReviews',
    async resolve(match, context) {
      const vehicleFilter = await context.resolveValue('vehicleFilter');
      if (!vehicleFilter) {
        logger('info', 'consumerReviews resolve aborted because vehicleFilter is not defined');
        return context.abort(); // Handling loading the no server-side state
      }
      const { make, model, submodel, styleId, years } = vehicleFilter;
      const { sorting, pagenum, pagesize, aspect, rating, polarity } = await context.resolveValue('reviewsFilter');
      const graphQlRatingParam = rating === DEFAULT_RATING ? null : rating;
      if (aspect) {
        return getConsumerReviewsFilteredByAspect(
          vehicleFilter,
          { pagenum, pagesize, aspect, graphQlRatingParam, polarity },
          context
        );
      }

      if (styleId && styleId !== NO_STYLE_ID) {
        return withMetrics(EdmundsGraphQLFederation, context)
          .query(
            gql`
              query(
                $vehicleFilter: VehicleFilter!
                $pageRequest: PageRequest
                $sortBy: ReviewSort
                $reviewFilter: ReviewFilter
              ) {
                vehicleReviews(
                  vehicleFilter: $vehicleFilter
                  page: $pageRequest
                  sortBy: $sortBy
                  reviewFilter: $reviewFilter
                ) {
                  ratingAggregation {
                    averageStars
                  }
                  totalReviews
                  reviews {
                    id
                    author {
                      authorName
                    }
                    created
                    updated
                    styleId
                    styleName
                    spans {
                      start
                      end
                    }
                    title
                    text
                    thumbsUp
                    thumbsDown
                    city
                    state
                    vehicleRating {
                      overall
                      categorized {
                        value
                        safety
                        performance
                        reliability
                        technology
                        interior
                        comfort
                      }
                    }
                  }
                }
              }
            `,
            {
              vehicleFilter: { styleId: `${styleId}` },
              pageRequest: {
                pageNum: pagenum,
                pageSize: pagesize,
              },
              reviewFilter: {
                userRating: graphQlRatingParam,
              },
              sortBy: JSON.parse(sorting),
            }
          )
          .then(response => get(response, 'vehicleReviews', null));
      }

      const gqlVehicleFilter = {
        makeSlug: make,
        modelSlug: model,
        years,
      };

      if (submodel) {
        gqlVehicleFilter.submodel = submodel;
      }

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query(
              $vehicleFilter: VehicleFilter!
              $pageRequest: PageRequest
              $sortBy: ReviewSort
              $reviewFilter: ReviewFilter
            ) {
              vehicleReviews(
                vehicleFilter: $vehicleFilter
                page: $pageRequest
                sortBy: $sortBy
                reviewFilter: $reviewFilter
              ) {
                ratingAggregation {
                  averageStars
                }
                totalReviews
                reviews {
                  id
                  author {
                    authorName
                  }
                  created
                  updated
                  styleId
                  year
                  styleName
                  spans {
                    start
                    end
                  }
                  title
                  text
                  thumbsUp
                  thumbsDown
                  vehicleRating {
                    overall
                    categorized {
                      value
                      safety
                      performance
                      reliability
                      technology
                      interior
                      comfort
                    }
                  }
                }
              }
            }
          `,
          {
            vehicleFilter: gqlVehicleFilter,
            pageRequest: {
              pageNum: pagenum,
              pageSize: pagesize,
            },
            sortBy: JSON.parse(sorting),
            reviewFilter: {
              userRating: graphQlRatingParam,
            },
          }
        )
        .then(response => get(response, 'vehicleReviews', null));
    },
  },
  /**
   * ex: https://qa-21-www.edmunds.com/api/coreresearch/v1/vehiclereviews/makes/honda/models/civic/years/2022/styles
   * @return ConsumerReviewsEntities.Styles
   * @see ConsumerReviewsPaths.getStylesPath (key: submodel, value: styles)
   */
  {
    path: 'yearStylesList',
    async resolve(match, context) {
      const vehicleFilter = await context.resolveValue('vehicleFilter');
      if (!vehicleFilter) return context.abort();
      const { make, model, year } = vehicleFilter;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/coreresearch/v1/vehiclereviews/makes/${make}/models/${model}/years/${year}/styles`)
        .then(response => filterFleetSubmodelStyles(response?.results, context));
    },
  },
  /**
   * ex: https://qa-21-www.edmunds.com/api/coreresearch/v1/vehiclereviews/makes/honda/models/civic/years/2022/styles
   * @return ConsumerReviewsEntities.Styles
   * @see ConsumerReviewsPaths.getStylesPath (key: year, value: styles)
   */
  {
    path: 'stylesList',
    async resolve(match, context) {
      const vehicleFilter = await context.resolveValue('vehicleFilter');
      if (!vehicleFilter) return context.abort();
      const { make, model, years } = vehicleFilter;

      return Promise.all(
        years.map(year =>
          withMetrics(EdmundsAPI, context)
            .fetchJson(`/coreresearch/v1/vehiclereviews/makes/${make}/models/${model}/years/${year}/styles`)
            .then(response => filterFleetSubmodelStyles(response?.results, context))
            .then(submodelStyles => ({
              [year]: flatten(Object.values(submodelStyles)),
            }))
        )
      ).then(yearsStyles => Object.assign({}, ...yearsStyles));
    },
  },
  /**
   * @return ConsumerReviewsEntities.ReviewsFilter
   * @see ConsumerReviewsPaths.getConsumerReviewsFilterPath
   */
  {
    path: 'reviewsFilter',
    resolve() {
      return Promise.resolve(DEFAULT_FILTER);
    },
    update(newValue, match, context) {
      return context.resolveValue('reviewsFilter').then(reviewsFilter => ({
        ...reviewsFilter,
        ...newValue,
      }));
    },
  },
  /**
   * @return ConsumerReviewsEntities.VehicleFilter
   * @see ConsumerReviewsPaths.getVehicleFilterPath
   */
  {
    path: 'vehicleFilter',
    async update({ params }, match, context) {
      const vehicleFilter = {
        make: params.make,
        model: params.model,
        year: params.year,
        years: await getReviewYears(params, context),
        styleId: params.styleId ? parseInt(params.styleId, 10) : NO_STYLE_ID,
      };
      if (params.submodel) {
        vehicleFilter.submodel = params.submodel;
      }
      return Promise.resolve(vehicleFilter);
    },
  },
  /**
   * @see ConsumerReviewsPaths.buildThumbsPath
   */
  {
    path: 'thumbs["{reviewId}"]',
    /**
     * Takes a reviewId and returns a boolean representing if the user has submitted a thumbsUp or thumbsDown
     * @return boolean
     */

    resolve({ reviewId }) {
      return Promise.resolve(ThumbsStorage.isReviewRated(reviewId));
    },
    /**
     * Takes isHelpful boolean, uses the reviewId from path and makes a POST request to submit a thumbsUp or thumbsDown rating.
     * It stores true if the api is submitted successfully
     * Example thumb up: https://qa-21-www.edmunds.com/gateway/api/vehiclereviews/v2/thumbsup/1450679328244940800
     * Example thumb down: https://qa-21-www.edmunds.com/gateway/api/vehiclereviews/v2/thumbsdown/1450679328244940800
     * @return boolean
     */
    update(isHelpful, { reviewId }, context) {
      const apiUrl = getThumbsApi({ reviewId, isHelpful });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(apiUrl, { method: 'POST' })
        .then(() => {
          ThumbsStorage.markReviewRated(reviewId);
          return true;
        })
        .catch(err => {
          logger('error', `Post helpful error - ${err}`);
          return null;
        });
    },
  },
  /**
   * Takes vehicle data (make, model, submodel and year) and returns aspects data for filtering consumer reviews
   * @returns array of objects defined in ConsumerReviewsAspects
   * @see buildConsumerReviewsAspectsPath
   */
  {
    path: 'aspects',
    async resolve(match, context) {
      const vehicleFilter = await context.resolveValue('vehicleFilter');
      if (!vehicleFilter) return context.abort();

      const { make, model, submodel, years } = vehicleFilter;
      const gqlVehicleFilter = {
        makeSlug: make,
        modelSlug: model,
        years,
      };

      if (submodel) {
        gqlVehicleFilter.submodel = submodel;
      }

      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($vehicleFilter: VehicleFilter!) {
              vehicleTopics: vehicleReviews(vehicleFilter: $vehicleFilter) {
                pros: topics(polarity: POSITIVE) {
                  displayName
                  polarity
                  topic
                }
                cons: topics(polarity: NEGATIVE) {
                  displayName
                  polarity
                  topic
                }
                neutral: topics(polarity: NEUTRAL) {
                  displayName
                  polarity
                  topic
                }
              }
            }
          `,
          {
            vehicleFilter: gqlVehicleFilter,
          }
        )
        .then(response => get(response, 'vehicleTopics', null));
    },
  },

  /**
   * Path is used to create/update review and store its reviewId.
   * There are 3 usages depending on data for update()
   * - data {String} Stores reviewId without any actions.
   * - data {Object with reviewId} Makes API call to update a review with passed reviewId. Stores reviewId.
   * - data {Object without reviewId} Makes API call to create a review. Create API returns new reviewId which is stored.
   * @params { make, model, year, bodytype, styleId, userRating, authorName, email, title, text, newReview } required for create
   * @params { id } required for update
   * @see ConsumerReviewsPaths.getCreateAndUpdateReviewIdPath
   */
  {
    path: 'writeReview.reviewId',
    async update(data, match, context) {
      if (isString(data)) {
        // When using preloader.set to store reviewId
        return Promise.resolve(data);
      }

      const [sessionId, visitorId] = await Promise.all([
        context.resolveValue('session', VisitorModel),
        context.resolveValue('id', VisitorModel),
      ]);

      if (data.reviewId) {
        // Updating existing review
        return withMetrics(EdmundsAPI, context)
          .fetchJson('/vehiclereviews/v3/update/', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ sessionId, visitorId, ...transformWriteReviewData(data) }),
          })
          .then(({ id }) => id)
          .catch(err => {
            logger('error', `Update review error - ${err}`);
            // Caught in the component to display 'try again'
            throw new Error();
          });
      }

      // Creating new review
      return withMetrics(EdmundsAPI, context)
        .fetchJson('/vehiclereviews/v2/?venom_form=true', {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            newReview: true,
            sessionId,
            visitorId,
            ...data,
          }),
        })
        .then(({ id }) => id)
        .catch(err => {
          logger('error', `Write new review error - ${err}`);
          // Caught in the component to display 'try again'
          throw new Error();
        });
    },
  },
  /**
   * @see ConsumerReviewsPaths.buildReviewDataPath
   * @return {Object} Consumer review data
   */
  {
    path: 'reviews["{reviewId}"]',
    resolve({ reviewId }, context) {
      const apiUrl = `/vehiclereviews/v2/${reviewId}?view=full&clearCache=${Date.now()}`;
      return withMetrics(EdmundsAPI, context).fetchJson(apiUrl);
    },
  },
]);
