import { PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { ELIGIBILITY_FIELD_MAP_TARGET } from 'site-modules/shared/components/incentives/incentives-wizard/constants/eligibility';
import { mapBase } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/base-mapper';

const ELIGIBILITY_FIELD_QUESTIONS_MAP = {
  assistanceParticipant: [PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID],
};

export const mapAssistance = (field, target = ELIGIBILITY_FIELD_MAP_TARGET.DISPLAY) =>
  ELIGIBILITY_FIELD_QUESTIONS_MAP[field] || mapBase(field, target);
