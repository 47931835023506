import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EDMUNDS_LOGO_TEXT } from 'client/images/inline-svgs/edmunds-logo-text';

import './price-checker-header.scss';

export function PriceCheckerHeader({ className, imageHeight, textClassName }) {
  return (
    <div className={classnames('price-checker-header d-flex align-items-center', className)}>
      {EDMUNDS_LOGO_TEXT({
        className: 'logo pe-0_5',
        height: imageHeight,
        fillColor: '#475569',
        iconFillColor: '#2070e8',
      })}
      <span className={classnames('align-middle ps-0_5', textClassName)}>
        PRICE CHECKER<sup>&trade;</sup>
      </span>
    </div>
  );
}

PriceCheckerHeader.propTypes = {
  imageHeight: PropTypes.number,
  className: PropTypes.string,
  textClassName: PropTypes.string,
};

PriceCheckerHeader.defaultProps = {
  imageHeight: 20,
  className: null,
  textClassName: 'header-5-inline',
};
