import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Input } from 'reactstrap';
import { Button } from 'site-modules/shared/components/inventory/button/button';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';

import './wizard-input.scss';

export const WizardInput = memo(
  ({
    id,
    value,
    onChange,
    ariaLabel,
    startAdornment: StartAdornmentComponent,
    onBlur,
    disabled,
    inputClassName,
    isLoading,
    ...restInputProps
  }) => (
    <div className="wizard-input pos-r">
      {StartAdornmentComponent && <StartAdornmentComponent />}
      <Input
        id={id}
        type="text"
        value={value}
        onChange={onChange}
        className={classnames('input-el size-20 rounded-8', inputClassName)}
        aria-label={ariaLabel}
        {...restInputProps}
      />
      <Button
        color="outline"
        size="sm"
        type="submit"
        className={classnames(
          'submit-btn bg-cool-gray-70 pos-a d-flex justify-content-center align-items-center rounded-6',
          { 'bg-blue-50': !disabled }
        )}
        aria-label="Submit"
        disabled={disabled}
      >
        {isLoading && <Spinner thickness={2} />}
        {!isLoading && <span className="icon-arrow-right8 icon text-white size-16" aria-hidden />}
      </Button>
    </div>
  )
);

WizardInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  startAdornment: PropTypes.elementType,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  inputClassName: PropTypes.string,
};
WizardInput.defaultProps = {
  value: undefined,
  ariaLabel: null,
  startAdornment: null,
  disabled: false,
  isLoading: false,
  inputClassName: null,
  onChange: noop,
};

WizardInput.displayName = 'WizardInput';
