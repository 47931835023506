import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';

export const EmailReviewAnswersCta = forwardRef((props, ref) => {
  const { className, icon, children, ...rest } = props;

  return (
    <Button
      className={classnames(
        'text-transform-none text-cool-gray-30 bg-transparent border-0 p-0 text-center w-100 px-0_5',
        className
      )}
      innerRef={ref}
      {...rest}
    >
      {icon}
      <span className="text-underline size-16">{children}</span>
    </Button>
  );
});

EmailReviewAnswersCta.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
