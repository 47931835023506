import React from 'react';
import PropTypes from 'prop-types';

export function BackButton({ onBack }) {
  return (
    <div className="mb-1_5">
      <button
        className="btn d-flex align-items-center border-0 bg-transparent p-0"
        onClick={onBack}
        aria-label="Back to previous question."
      >
        <i aria-hidden className="icon-arrow-left3 size-14 text-cool-gray-50 me-0_5" />
        <span className="size-16 text-cool-gray-50">back</span>
      </button>
    </div>
  );
}

BackButton.propTypes = { onBack: PropTypes.func.isRequired };
