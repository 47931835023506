import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
// Constants
import { wizardTrackingIds } from 'client/tracking/maps/incentives-wizard';
// Utils
import { getPlural } from 'client/utils/plural';
// Components
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { EligibilityCard } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/eligibility-card';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';

import './eligibility-incentives-list.scss';

const orderByRebateAmount = incentives =>
  orderBy(
    incentives,
    [
      incentive => {
        if (incentive.rebateOverridesByEligibility?.[0]?.rebateAmount) {
          return incentive.rebateOverridesByEligibility[0].rebateAmount;
        }

        return incentive.rebateAmount;
      },
    ],
    ['desc']
  );

export const EligibilityIncentivesList = memo(props => {
  const { eligibleIncentives, ineligibleIncentives } = props;
  const sortedEligibleIncentives = useMemo(() => orderByRebateAmount(eligibleIncentives), [eligibleIncentives]);
  const sortedIneligibleIncentives = useMemo(() => orderByRebateAmount(ineligibleIncentives), [ineligibleIncentives]);

  const collapseText = `Show ${sortedIneligibleIncentives.length} ineligible ${getPlural(
    'incentive',
    sortedIneligibleIncentives.length
  )}`;

  return (
    <div className="eligibility-incentives-list">
      <div className="d-flex flex-column align-items-center">
        {!!sortedEligibleIncentives.length && (
          <>
            <div className="text-cool-gray-10 size-20 fw-bold mt-2 mb-1_5 text-center">
              <i aria-hidden className="icon icon-checkmark-circle size-20 text-green-50 me-0_5" />
              You’re eligible for:
            </div>
            <ul
              className={classnames('list-unstyled eligibility-cards w-100', {
                'two-col': sortedEligibleIncentives.length > 1,
              })}
            >
              {sortedEligibleIncentives.map(incentive => (
                <EligibilityCard key={incentive.id} incentive={incentive} />
              ))}
            </ul>
          </>
        )}
        {!!sortedIneligibleIncentives.length && (
          <ContentCollapse
            textClosed={collapseText}
            textOpen={collapseText}
            classes="w-100"
            justify="mt-2 justify-content-center"
            btnContainerClasses="m-0 p-0"
            btnClasses="p-0 size-16 fw-normal"
            btnTextClasses="text-cool-gray-30 text-underline"
            btnTrackingId={wizardTrackingIds.displayIneligibleIncentiveDetails}
            isSameExpandCollapseTracking
            btnIgnoreTrackingValue
          >
            <div className="d-flex flex-column align-items-center">
              <div className="text-cool-gray-10 size-20 fw-bold mt-1_5 mb-1_5 text-center">
                <i aria-hidden className="icon icon-cancel-circle2 size-20 text-cool-gray-50 me-0_5" />
                You’re not eligible for:
              </div>
              <ul
                className={classnames('eligibility-cards list-unstyled w-100', {
                  'two-col': sortedIneligibleIncentives.length > 1,
                })}
              >
                {sortedIneligibleIncentives.map(incentive => (
                  <EligibilityCard key={incentive.id} incentive={incentive} isIneligible />
                ))}
              </ul>
            </div>
          </ContentCollapse>
        )}
      </div>
    </div>
  );
});

EligibilityIncentivesList.displayName = 'EligibilityIncentivesList';

EligibilityIncentivesList.propTypes = {
  eligibleIncentives: PropTypes.arrayOf(Incentive).isRequired,
  ineligibleIncentives: PropTypes.arrayOf(Incentive).isRequired,
};
