import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Button from 'reactstrap/lib/Button';

export const SellCarCta = ({ ctaText, onClick }) => (
  <Button className="btn-blue-50 text-transform-none size-16 text-white py-1 px-2" onClick={onClick}>
    {ctaText}
  </Button>
);

SellCarCta.propTypes = {
  ctaText: PropTypes.string,
  onClick: PropTypes.func,
};

SellCarCta.defaultProps = {
  ctaText: '',
  onClick: noop,
};
