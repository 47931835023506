import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
/* Constants */
import {
  PRIVATE_PARTY_SURVEY_LIST,
  OTHER,
  PRIVATE_PARTY_THANK_YOU,
} from 'site-modules/shared/constants/private-party/private-party';
import { PRIVATE_PARTY_EVENTS } from 'site-modules/shared/constants/private-party/private-party-events';
/* Utils */
import { checkIsDisabled } from 'site-modules/shared/utils/private-party/private-party';
import { EventToolbox } from 'client/utils/event-toolbox';

export function PrivatePartySurvey({ onClick }) {
  const [otherValue, setOtherValue] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkboxes, setCheckboxes] = useState(
    [...PRIVATE_PARTY_SURVEY_LIST, OTHER].reduce((result, item) => {
      const updatedResult = result;
      updatedResult[item] = false;
      return updatedResult;
    }, {})
  );

  useEffect(() => {
    setIsDisabled(checkIsDisabled(checkboxes));
  }, [checkboxes]);

  const handleChange = useCallback(
    ({ currentTarget: { value } }) => {
      setCheckboxes({ ...checkboxes, [value]: !checkboxes[value] });
    },
    [checkboxes]
  );

  const handleTextChange = useCallback(({ currentTarget: { value } }) => {
    setOtherValue(value);
  }, []);

  const handleClick = useCallback(() => {
    const values = Object.entries(checkboxes).reduce((result, item) => {
      const [key, value] = item;
      if (value) {
        if (key === OTHER) {
          result.push(otherValue);
        } else {
          result.push(key);
        }
      }
      return result;
    }, []);

    EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.SUBMIT_SURVEY, {
      value: values,
    });

    onClick(PRIVATE_PARTY_THANK_YOU);
  }, [checkboxes, onClick, otherValue]);

  return (
    <div className="private-party-modal-content bg-white py-2 px-1_5 px-md-2_5 mt-1_5">
      <h2 className="fw-bold size-20 mb-0_5">Thanks for your interest! This feature isn&apos;t yet available.</h2>
      <p className="mb-1_5 size-16">Help us know more about how this could help you and other shoppers:</p>
      <h3 className="fw-bold size-16">What about listing your car for sale on Edmunds is most appealing to you?</h3>
      <div>
        {PRIVATE_PARTY_SURVEY_LIST.map(item => (
          <div className={classnames('ps-1_25', { 'text-blue-40': checkboxes[item] })} key={item}>
            <Label className=" mb-0_25 d-flex" for={item}>
              <div>
                <Input
                  className="d-block c-pointer bg-white me-1"
                  name={item}
                  onChange={handleChange}
                  id={item}
                  type="checkbox"
                  value={item}
                />
              </div>

              {item}
            </Label>
          </div>
        ))}
        <div className="mb-1 ps-1_25 ">
          <Label className="d-flex align-items-center mb-0" for={OTHER}>
            <div>
              <Input name={OTHER} value={OTHER} onChange={handleChange} id={OTHER} type="checkbox" className="me-1" />
            </div>
            <Input className="size-16" type="text" placeholder="Other" onBlur={handleTextChange} />
          </Label>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          onClick={handleClick}
          disabled={isDisabled}
          className={classnames('text-white  text-transform-none', {
            'bg-green-50': !isDisabled,
            'bg-cool-gray-60': isDisabled,
          })}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

PrivatePartySurvey.propTypes = {
  onClick: PropTypes.func,
};

PrivatePartySurvey.defaultProps = {
  onClick: noop,
};
