import React from 'react';

export function PrivatePartyThankYou() {
  return (
    <div className="private-party-modal-content bg-white px-1_5 px-md-2_5 py-1_5 mt-1_5">
      <h2 className="fw-bold size-20 mb-1">We appreciate your feedback!</h2>
      <p className="size-16 mb-0_75">It will help us make our website better for all our valued visitors!</p>
    </div>
  );
}
