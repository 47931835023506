import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export function SellCarHeader({ isMobile, isTED6571Chal2, classNames }) {
  return (
    <h2 className={classnames('header fw-bold', classNames)}>
      Sell Your Car{' '}
      {isTED6571Chal2 && !isMobile ? (
        <>
          <span className="text-blue-50">Yourself on Edmunds.</span>
          <br />
          You&apos;re Almost There!
        </>
      ) : (
        <>
          Yourself on Edmunds. <span className="text-blue-50">It&apos;s Easy!</span>
        </>
      )}
    </h2>
  );
}

SellCarHeader.propTypes = {
  isMobile: PropTypes.bool,
  isTED6571Chal2: PropTypes.bool,
  classNames: PropTypes.string,
};

SellCarHeader.defaultProps = {
  isMobile: false,
  isTED6571Chal2: false,
  classNames: '',
};
