import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { wizardTrackingIds } from 'client/tracking/maps/incentives-wizard';
import { Link } from 'site-modules/shared/components/link/link';
import { Button } from 'site-modules/shared/components/inventory/button/button';

export function ResultScreenCta({ wrapperClassName, srpLink, onExit, children }) {
  const containerRef = useRef();
  const [isVisible] = useOnScreen(containerRef, {}, !!srpLink);

  useEffect(() => {
    if (isVisible) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
        event_data: {
          action_name: TrackingConstant.ACTION_WIDGET_VIEW,
          action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
          creative_id: 'srp-link-in-incentives-matcher',
        },
      });
    }
  }, [isVisible]);

  const shoppingButtonProps = srpLink
    ? { tag: Link, to: srpLink, 'data-tracking-id': 'view_search_result' }
    : { onClick: onExit, 'data-tracking-id': wizardTrackingIds.closeDrawer };

  return (
    <div ref={containerRef} className={wrapperClassName}>
      <Button
        color="blue-50"
        size="lg"
        className="submit-btn text-white text-transform-none fw-medium py-1 px-2"
        {...shoppingButtonProps}
      >
        {children}
      </Button>
    </div>
  );
}

ResultScreenCta.propTypes = {
  children: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  srpLink: PropTypes.string,
  onExit: PropTypes.func,
};

ResultScreenCta.defaultProps = {
  wrapperClassName: null,
  srpLink: null,
  onExit: noop,
};
