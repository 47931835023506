import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Progress from 'reactstrap/lib/Progress';
import { QUESTION } from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-variants';
import { questions } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import './wizard-progress-bar.scss';

export function WizardProgressBar({ currentStepIndex, flowSteps }) {
  const [value, setValue] = useState(0);
  const availableQuestions = flowSteps.filter(stepId => questions[stepId].variant === QUESTION);

  useEffect(() => {
    const prevQuestionIndex = availableQuestions.findIndex(id => id === flowSteps.at(currentStepIndex - 1));
    if (prevQuestionIndex !== -1) {
      setValue(prevQuestionIndex + 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  return (
    <div className="wizard-progress-bar">
      <span className="visually-hidden" aria-live="polite" aria-atomic>
        Completed questions: {value} of {availableQuestions.length}.
      </span>
      <Progress value={value} max={availableQuestions.length} className="rounded-0 bg-cool-gray-70 w-100" aria-hidden>
        <div className="ps-0_5 wizard-progress pos-r h-100" />
      </Progress>
    </div>
  );
}

WizardProgressBar.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
  flowSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
};
