import { gql } from '@apollo/client'; // eslint-disable-line
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { VisitorModel } from 'client/data/models/visitor';

export const INVENTORY_CODE = { NEW: 'NEW', USED: 'USED' };

const EvInsightsData = PropTypes.shape({
  style: PropTypes.shape({
    epaSpecifications: PropTypes.shape({
      electricityRange: PropTypes.number,
      batteryChargeTime: PropTypes.number,
    }),
    engine: PropTypes.shape({
      engineType: PropTypes.string,
      electricMotor: PropTypes.shape({
        fastChargePortType: PropTypes.string,
      }),
    }),
    batteryWarranty: PropTypes.shape({
      name: PropTypes.string,
      comments: PropTypes.string,
      warrantyType: PropTypes.string,
      maxYears: PropTypes.string,
      maxYearsType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
      maxMileage: PropTypes.string,
      maxMileageType: PropTypes.oneOf(['LIMITED', 'UNLIMITED']),
    }),
  }),
  incentives: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      programType: PropTypes.string,
      rebateAmount: PropTypes.number,
      restrictions: PropTypes.string,
      subtype: PropTypes.string,
    })
  ),
});

export const EvInsightsEntities = {
  EvInsightsData,
};

const getEvInsightsDataPath = (styleId, inventoryCode = INVENTORY_CODE.NEW) =>
  styleId && `styles["${styleId}"].inventoryCode["${inventoryCode}"].insights`;

export const EvInsightsPaths = {
  getEvInsightsDataPath,
};

export const EvInsightsModel = createModelSegment('evInsights', [
  {
    /**
     * @see getEvInsightsDataPath
     */
    path: 'styles["{styleId}"].inventoryCode["{inventoryCode}"].insights',
    async resolve({ styleId, inventoryCode }, context) {
      const { zipCode } = await context.resolveValue('location', VisitorModel);

      // TODO: Skip old incentives after core-6416
      const data = await withMetrics(EdmundsGraphQLFederation, context).query(
        gql`
          query($zipCode: String!, $styleId: String!, $inventoryCode: InventoryCode!) {
            style(styleId: $styleId) {
              warranty {
                name
                comments
                warrantyType
                maxMileage
                maxMileageType
                maxYears
                maxYearsType
              }
              epaSpecifications {
                electricityRange
                batteryChargeTime
              }
              engine {
                engineType
                electricMotor {
                  fastChargePortType
                }
              }
            }
            incentives(
              incentiveFilters: {
                zip: $zipCode
                styleIds: [$styleId]
                programTypesFilter: {
                  in: [
                    "EV Rebate"
                    "EV Charging"
                    "Tax Credit"
                    "Utility Rebate"
                    "Municipality Rebate"
                    "Charger Installation"
                    "State Rebate"
                  ]
                }
                types: { notIn: [CUSTOMER_APR, DEALER_CASH] }
                transactionTypes: { in: [CASH] }
                inventoryCodes: [$inventoryCode]
              }
            ) {
              incentives {
                id
                programType
                rebateAmount
                restrictions
                subtype
              }
            }
          }
        `,
        {
          zipCode,
          styleId,
          inventoryCode,
        }
      );
      const style = get(data, 'style', {});
      const incentives = get(data, 'incentives', {});

      return {
        style: {
          ...style,
          warranty: undefined,
          batteryWarranty:
            get(style, 'warranty') && style.warranty.find(({ warrantyType }) => warrantyType === 'EV Battery'),
          hybridComponentsWarranty:
            get(style, 'warranty') && style.warranty.find(({ warrantyType }) => warrantyType === 'Hybrid Component'),
        },
        incentives: incentives.incentives,
      };
    },
  },
]);
