import { useCallback, useMemo } from 'react';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import { IncentivesWizardMap } from 'client/tracking/maps/incentives-wizard';
import { TrackingHandler } from 'client/tracking/handler';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { IncentivesWizardModel, IncentivesWizardPaths } from 'client/data/models/incentives-wizard';
import { getCoreUrl } from 'site-modules/shared/utils/core-link-constructor';
import { generateNewFlatUrl } from 'site-modules/shared/utils/srp-link-constructor';
import { getPotentialRebatesSum } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-potential-rebates-sum';
import {
  questions,
  RESULTS_SCREEN_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { isEmpty } from 'lodash';
import {
  getDynamicBulletsFromIncentives,
  getStartScreenBulletsConfig,
} from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-bullets-config';
import { checkCategoryIncentivesEligible } from 'site-modules/shared/components/incentives/incentives-wizard/utils/check-eligibility';
import { buildEligibleIncentives } from 'site-modules/shared/components/incentives/incentives-wizard/utils/build-eligible-incentives';

export const INVENTORY_TYPE = { true: 'used', false: 'new' };

export function useCoreIncentivesWizard({
  incentives,
  wizardState,
  styleId,
  year,
  makeSlug,
  modelSlug,
  makeName,
  engineTypes,
  isUsed,
  creativeId,
  initialStepIndex,
  setModelValue,
  toggleModalOpen,
  fireOpenTracking,
}) {
  const [setDrawerTimeout] = useTimeout();

  const wizardWidgetCommonConfig = useMemo(
    () =>
      isEmpty(wizardState)
        ? {
            btnCopy: 'Check my eligibility',
            eligibleIncentives: incentives,
            initialStepIndex,
          }
        : {
            btnCopy: 'View Rebates',
            bulletsTitle: 'We checked your eligibility for:',
            getBulletsConfig: params =>
              getStartScreenBulletsConfig({
                ...params,
                eligibleIncentiveIds: wizardState.eligibleIncentiveIds,
                bulletsConfig: getDynamicBulletsFromIncentives(incentives, { make: makeName }),
                evaluateCategoryEligibility: checkCategoryIncentivesEligible,
              }),
            eligibleIncentives: buildEligibleIncentives(incentives, wizardState.eligibleIncentiveIds),
            initialStepIndex: -1,
          },
    [incentives, makeName, wizardState, initialStepIndex]
  );

  const cta = wizardWidgetCommonConfig.btnCopy;
  const openWizard = useCallback(() => {
    TrackingHandler.tryAddMap(IncentivesWizardMap);

    if (fireOpenTracking) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_name: TrackingConstant.ACTION_FILL_FORM,
          subaction_name: TrackingConstant.OPEN_INCENTIVES_MATCHER,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: creativeId,
          value: cta,
        },
      });
    }

    setDrawerTimeout(async () => {
      await setModelValue(IncentivesWizardPaths.getVehicle(), IncentivesWizardModel, {
        styleId: styleId?.toString(),
        inventoryCode: INVENTORY_TYPE[isUsed].toUpperCase(),
        engineTypes,
        year,
        makeSlug,
        modelSlug,
      });
      await setModelValue(IncentivesWizardPaths.getIncentivesWizardData(), IncentivesWizardModel, incentives);
      toggleModalOpen();
    }, 0);
  }, [
    creativeId,
    cta,
    setDrawerTimeout,
    setModelValue,
    styleId,
    isUsed,
    engineTypes,
    year,
    makeSlug,
    modelSlug,
    incentives,
    toggleModalOpen,
    fireOpenTracking,
  ]);

  const wizardQuestions = useMemo(
    () => ({
      ...questions,
      [RESULTS_SCREEN_ID]: {
        ...questions[RESULTS_SCREEN_ID],
        srpLink: isUsed
          ? getCoreUrl({ makeSlug, modelSlug, year })
          : generateNewFlatUrl({ make: makeSlug, model: modelSlug }),
      },
    }),
    [isUsed, makeSlug, modelSlug, year]
  );

  const incentivesAmount =
    wizardWidgetCommonConfig.eligibleIncentives && getPotentialRebatesSum(wizardWidgetCommonConfig.eligibleIncentives);

  return {
    openWizard,
    wizardQuestions,
    wizardWidgetCommonConfig,
    incentivesAmount,
  };
}
