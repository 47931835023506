import React from 'react';
import PropTypes from 'prop-types';
/* Constants */
import {
  PRIVATE_PARTY_LISTING_SETUP,
  PRIVATE_PARTY_SURVEY,
  PRIVATE_PARTY_THANK_YOU,
} from 'site-modules/shared/constants/private-party/private-party';
/* Components */
import { PrivatePartySurvey } from 'site-modules/shared/components/private-party/private-party-modal/components/private-party-survey/private-party-survey';
import { PrivatePartyThankYou } from 'site-modules/shared/components/private-party/private-party-modal/components/private-party-thank-you/private-party-thank-you';
import { PrivatePartyListingSetup } from 'site-modules/shared/components/private-party/private-party-modal/components/private-party-listing-setup/private-party-listing-setup';

export function PrivatePartyModalStep({ step, ...rest }) {
  return (
    <>
      {step === PRIVATE_PARTY_LISTING_SETUP && <PrivatePartyListingSetup {...rest} />}
      {step === PRIVATE_PARTY_SURVEY && <PrivatePartySurvey {...rest} />}
      {step === PRIVATE_PARTY_THANK_YOU && <PrivatePartyThankYou />}
    </>
  );
}

PrivatePartyModalStep.propTypes = {
  step: PropTypes.string,
};

PrivatePartyModalStep.defaultProps = {
  step: '',
};
