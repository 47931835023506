import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ViewMore } from 'site-modules/shared/components/inventory/view-more/view-more';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';

const ScreenHintUI = memo(({ hint, state }) => {
  const text = hint.text;
  const expandedSummary = hint.expandedSummary || 'What’s this?';
  const collapsedSummary = hint.collapsedSummary || 'What’s this?';

  return (
    <div className="screen-hint">
      <ViewMore
        className="justify-content-center"
        expandedSummary={<span className="text-blue-30 text-underline">{expandedSummary}</span>}
        collapsedSummary={<span className="text-blue-30 text-underline">{collapsedSummary}</span>}
        iconClasses="small text-blue-30"
      >
        <ContentFragment componentToUse="div" classes="text-cool-gray-40 medium mt-0_5">
          {text(state)}
        </ContentFragment>
      </ViewMore>
    </div>
  );
});

ScreenHintUI.displayName = 'ScreenHint';

ScreenHintUI.propTypes = {
  hint: PropTypes.shape({
    text: PropTypes.func,
    expandedSummary: PropTypes.string,
    collapsedSummary: PropTypes.string,
  }).isRequired,
  state: PropTypes.string,
};
ScreenHintUI.defaultProps = {
  state: undefined,
};

const stateToPropsConfig = {
  state: bindToPath('location.state', VisitorModel),
};

export const ScreenHint = connectToModel(ScreenHintUI, stateToPropsConfig);
