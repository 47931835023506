import { get } from 'lodash';

export const getIncentiveIdEligibilityFactorIdPairs = eligibleIncentives => {
  const pairsList = eligibleIncentives.reduce((pairs, eligibleIncentive) => {
    const rebateOverridesByEligibility = get(eligibleIncentive, 'rebateOverridesByEligibility', []);
    if (!rebateOverridesByEligibility.length) return pairs;
    const incentiveId = eligibleIncentive.id;
    const eligibilityFactorId = rebateOverridesByEligibility[0].eligibilityFactor.id;
    const pair = `${incentiveId}:${eligibilityFactorId}`;
    return [...pairs, pair];
  }, []);

  return pairsList.join(',');
};
