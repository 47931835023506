import React from 'react';
import PropTypes from 'prop-types';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { OdometerWrapperAsync } from 'site-modules/shared/components/odometer-wrapper/odometer-wrapper-async';

export function WizardPrice(props) {
  const { value, className, odometerWrapperClassName } = props;

  return (
    <>
      <span className="visually-hidden">{formatPriceString(value)}</span>
      <div aria-hidden className={odometerWrapperClassName}>
        <OdometerWrapperAsync value={value} duration={200} classNames={className} />
      </div>
    </>
  );
}

WizardPrice.propTypes = {
  value: PropTypes.number.isRequired,
  className: PropTypes.string,
  odometerWrapperClassName: PropTypes.string,
};

WizardPrice.defaultProps = {
  className: 'fw-bold size-24',
  odometerWrapperClassName: undefined,
};
