import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
/* Models */
import {
  EvRangeMapImagesPaths,
  EvRangeMapImagesModel,
  MAP_NOT_AVAILABLE,
} from 'client/data/models/ev-range-map-images';
/* Utils */
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { SOURCES } from 'client/utils/image-helpers';
/* Components */
import { Image } from 'site-modules/shared/components/image/image';
import { LoadingDots } from 'site-modules/shared/components/loading-dots/loading-dots';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

import './ev-range-map-image.scss';

export function EVRangeMapImageUI({ url, className }) {
  const [isImgLoaded, setIsImgLoaded] = useState(!!url);
  const [isModalOpen, toggleModalOpen] = useToggle(false);
  const prevUrl = useRef({ url });

  useEffect(() => {
    if (url !== prevUrl.current.url || url === MAP_NOT_AVAILABLE) {
      setIsImgLoaded(false);
      prevUrl.current.url = url;
    }
  }, [url]);

  function handleImgLoad() {
    setIsImgLoaded(true);
  }

  const isNAUrl = url === MAP_NOT_AVAILABLE;

  return (
    <Fragment>
      <AspectRatioContainer aspectRatio="65:54" className={className}>
        <div className="ev-range-map-loading-wrapper h-100 pos-r">
          {isNAUrl ? (
            <div className="pos-r">
              <Image
                className="w-100 rounded-content no-map-found"
                imageId="/img/ev-module/no-map-found.jpg"
                source={SOURCES.STATIC}
                alt="No map found"
                lazy
              />
              <div className="pos-a left-0 top-0 w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                <div className="fw-bold">No map found</div>Try changing the zip code
              </div>
            </div>
          ) : (
            <>
              {!!url && (
                <Image
                  className="w-100 rounded-content"
                  imageId={url}
                  source={SOURCES.STATIC}
                  onLoad={handleImgLoad}
                  alt="EV range map"
                  lazy
                />
              )}
              {!isImgLoaded && (
                <div className="h-100 w-100 pos-a top-0 left-0 bg-cool-gray-80 rounded-content d-flex align-items-center justify-content-center">
                  <LoadingDots className="me-0_5" dotClassName="ev-range-dots bg-cool-gray-60" />
                </div>
              )}
              {isImgLoaded && (
                <Button
                  className="img-btn p-0 pos-a left-0 top-0 h-100 w-100"
                  onClick={toggleModalOpen}
                  data-tracking-id="view_content"
                  data-tracking-value="large-image"
                  aria-label="Open detailed EV range map"
                />
              )}
            </>
          )}
        </div>
      </AspectRatioContainer>
      {!isNAUrl && (
        <Modal modalClassName="range-map-modal" isOpen={isModalOpen} toggle={toggleModalOpen}>
          <ModalBody className="p-1 d-flex flex-column">
            <button
              className="p-0 btn btn-link text-end size-20"
              onClick={toggleModalOpen}
              aria-label="Close detailed EV range map"
            >
              <i className="icon-cross3 close-icon" aria-hidden />
            </button>
            {!!url && (
              <Image className="w-100 rounded-content" imageId={url} source={SOURCES.STATIC} alt="EV range map" />
            )}
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  );
}

EVRangeMapImageUI.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};

EVRangeMapImageUI.defaultProps = {
  url: '',
  className: null,
};

export const EVRangeMapImage = connectToModel(EVRangeMapImageUI, {
  url: bindToPath(({ miles }) => EvRangeMapImagesPaths.RANGE_MAP_CURRENT_CITY(miles), EvRangeMapImagesModel),
});

EVRangeMapImage.propTypes = {
  miles: PropTypes.number,
  className: PropTypes.string,
};

EVRangeMapImage.defaultProps = {
  miles: 100,
  className: null,
};
