import React, { Fragment, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import { TrackingHandler } from 'client/tracking/handler';
import { TrackingConstant } from 'client/tracking/constant';
import { ENTER_KEY_CODE } from 'site-modules/shared/constants/key-codes';

import './thumbs-survey.scss';

export const ANSWER = { YES: 'yes', NO: 'no' };
const STEP = { SURVEY: 'survey', TEXT: 'text', THANK_YOU: 'thank-you' };
const LABEL_ID = 'label';
const SURVEY_BUTTONS = [
  { icon: 'icon-thumbs-up2', answer: ANSWER.YES },
  { icon: 'icon-thumbs-down22', answer: ANSWER.NO },
];

export function ThumbsSurvey({
  question,
  answerAriaLabel,
  creativeId,
  trackingMap,
  className,
  questionClassName,
  answerWrapperClassName,
  answerButtonClassName,
  textQuestionClassName,
  textAreaClassName,
  textSubmitClassName,
  textAriaPlaceholder,
  textSubmitCta,
  thankYouClassName,
  textAreaRowCount,
}) {
  const [currentStep, setCurrentStep] = useState(STEP.SURVEY);
  const [isTextEntered, setTextEntered] = useState(false);
  const textRef = useRef(null);
  const submittedAnswer = useRef(null);

  const onThumbClick = useCallback(({ currentTarget: { dataset: { answer } } }) => {
    submittedAnswer.current = answer;
    setCurrentStep(STEP.TEXT);
  }, []);

  const onTextSubmit = useCallback(() => {
    TrackingHandler.fireEvent(trackingMap[submittedAnswer.current], {
      event_data: {
        value: textRef.current.value,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
        creative_id: creativeId,
      },
    });
    setCurrentStep(STEP.THANK_YOU);
  }, [trackingMap, creativeId]);

  const onTextChange = useCallback(({ currentTarget: { value } }) => setTextEntered(!!value), []);

  const onTextKeyDown = useCallback(
    ({ keyCode }) => {
      if (textAreaRowCount === 1 && keyCode === ENTER_KEY_CODE) {
        onTextSubmit();
      }
    },
    [onTextSubmit, textAreaRowCount]
  );

  return (
    <div className={classnames('thumbs-survey', className)} data-tracking-parent={creativeId}>
      <div
        id={LABEL_ID}
        className={classnames({
          [questionClassName]: currentStep === STEP.SURVEY,
          [textQuestionClassName]: currentStep === STEP.TEXT,
          [thankYouClassName]: currentStep === STEP.THANK_YOU,
        })}
        aria-live={currentStep === STEP.SURVEY ? undefined : 'polite'}
      >
        {currentStep === STEP.SURVEY && question}
        {currentStep === STEP.TEXT && 'Please tell us why'}
        {currentStep === STEP.THANK_YOU && 'Thanks for your feedback!'}
      </div>
      <div
        className={classnames({
          [answerWrapperClassName]: currentStep === STEP.SURVEY,
          'd-none': currentStep !== STEP.SURVEY,
        })}
      >
        {SURVEY_BUTTONS.map(({ icon, answer }) => (
          <button
            key={`thumb-survey-${answer}`}
            className={classnames(
              'answer-button text-gray-darker size-20 bg-white border-0 p-0_5',
              answerButtonClassName
            )}
            onClick={onThumbClick}
            data-answer={answer}
            data-tracking-id={trackingMap.submit}
            data-tracking-value={answer}
            aria-label={get(answerAriaLabel, answer)}
            style={{ height: '36px', width: '36px' }}
          >
            <i className={icon} aria-hidden />
            <span className="visually-hidden">{answer}</span>
          </button>
        ))}
      </div>
      <div
        className={classnames({ 'd-flex w-100 pos-r': currentStep === STEP.TEXT, 'd-none': currentStep !== STEP.TEXT })}
      >
        <Input
          innerRef={textRef}
          placeholder={textAriaPlaceholder}
          className={classnames('text-area-input', textAreaClassName)}
          name="thumbs-text"
          type="textarea"
          onChange={onTextChange}
          onKeyDown={onTextKeyDown}
          maxLength={300}
          rows={textAreaRowCount}
          aria-labelledby={LABEL_ID}
        />
        <Button
          onClick={onTextSubmit}
          color="outline-primary-b"
          className={classnames('submit-button', textSubmitClassName)}
          disabled={!isTextEntered}
        >
          {textSubmitCta}
        </Button>
      </div>
    </div>
  );
}

ThumbsSurvey.propTypes = {
  question: PropTypes.string.isRequired,
  creativeId: PropTypes.string.isRequired,
  trackingMap: PropTypes.shape({
    submit: PropTypes.string,
    [ANSWER.YES]: PropTypes.string,
    [ANSWER.NO]: PropTypes.string,
  }).isRequired,
  answerAriaLabel: PropTypes.shape({
    [ANSWER.YES]: PropTypes.string,
    [ANSWER.NO]: PropTypes.string,
  }),
  className: PropTypes.string,
  questionClassName: PropTypes.string,
  answerWrapperClassName: PropTypes.string,
  answerButtonClassName: PropTypes.string,
  textQuestionClassName: PropTypes.string,
  textAreaClassName: PropTypes.string,
  textSubmitClassName: PropTypes.string,
  textAriaPlaceholder: PropTypes.node,
  textSubmitCta: PropTypes.node,
  thankYouClassName: PropTypes.string,
  textAreaRowCount: PropTypes.number,
};

ThumbsSurvey.defaultProps = {
  answerAriaLabel: {
    [ANSWER.YES]: 'Yes, the information was helpful',
    [ANSWER.NO]: "No, the information wasn't helpful",
  },
  className: 'd-flex flex-column align-items-center px-1_5',
  questionClassName: 'medium text-center mb-0_75',
  answerWrapperClassName: 'd-flex justify-content-center',
  answerButtonClassName: 'mx-0_5',
  textQuestionClassName: 'medium text-center mb-1',
  textAreaClassName: 'mb-0_75 px-0_5',
  textSubmitClassName: 'bg-white text-transform-none px-1 py-0_5',
  textAriaPlaceholder: null,
  textSubmitCta: (
    <Fragment>
      Submit<span className="visually-hidden">&nbsp;your answer</span>
    </Fragment>
  ),
  thankYouClassName: 'medium text-center',
  textAreaRowCount: 2,
};
