import { set } from 'lodash';
import {
  ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID,
  ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID,
  HOUSEHOLD_SIZE_QUESTION_ID,
  PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID,
  RESIDE_DISADVANTAGE_COMMUNITY_QUESTION_ID,
  TAX_FILING_STATUS_LOCAL_QUESTION_ID,
  TAX_FILING_STATUS_FEDERAL_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

const QUESTIONS_ELIGIBILITY_FACTOR_FIELD_MAP = {
  [ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID]: 'income',
  [ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID]: 'income',
  [TAX_FILING_STATUS_FEDERAL_QUESTION_ID]: 'taxFilingStatus',
  [TAX_FILING_STATUS_LOCAL_QUESTION_ID]: 'taxFilingStatus',
  [HOUSEHOLD_SIZE_QUESTION_ID]: 'householdSize',
  [PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID]: 'assistanceParticipant',
  [RESIDE_DISADVANTAGE_COMMUNITY_QUESTION_ID]: 'assistanceParticipant',
};

export function createEligibilityFactorFilter(state) {
  const { eligibilityQuestionsQualifiersMap, answers } = state;
  const filter = {};
  Object.entries(eligibilityQuestionsQualifiersMap).forEach(([questionId, qualifiersList]) => {
    qualifiersList.forEach(qualifier => {
      const field = QUESTIONS_ELIGIBILITY_FACTOR_FIELD_MAP[questionId];
      if (Object.hasOwn(answers, questionId)) {
        const answer = answers[questionId][0];
        set(filter, `${qualifier}.${field}`, answer);
      }
    });
  });

  return Object.entries(filter).map(([qualifier, fields]) => ({ qualifier, ...fields }));
}
