import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { IncentivesWizard } from 'site-modules/shared/components/incentives/incentives-wizard/incentives-wizard';
import { getWizardStateForStoring } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-wizard-state-for-storing';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { IncentivesWizardModel, IncentivesWizardPaths } from 'client/data/models/incentives-wizard';
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { Context } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/context';

const PersistedStateIncentivesWizardUI = memo(props => {
  const { onFinish, setModelValue, onStartOver, wizardStateCode, ...rest } = props;

  const handleFinish = useCallback(
    (totalSavings, state) => {
      setModelValue(
        IncentivesWizardPaths.getPersistedWizardState(),
        IncentivesWizardModel,
        getWizardStateForStoring({ ...state, wizardStateCode })
      );

      onFinish(totalSavings, state);
    },
    [onFinish, setModelValue, wizardStateCode]
  );

  const handleStartOver = useCallback(() => {
    setModelValue(IncentivesWizardPaths.getPersistedWizardState(), IncentivesWizardModel, {});
    onStartOver();
  }, [onStartOver, setModelValue]);

  return <IncentivesWizard onFinish={handleFinish} onStartOver={handleStartOver} {...rest} />;
});

PersistedStateIncentivesWizardUI.displayName = 'PersistedStateIncentivesWizardUI';

PersistedStateIncentivesWizardUI.propTypes = {
  setModelValue: PropTypes.func,
  incentives: PropTypes.arrayOf(Incentive),
  onFilterIncentives: PropTypes.func.isRequired,
  flowProcessor: PropTypes.shape({
    processIncentivesUpdate: PropTypes.func.isRequired,
    processAnswer: PropTypes.func.isRequired,
  }).isRequired,
  flowBuilder: PropTypes.shape({
    buildFlow: PropTypes.func.isRequired,
  }).isRequired,
  questions: PropTypes.shape({}).isRequired,
  incentivesWizardStepFactory: PropTypes.shape({
    createStep: PropTypes.func.isRequired,
  }).isRequired,
  onStartConvexAnimation: PropTypes.func.isRequired,
  onScrollToTop: PropTypes.func.isRequired,
  wizardStateCode: PropTypes.string.isRequired,
  context: Context,
  initialStepIndex: PropTypes.number,
  onExit: PropTypes.func,
  onFinish: PropTypes.func,
  creativeId: PropTypes.string,
  initialEligibleIncentivesIds: PropTypes.arrayOf(PropTypes.string),
  savedAnswers: PropTypes.shape({}),
  onStartOver: PropTypes.func,
};

PersistedStateIncentivesWizardUI.defaultProps = {
  setModelValue: noop,
  incentives: undefined,
  context: {},
  initialStepIndex: 0,
  onExit: noop,
  onFinish: noop,
  onStartOver: noop,
  creativeId: undefined,
  initialEligibleIncentivesIds: [],
  savedAnswers: undefined,
};

export const PersistedStateIncentivesWizard = connectToModel(PersistedStateIncentivesWizardUI, {
  wizardStateCode: bindToPath(
    IncentivesWizardPaths.getIncentivesWizardFilters(),
    IncentivesWizardModel,
    ({ incentiveFilters: { stateCode } }) => stateCode
  ),
});
