import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT } from 'site-modules/shared/components/incentives/incentives-wizard/constants/animations';

export function IncentivesWizardTransition({ currentStepIndex, wizardRef, onSetStyle, children }) {
  const containerHeight = useRef(0);

  useEffect(() => {
    const height = wizardRef.current.clientHeight;
    onSetStyle('height', `${height}px`);
    containerHeight.current = height;
  }, [onSetStyle, wizardRef]);

  const handleOnEnter = useCallback(() => {
    onSetStyle('height', `${containerHeight.current}px`);
  }, [onSetStyle]);

  const handleOnEntered = useCallback(() => {
    onSetStyle('height', null);
    onSetStyle('minHeight', `${containerHeight.current}px`);
  }, [onSetStyle]);

  const handleOnExiting = useCallback(() => {
    const height = wizardRef.current.clientHeight;
    containerHeight.current = height;
    onSetStyle('height', `${height}px`);
    onSetStyle('minHeight', null);
  }, [onSetStyle, wizardRef]);

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={currentStepIndex}
        timeout={ANIMATION_TIMEOUT}
        classNames="fade"
        onEnter={handleOnEnter}
        onEntered={handleOnEntered}
        onExiting={handleOnExiting}
        mountOnEnter
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}

IncentivesWizardTransition.propTypes = {
  currentStepIndex: PropTypes.number,
  wizardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  children: PropTypes.node.isRequired,
  onSetStyle: PropTypes.func.isRequired,
};
IncentivesWizardTransition.defaultProps = {
  currentStepIndex: 0,
  wizardRef: null,
};
