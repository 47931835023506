import { curry, flow as flowFn } from 'lodash';
import {
  CATEGORIES,
  CATEGORIES_SEQUENCE,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import {
  GOLD_CATEGORY_QUESTIONS_MAP,
  RESULT_FEDERAL_REBATES_SCREEN_ID,
  RESULT_LOCAL_REBATES_SCREEN_ID,
  RESULTS_SCREEN_ID,
  START_SCREENS,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

const categoryInstantIneligibleScreens = {
  [CATEGORIES.FEDERAL]: RESULT_FEDERAL_REBATES_SCREEN_ID,
  [CATEGORIES.LOCAL]: RESULT_LOCAL_REBATES_SCREEN_ID,
};

function getCategorySequence(activeCategory) {
  return [activeCategory, ...CATEGORIES_SEQUENCE.filter(category => category !== activeCategory)].filter(
    category => !!category
  );
}

function distributeFlowByCategories(programs) {
  return Object.entries(programs).reduce(
    (result, [, { flow, category }]) => ({ ...result, [category]: [...result[category], ...flow] }),
    { [CATEGORIES.FEDERAL]: [], [CATEGORIES.LOCAL]: [] }
  );
}

function filterOutRedundantQuestions(categoryFlowMap) {
  return Object.entries(categoryFlowMap).reduce(
    (result, [category, flow]) => ({
      ...result,
      [category]: Array.from(new Set(flow)).filter(flowStep =>
        GOLD_CATEGORY_QUESTIONS_MAP[category].includes(flowStep)
      ),
    }),
    {}
  );
}

const sortFlow = (flow, goldFlow) => {
  const sortedFlow = [...flow];
  return sortedFlow.sort((a, b) => goldFlow.indexOf(a) - goldFlow.indexOf(b));
};

function sortFlowInsideCategory(categoryFlowMap) {
  const sortedCategoriesFlowMap = categoryFlowMap;
  Object.entries(categoryFlowMap).forEach(([category, flow]) => {
    sortedCategoriesFlowMap[category] = sortFlow(flow, GOLD_CATEGORY_QUESTIONS_MAP[category]);
  });

  return sortedCategoriesFlowMap;
}

function sortFlowByCategory(activeCategory, categoryFlowMap) {
  const desiredCategoriesSequence = getCategorySequence(activeCategory);
  return desiredCategoriesSequence.flatMap(category => categoryFlowMap[category]);
}

function insertMissingEligibilityScreen(activeCategory, flow) {
  return !flow.includes(categoryInstantIneligibleScreens[activeCategory]) && activeCategory
    ? [categoryInstantIneligibleScreens[activeCategory], ...flow]
    : flow;
}

function createFlow(programs, { flowSteps, currentStepIndex, activeCategory }) {
  const newFlow = flowFn([
    distributeFlowByCategories,
    filterOutRedundantQuestions,
    sortFlowInsideCategory,
    curry(sortFlowByCategory)(activeCategory),
    curry(insertMissingEligibilityScreen)(activeCategory),
  ])(programs);

  const passedSteps = flowSteps.slice(0, currentStepIndex + 1);

  return Array.from(new Set([...START_SCREENS, ...passedSteps, ...newFlow, RESULTS_SCREEN_ID]));
}

export const flowStepCreator = {
  createFlow,
};
