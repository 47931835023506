import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Card from 'reactstrap/lib/Card';

import {
  PricingCreativeConfigDefaultProps,
  PricingCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { PRICING_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import {
  addZipCode,
  definePlatform,
  getTrimNamesAndMoreCount,
} from 'site-modules/shared/components/native-ad/utils/utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';
import { Disclaimer } from 'site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';

import { getNonMSRPDefaultLabel, isNonMSRPMake, isOemUrlWhitelisted } from './data-helper';
import './pricing-site-served-ad.scss';

export function PricingSiteServedAdUI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  vehicle,
  mobile,
  position,
  cardClassName,
  wrapperClass,
  ivtClassName,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    creativeConfigData: {
      photoDisclaimer,
      photo,
      logo,
      headline,
      msrp: msrpConfig,
      disclaimerCopy,
      lineItemId,
      siteServedCreativeId,
      linkDisplayUrl,
      cta,
      isConquest,
      target,
      trimNames,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      adLoadTrackingListener(event, cta);
    },
    [adLoadTrackingListener, cta]
  );

  const msrp = msrpConfig?.trim();
  const make = get(vehicle, 'make.niceName', '');

  const isNonMsrpOem = isNonMSRPMake(make);
  const isWhiteListedUrl = isOemUrlWhitelisted(linkDisplayUrl);

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';

  /*
   * Image Component
   */
  const imageComponent = (
    <Fragment>
      {isConquest ? (
        <ConquestRibbon classes="mt-0_5" style={{ position: 'absolute', height: '20px' }} />
      ) : (
        <img className="logo" style={{ position: 'absolute', maxHeight: '24px' }} alt="" src={logo} loading="lazy" />
      )}
      <div className="img-wrapper mt-1">
        <img
          className={classnames('core-img img-fluid d-block mx-auto', {
            'has-img-disclaimer': !!photoDisclaimer,
            background: !photo,
          })}
          style={{ maxWidth: '100%' }}
          src={photo}
          alt=""
          loading="lazy"
        />
        {photoDisclaimer && (
          <div className="px-0_5 text-gray-darker size-10 d-flex justify-content-end align-items-end text-end disclaimer img-disclaimer">
            {photoDisclaimer}
          </div>
        )}
      </div>
    </Fragment>
  );

  /*
   * Headline component.
   */
  const headlineComponent = headline && (
    <ContentFragment classes="heading-4 mx-0_5 my-0_25">{headline}</ContentFragment>
  );

  const defaultLabel = (
    <div
      className={classnames('label-container mx-0_5 field mt-auto', {
        'mb-1_75': mobile,
      })}
    >
      {msrp && (
        <div>
          <span className="small text-gray-darker d-block">Starting MSRP</span>
          <ContentFragment classes="heading-3" componentToUse="span">
            {msrp}
          </ContentFragment>
        </div>
      )}
    </div>
  );

  const noMSRPLabel = (
    <div className={classnames('label-container mx-0_5 field', { 'mb-1_75': mobile })}>
      <div className="display-3">{msrp || getNonMSRPDefaultLabel(make)}</div>
    </div>
  );

  const labelComponent = (isNonMsrpOem && noMSRPLabel) || defaultLabel;

  // /*
  //  * CTA component.
  //  */
  const ctaBtn = (
    <div className="mt-auto mb-1">
      <div
        className={classnames('mx-0_5 px-1 cta btn btn-sm btn-blue-50 text-transform-none size-16', {
          'd-block': mobile,
        })}
      >
        <CtaButtonText text={cta} />
      </div>
      <div className="oem-url text-blue-50 fw-medium mt-0_25 ms-0_5">{linkDisplayUrl}</div>
    </div>
  );

  const disclaimer = disclaimerCopy && <Disclaimer disclaimerText={disclaimerCopy} />;

  const nativeStyle = PRICING_AD.NATIVE_STYLE;

  return (
    <div
      className={classnames('pricing-site-served-ad-unit w-100', wrapperClass, ivtClassName, {
        'is-whitelisted-oem-url': isWhiteListedUrl,
        'heavy-card': mobile,
      })}
      style={{ overflow: 'hidden' }}
    >
      <Card
        className={classnames('pricing-site-served-card text-start', cardClassName, { mobile })}
        data-tracking-parent={nativeStyle}
        data-viewability={`${nativeStyle}-${position}`}
        innerRef={adRef}
      >
        <DfpTracking
          lineItemId={lineItemId}
          siteServedCreativeId={siteServedCreativeId}
          mobile={mobile}
          nativeStyle={nativeStyle}
          position={position}
          slotRenderEndListener={loadTrackingListener}
          siteServedAdsUpdater={siteServedAdsUpdater}
        />
        {adTrackers}
        <a
          href={ctaURL}
          target={ctaTarget}
          rel="noopener noreferrer"
          onClick={adClickTrackingListener}
          className="site-served-ad-link no-focus h-100 text-decoration-none d-flex flex-column"
        >
          <AdLabel />
          <Fragment>
            {imageComponent}
            {headlineComponent}
            <div className="field mx-0_5 text-gray-darker mb-0_75">
              {!!get(trimNames, 'length') && (
                <ContentFragment classes="medium">{getTrimNamesAndMoreCount(trimNames)}</ContentFragment>
              )}
            </div>
            {labelComponent}
            {ctaBtn}
          </Fragment>
        </a>
        {disclaimer}
      </Card>
    </div>
  );
}

PricingSiteServedAdUI.propTypes = {
  ...SiteServedAdPropTypes,
  creativeConfig: PricingCreativeConfigPropTypes,
  cardClassName: PropTypes.string,
  wrapperClass: PropTypes.string,
};

PricingSiteServedAdUI.defaultProps = {
  ...SiteServedAdDefaultProps,
  creativeConfig: PricingCreativeConfigDefaultProps,
  cardClassName: null,
  wrapperClass: null,
};

export const PricingSiteServedAd = siteServedAdTrackingWrapper(PricingSiteServedAdUI);
