import {
  ADD_ANSWER_ACTION,
  ELIGIBILITY_QUESTIONS_QUALIFIER_MAP_ACTION,
  REMEMBER_NEW_FLOW_INCENTIVES,
  REMOVE_ANSWER_ACTION,
  REMOVE_ELIGIBLE_INCENTIVES_IDS_ACTION,
  SET_CURRENT_STEP_ACTION,
  SET_FLOW_STEPS_ACTION,
  SET_LOADING_ACTION,
  START_OVER,
  UPDATE_ACTIVE_CATEGORY_ACTION,
  UPDATE_ELIGIBLE_INCENTIVES_IDS_ACTION,
  UPDATE_INCENTIVES_ACTION,
  UPDATE_META_DATA_ACTION,
} from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/actions';
import { omit, unionBy } from 'lodash';

export function wizardStateManagerReducer(state, action) {
  switch (action.type) {
    case UPDATE_INCENTIVES_ACTION:
      return {
        ...state,
        incentives: unionBy(action.incentives, state.incentives, 'id'),
      };
    case SET_FLOW_STEPS_ACTION:
      return {
        ...state,
        flowSteps: action.flowSteps,
      };
    case SET_CURRENT_STEP_ACTION:
      return {
        ...state,
        currentStepIndex: action.nextStepIndex,
      };
    case SET_LOADING_ACTION:
      return {
        ...state,
        isLoading: action.loading,
      };
    case ADD_ANSWER_ACTION:
      return {
        ...state,
        answers: { ...state.answers, [action.id]: action.answer },
      };
    case REMOVE_ANSWER_ACTION:
      return {
        ...state,
        answers: omit(state.answers, [action.id]),
      };
    case UPDATE_META_DATA_ACTION:
      return {
        ...state,
        stepMeta: { ...state.stepMeta, ...action.stepMeta },
      };
    case UPDATE_ACTIVE_CATEGORY_ACTION:
      return {
        ...state,
        activeCategory: action.activeCategory,
      };
    case UPDATE_ELIGIBLE_INCENTIVES_IDS_ACTION:
      return {
        ...state,
        eligibleIncentiveIds: Array.from(new Set([...state.eligibleIncentiveIds, ...action.ids])),
      };
    case REMOVE_ELIGIBLE_INCENTIVES_IDS_ACTION:
      return {
        ...state,
        eligibleIncentiveIds: state.eligibleIncentiveIds.filter(id => !action.ids.includes(id)),
      };
    case ELIGIBILITY_QUESTIONS_QUALIFIER_MAP_ACTION:
      return {
        ...state,
        eligibilityQuestionsQualifiersMap: action.eligibilityQuestionsQualifiersMap,
      };
    case REMEMBER_NEW_FLOW_INCENTIVES:
      return {
        ...state,
        flowIncentives: action.incentives,
      };
    case START_OVER:
      return {
        ...state,
        ...action.initialState,
        currentStepIndex: action.initialState.currentStepIndex === -1 ? 0 : action.initialState.currentStepIndex,
      };
    default:
      return state;
  }
}
