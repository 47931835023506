import React, { useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { randomInt } from 'client/utils/seed-randomizers';
import { noop } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  IncentivesWizardEntities,
  IncentivesWizardModel,
  IncentivesWizardPaths,
} from 'client/data/models/incentives-wizard';
import { flowProcessor } from 'site-modules/shared/components/incentives/incentives-wizard/processors/flow-processor';
import { flowBuilder } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/flow-builder';
import { incentivesWizardStepFactory } from 'site-modules/shared/components/incentives/incentives-wizard/factories/incentives-wizard-step-factory';
import { questions as defaultQuestions } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { Context } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/context';

import { IncentivesWizard } from 'site-modules/shared/components/incentives/incentives-wizard/incentives-wizard';

const WizardContentUI = memo(
  ({
    questions,
    onRebatesSaveSum,
    onExit,
    incentives,
    setModelValue,
    context,
    initialStepIndex,
    initialEligibleIncentivesIds,
    creativeId,
    onStartConvexAnimation,
    onScrollToTop,
    wizardComponent: Wizard,
    savedAnswers,
    onStartOver,
  }) => {
    useEffect(
      () => () => {
        setModelValue(IncentivesWizardPaths.getIncentivesWizardFilters(), IncentivesWizardModel, undefined);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const updateFilters = useCallback(
      filters => {
        setModelValue(IncentivesWizardPaths.getIncentivesWizardFilters(), IncentivesWizardModel, {
          ...filters,
          // Sometimes we could get same eligibility factors and filters while checking eligibility for category of questions (federal or local).
          // It could happen because of dynamic nature of questions, because they  depend from eligibility factors returned by API.
          // But we still want to trigger update of incentives in the store in order to let our wizard state manager handle update and run appropriate processor
          random: randomInt(),
        });
      },
      [setModelValue]
    );

    return (
      <Wizard
        incentives={incentives}
        onFilterIncentives={updateFilters}
        flowProcessor={flowProcessor}
        flowBuilder={flowBuilder}
        questions={questions}
        incentivesWizardStepFactory={incentivesWizardStepFactory}
        context={context}
        initialStepIndex={initialStepIndex}
        initialEligibleIncentivesIds={initialEligibleIncentivesIds}
        onExit={onExit}
        onFinish={onRebatesSaveSum}
        creativeId={creativeId}
        onStartConvexAnimation={onStartConvexAnimation}
        onScrollToTop={onScrollToTop}
        savedAnswers={savedAnswers}
        onStartOver={onStartOver}
      />
    );
  }
);

WizardContentUI.propTypes = {
  onRebatesSaveSum: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  setModelValue: PropTypes.func.isRequired,
  incentives: IncentivesWizardEntities.IncentivesData,
  context: Context.isRequired,
  initialStepIndex: PropTypes.number,
  initialEligibleIncentivesIds: PropTypes.arrayOf(PropTypes.string),
  creativeId: PropTypes.string,
  onStartConvexAnimation: PropTypes.func.isRequired,
  onScrollToTop: PropTypes.func.isRequired,
  questions: PropTypes.shape({}),
  wizardComponent: PropTypes.elementType,
  savedAnswers: PropTypes.shape({}),
  onStartOver: PropTypes.func,
};

WizardContentUI.defaultProps = {
  initialStepIndex: undefined,
  initialEligibleIncentivesIds: undefined,
  incentives: undefined,
  creativeId: undefined,
  questions: defaultQuestions,
  wizardComponent: IncentivesWizard,
  savedAnswers: undefined,
  onStartOver: noop,
};

WizardContentUI.displayName = 'WizardContentUI';

export const stateToPropsConfig = {
  incentives: bindToPath(IncentivesWizardPaths.getIncentivesWizardData(), IncentivesWizardModel, null, false),
};

export const WizardContent = connectToModel(WizardContentUI, stateToPropsConfig);
