import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { SeeAllReviewsLink, WriteReview } from 'site-modules/shared/components/reviews-links/reviews-links';
import { SummaryLinks } from 'site-modules/shared/components/core-page/summary-links/summary-links';

import './review-summary-links.scss';

export function ReviewSummaryLinks({
  className,
  vehicle,
  styleId,
  reviewsCount,
  showWriteReviewLink,
  showAllReviewsLink,
  showAllForSaleLink,
  showPhotoFlipperButton,
  showPhotoFlipperButtonClassName,
  writeReviewTrackingId,
  allForSaleTrackingId,
  target,
}) {
  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    submodels: { slug: submodelSlug },
    year,
  } = vehicle;
  const urlParams = { makeSlug, modelSlug, submodelSlug, year, styleId };

  return (
    <div className={classnames('review-summary-links mt-0_75 d-flex flex-column align-items-start', className)}>
      {showWriteReviewLink && (
        <WriteReview
          params={urlParams}
          mmsy={{ make: makeName, model: modelName, year }}
          data-tracking-id={writeReviewTrackingId}
          target={target}
        />
      )}
      {showAllReviewsLink && (
        <SeeAllReviewsLink params={urlParams} reviewsCount={reviewsCount} rel="nofollow" target={target} />
      )}
      <SummaryLinks
        vehicle={vehicle}
        showAllForSaleLink={showAllForSaleLink}
        showPhotoFlipperButton={showPhotoFlipperButton}
        showPhotoFlipperButtonClassName={showPhotoFlipperButtonClassName}
        allForSaleTrackingId={allForSaleTrackingId}
      />
    </div>
  );
}
ReviewSummaryLinks.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  styleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviewsCount: PropTypes.number,
  className: PropTypes.string,
  showWriteReviewLink: PropTypes.bool,
  showAllReviewsLink: PropTypes.bool,
  showAllForSaleLink: PropTypes.bool,
  showPhotoFlipperButton: PropTypes.bool,
  showPhotoFlipperButtonClassName: PropTypes.string,
  writeReviewTrackingId: PropTypes.string,
  allForSaleTrackingId: PropTypes.string,
  target: PropTypes.string,
};
ReviewSummaryLinks.defaultProps = {
  styleId: null,
  reviewsCount: 0,
  className: null,
  showWriteReviewLink: true,
  showAllReviewsLink: false,
  showAllForSaleLink: false,
  showPhotoFlipperButton: false,
  showPhotoFlipperButtonClassName: 'd-lg-none',
  writeReviewTrackingId: 'write_review',
  allForSaleTrackingId: 'view_search_result',
  target: '_self',
};
