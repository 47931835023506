import React from 'react';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';
import { ResultScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/result-screen';
import {
  START,
  INFORMATIVE,
  ELIGIBILITY,
  RESULT,
  CHECKING_ELIGIBILITY,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-types';
import { BaseScreenStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-screen-step';
import { EligibilityScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/eligibility-screen';
import { StartScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/start-screen';
import { CheckingEligibilityScreen } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/checking-eligibility-screen';

/* eslint-disable react/prop-types */
function createScreen({
  stepConfig,
  stepMeta,
  answers,
  eligibleIncentiveIds,
  incentives,
  allIncentives,
  onAnswer,
  context,
  onExit,
  onFinish,
  flowSteps,
  creativeId,
  onStartConvexAnimation,
  flowSkipped,
  onStartOver,
}) {
  let StepComponent;

  switch (stepConfig.type) {
    case START:
      StepComponent = StartScreen;
      break;
    case INFORMATIVE:
      StepComponent = BaseScreenStep;
      break;
    case ELIGIBILITY:
      StepComponent = EligibilityScreen;
      break;
    case RESULT:
      StepComponent = ResultScreen;
      break;
    case CHECKING_ELIGIBILITY:
      StepComponent = CheckingEligibilityScreen;
      break;
    default:
      StepComponent = BaseWizardStep;
  }

  return (
    <StepComponent
      stepConfig={stepConfig}
      stepMeta={stepMeta}
      answers={answers}
      eligibleIncentiveIds={eligibleIncentiveIds}
      incentives={incentives}
      allIncentives={allIncentives}
      onAnswer={onAnswer}
      onFinish={onFinish}
      context={context}
      onExit={onExit}
      flowSteps={flowSteps}
      creativeId={creativeId}
      key={stepConfig.id}
      onStartConvexAnimation={onStartConvexAnimation}
      flowSkipped={flowSkipped}
      onStartOver={onStartOver}
    />
  );
}

export const incentivesWizardScreenFactory = {
  createScreen,
};
