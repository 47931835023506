import React, { memo, useCallback } from 'react';
import { get } from 'lodash';
import { Button } from 'reactstrap';
import { BINARY_ANSWERS } from 'site-modules/shared/components/incentives/incentives-wizard/constants/binary-answers';
import { StepProps } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { BaseQuestionStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-question-step';
import { useQuestionTracking } from 'site-modules/shared/components/incentives/incentives-wizard/hooks/use-question-tracking';

import './binary-wizard-question.scss';

export const SURVEY_RESPONSE = {
  yes: 'Yes',
  no: 'No',
};

export const BinaryWizardQuestion = memo(
  ({
    stepConfig,
    stepMeta,
    answers,
    eligibleIncentiveIds,
    incentives,
    allIncentives,
    onAnswer,
    onBack,
    context,
    flowSteps,
    creativeId,
  }) => {
    const [handleAnswerTracking] = useQuestionTracking({ stepConfig, creativeId, stepMeta });

    const handleClick = useCallback(
      response => () => {
        onAnswer([BINARY_ANSWERS[response]]);
        handleAnswerTracking(response);
      },
      [onAnswer, handleAnswerTracking]
    );

    const Component = get(stepConfig, 'customConfig.component', null);

    return (
      <BaseQuestionStep
        stepConfig={stepConfig}
        stepMeta={stepMeta}
        answers={answers}
        eligibleIncentiveIds={eligibleIncentiveIds}
        incentives={incentives}
        allIncentives={allIncentives}
        onAnswer={onAnswer}
        onBack={onBack}
        context={context}
        flowSteps={flowSteps}
      >
        <div>
          {Component && <Component incentives={allIncentives} />}
          <div className="binary-wizard-question d-flex justify-content-center mb-2">
            <Button
              onClick={handleClick(SURVEY_RESPONSE.yes)}
              className="question-btn d-flex justify-content-center align-items-center size-20 fw-medium text-transform-none text-cool-gray-30 me-1_5 bg-cool-gray-100 rounded-12"
            >
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <span className="emoji" aria-hidden>
                👍
              </span>
              <span className="ms-0_5">{SURVEY_RESPONSE.yes}</span>
            </Button>
            <Button
              onClick={handleClick(SURVEY_RESPONSE.no)}
              className="question-btn d-flex justify-content-center align-items-center size-20 fw-medium text-transform-none text-cool-gray-30 bg-cool-gray-100 rounded-12"
            >
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <span className="emoji" aria-hidden>
                👎
              </span>
              <span className="ms-0_5">{SURVEY_RESPONSE.no}</span>
            </Button>
          </div>
        </div>
      </BaseQuestionStep>
    );
  }
);

BinaryWizardQuestion.displayName = 'BinaryWizardQuestion';

BinaryWizardQuestion.propTypes = StepProps;
