import { mapDisadvantagedCommunity } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/disadvantaged-community-mapper';
import { mapAssistance } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/assistance-mapper';
import { mapBase } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/base-mapper';
import {
  ASSISTANCE,
  DISADVANTAGED_COMMUNITY,
  FILING_CURRENT_YEAR,
  FILING_PREVIOUS_YEAR,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/qualifiers';
import { mapFilingPreviousYear } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/filing-previous-year-mapper';
import { mapFilingCurrentYear } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/filing-current-year-mapper';

export const getQualifierMapper = qualifier => {
  switch (qualifier.toLowerCase()) {
    case DISADVANTAGED_COMMUNITY:
      return mapDisadvantagedCommunity;
    case ASSISTANCE:
      return mapAssistance;
    case FILING_PREVIOUS_YEAR:
      return mapFilingPreviousYear;
    case FILING_CURRENT_YEAR:
      return mapFilingCurrentYear;
    default:
      return mapBase;
  }
};
