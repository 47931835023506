import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'reactstrap';
// Utils
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { stripInvalidHTML } from 'site-modules/shared/utils/strip-html';
import { wizardTrackingIds } from 'client/tracking/maps/incentives-wizard';
import { getRebateAmount } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-rebate-amount-from-incentives';
// Components
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { IncentivesCardListOptions } from 'site-modules/shared/components/incentives/card-list-options/incentives-card-list-options';
import { IncentiveBadge } from 'site-modules/shared/components/incentives/incentive-badge/incentive-badge';
import { ViewMore } from 'site-modules/shared/components/inventory/view-more/view-more';
import { Incentive } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/incentive';
import { Link } from 'site-modules/shared/components/link/link';
import { Button } from 'site-modules/shared/components/inventory/button/button';

import './eligibility-card.scss';

export const EligibilityCard = memo(({ incentive, isIneligible }) => {
  const { programType, subtype, restrictions, grantor, endDate, programUrl } = incentive;
  const rebateAmount = getRebateAmount(incentive);

  return (
    <Card
      className={classnames('eligibility-card px-1_5 pt-1_5 pb-0_5 overflow-hidden border-0 rounded-16 mx-auto w-100', {
        ineligible: isIneligible,
      })}
      tag="li"
    >
      <Row className="m-0">
        <Col className="ps-0">
          <IncentiveBadge programType={programType} badgeContent={programType} rootClassName="mb-1" />
          <div className="mb-1">
            <h3
              className={classnames('size-16 mb-0', {
                'text-cool-gray-10': !isIneligible,
                'text-cool-gray-40': isIneligible,
              })}
              title={subtype}
            >
              {subtype}
            </h3>
          </div>
        </Col>
        <Col xs="auto">
          {!!rebateAmount && (
            <div
              className={classnames('size-24 fw-bold', {
                'text-cool-gray-30': !isIneligible,
                'text-cool-gray-50 strikethrough': isIneligible,
              })}
            >
              {getPriceString(rebateAmount)}
            </div>
          )}
        </Col>
      </Row>
      {programUrl && (
        <div className="mb-1">
          <Button
            tag={Link}
            color="outline"
            size="lg"
            to={programUrl.trim()}
            target="_blank"
            className="program-url-link text-transform-none fw-medium w-100 p-0 d-flex justify-content-center align-items-center"
            data-tracking-id={wizardTrackingIds.incentivesOffer}
            data-tracking-value={grantor}
          >
            View Offer
            <span className="ms-0_25 icon-open_in_new small" aria-hidden />
          </Button>
        </div>
      )}
      <ViewMore
        className="mb-1"
        expandedSummary={<span className="text-cool-gray-30 text-underline">Details & next steps</span>}
        collapsedSummary={<span className="text-cool-gray-30 text-underline">Details & next steps</span>}
        trackingId="display_incentive_program_details"
        trackingValue={subtype}
        iconClasses="small text-cool-gray-30"
      >
        <IncentivesCardListOptions
          listItems={[
            { label: 'Program Provider', value: grantor || '' },
            { label: 'Expiration Date', value: endDate },
          ]}
        />
        <ContentFragment componentToUse="div" classes="text-cool-gray-40 mb-1">
          {stripInvalidHTML(restrictions)}
        </ContentFragment>
      </ViewMore>
    </Card>
  );
});

EligibilityCard.displayName = 'EligibilityCard';

EligibilityCard.propTypes = {
  incentive: Incentive.isRequired,
  isIneligible: PropTypes.bool,
};
EligibilityCard.defaultProps = {
  incentive: Incentive.isRequired,
  isIneligible: false,
};
