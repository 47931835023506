import React from 'react';
import PropTypes from 'prop-types';
import './incentives-card-list-options.scss';

export function IncentivesCardListOptions({ listItems }) {
  return (
    <>
      <hr className="text-cool-gray-80 my-0" />
      <dl className="card-list-options medium">
        {listItems
          .filter(({ value }) => !!value)
          .map(({ label, value }) => (
            <div className="d-flex justify-content-between py-0_5" key={`${label}`}>
              <dt className="fw-normal text-cool-gray-40">{label}</dt>
              <dd className="fw-bold text-cool-gray-30 mb-0 text-end">{value}</dd>
            </div>
          ))}
      </dl>
    </>
  );
}

IncentivesCardListOptions.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]) })
  ).isRequired,
};
