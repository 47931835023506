import { omit } from 'lodash';
import {
  addAnswer,
  setCurrentStepIndex,
  setLoading,
  updateActiveCategory,
  addUniqIncentivesIds,
  removeIncentivesIds,
  updateIncentives,
  updateMeta,
  rememberNewFlowIncentives,
  removeAnswer,
} from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/actions';
import {
  CHECK_FEDERAL_REBATES_SCREEN_ID,
  CHECK_LOCAL_REBATES_SCREEN_ID,
  INSTALL_EV_CHARGER_QUESTION_ID,
  LOWER_INCOME_YEAR_QUESTION_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  ZIP_CODE_QUESTION_ID,
  questions,
  CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID,
  CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID,
  TAX_FILING_STATUS_FEDERAL_QUESTION_ID,
  ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID,
  ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID,
  TAX_FILING_STATUS_LOCAL_QUESTION_ID,
  ENGINE_TYPE_QUESTION_ID,
  CUSTOMER_OF_QUESTION_ID,
  DEFAULT_CUSTOMER_OF_ANSWER,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { ELIGIBILITY } from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-types';
import { QUESTION } from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-variants';
import { flowBuilder } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/flow-builder';
import {
  isAnswerPresent,
  isSameAnswer,
} from 'site-modules/shared/components/incentives/incentives-wizard/utils/answer-status';
import { getIncentivesIdsFilteredBy } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-incentives-ids-filtered-by';
import { isChargerInstallationRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-charger-installation-rebate';
import { createEligibilityFactorFilter } from 'site-modules/shared/components/incentives/incentives-wizard/utils/create-eligibility-factor-filter';
import { checkIncentiveBelongsToCategory } from 'site-modules/shared/components/incentives/incentives-wizard/utils/check-incentive-belongs-to-category';
import { getIncentivesWithMaxRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-incentives-with-max-rebate';
import { getCurrentYear } from 'site-modules/shared/utils/time-util';
import { getTaxFilingStatuses } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-tax-filing-statuses';
import { getUtilityEvChargingOfferRebates } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-utility-ev-charging-offer-rebates';
import { filterOutIncentivesWithClientSideEligibilityCheck } from 'site-modules/shared/components/incentives/incentives-wizard/utils/filter-out-incentives-with-client-side-eligibility-check';

function basicStepProcessor({ state, dispatch }) {
  if (state.flowSteps.at(state.currentStepIndex + 1)) {
    dispatch(setCurrentStepIndex(state.currentStepIndex + 1));
  }
}

function zipCodeTypeQuestionProcessor({ state, dispatch, answer }) {
  dispatch(updateMeta({ zip: answer }));
  basicStepProcessor({ state, dispatch });
}

function transactionTypeQuestionProcessor({ state, dispatch, onFilterIncentives, answer }) {
  const { flowSteps, currentStepIndex, answers, stepMeta } = state;
  const {
    zip: [zip, { isChanged }],
    engineTypes,
  } = stepMeta;
  const sameAnswer = isSameAnswer(flowSteps.at(currentStepIndex), answers, answer);
  if (sameAnswer && !isChanged) {
    basicStepProcessor({ state, dispatch });
    return;
  }
  dispatch(setLoading(true));
  onFilterIncentives({
    incentiveFilters: { transactionType: answer[0].toUpperCase(), zip, ...(engineTypes ? { engineTypes } : {}) },
  });
}

const updateAnswerOnSameLocalQuestion = (processor, localQuestionId) => ({ state, dispatch, answer, incentives }) => {
  const lowerIncomeYearAnswer = state.answers[LOWER_INCOME_YEAR_QUESTION_ID];

  if (!lowerIncomeYearAnswer) {
    return processor({ state, dispatch, answer, incentives });
  }

  if (Number(lowerIncomeYearAnswer) === getCurrentYear() - 1) {
    dispatch(addAnswer(localQuestionId, answer));
    return processor({
      state: { ...state, answers: { ...state.answers, [localQuestionId]: answer } },
      dispatch,
      answer,
      incentives,
    });
  }

  if (state.answers[localQuestionId]) {
    dispatch(removeAnswer(localQuestionId));
    return processor({
      state: { ...state, answers: omit(state.answers, [localQuestionId]) },
      dispatch,
      answer,
      incentives,
    });
  }

  return processor({ state, dispatch, answer, incentives });
};

function lowerIncomeTypeQuestionProcessor({ state, dispatch, answer, context }) {
  const { currentStepIndex, flowSteps, flowIncentives } = state;
  flowBuilder.rebuildFlow(
    { ...state, answers: { ...state.answers, [flowSteps.at(currentStepIndex)]: answer } },
    dispatch,
    flowIncentives,
    context
  );
  dispatch(updateMeta({ lowerIncomeYear: answer[0] }));
  basicStepProcessor({ state, dispatch });
}

function taxFillingStatusTypeQuestionProcessor({ state, dispatch }) {
  const { activeCategory, flowIncentives } = state;
  const taxFilingStatuses = getTaxFilingStatuses(flowIncentives, activeCategory);
  dispatch(updateMeta({ taxFilingStatuses }));
  basicStepProcessor({ state, dispatch });
}

function installEvChargerTypeQuestionProcessor({ state, dispatch, answer, context }) {
  const { currentStepIndex, flowSteps, flowIncentives } = state;

  flowBuilder.rebuildFlow(
    { ...state, answers: { ...state.answers, [flowSteps.at(currentStepIndex)]: answer } },
    dispatch,
    flowIncentives,
    context
  );

  const chargerInstallationIds = getIncentivesIdsFilteredBy(flowIncentives, [isChargerInstallationRebate]);

  const hasUtilityEvChargingOfferRebates = !!getUtilityEvChargingOfferRebates(flowIncentives).length;
  // If programType=Charger Installation and subtypeId is NOT 575
  // Then do not ask CUSTOMER_OF_QUESTION_ID question
  // And mark the incentive as eligible if user answered "YES"
  if (!!answer[0] && !hasUtilityEvChargingOfferRebates) {
    dispatch(addUniqIncentivesIds(chargerInstallationIds));
  } else {
    dispatch(removeIncentivesIds(chargerInstallationIds));
  }

  basicStepProcessor({ state, dispatch });
}

function customerOffTypeQuestionProcessor({ state, dispatch, answer }) {
  const { flowSteps, currentStepIndex, answers } = state;
  const questionId = flowSteps.at(currentStepIndex);
  if (isAnswerPresent(questionId, answers)) {
    dispatch(removeIncentivesIds(answers[questionId][0]));
  }
  const answerId = answer[0];
  if (answerId !== DEFAULT_CUSTOMER_OF_ANSWER) {
    dispatch(addUniqIncentivesIds([answerId]));
  }

  basicStepProcessor({ state, dispatch });
}

function checkingEligibilityTypeScreenProcessor({ state, dispatch, onFilterIncentives }) {
  dispatch(setLoading(true));
  const eligibilityFactors = createEligibilityFactorFilter(state);
  onFilterIncentives({ eligibilityFactors });
}

function checkRebatesTypeScreenProcessor({ state, dispatch, answer }) {
  dispatch(updateActiveCategory(answer[0]));
  basicStepProcessor({ state, dispatch });
}

function engineTypeQuestionProcessor({ state, dispatch, answer }) {
  dispatch(updateMeta({ engineTypes: answer }));
  basicStepProcessor({ state, dispatch });
}

const stepProcessorMap = {
  [ZIP_CODE_QUESTION_ID]: zipCodeTypeQuestionProcessor,
  [TRANSACTION_TYPE_QUESTION_ID]: transactionTypeQuestionProcessor,
  [LOWER_INCOME_YEAR_QUESTION_ID]: lowerIncomeTypeQuestionProcessor,
  [ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID]: updateAnswerOnSameLocalQuestion(
    basicStepProcessor,
    ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID
  ),
  [TAX_FILING_STATUS_FEDERAL_QUESTION_ID]: updateAnswerOnSameLocalQuestion(
    taxFillingStatusTypeQuestionProcessor,
    TAX_FILING_STATUS_LOCAL_QUESTION_ID
  ),
  [TAX_FILING_STATUS_LOCAL_QUESTION_ID]: taxFillingStatusTypeQuestionProcessor,
  [INSTALL_EV_CHARGER_QUESTION_ID]: installEvChargerTypeQuestionProcessor,
  [CUSTOMER_OF_QUESTION_ID]: customerOffTypeQuestionProcessor,
  [CHECK_FEDERAL_REBATES_SCREEN_ID]: checkRebatesTypeScreenProcessor,
  [CHECK_LOCAL_REBATES_SCREEN_ID]: checkRebatesTypeScreenProcessor,
  [CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID]: checkingEligibilityTypeScreenProcessor,
  [CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID]: checkingEligibilityTypeScreenProcessor,
  [ENGINE_TYPE_QUESTION_ID]: engineTypeQuestionProcessor,
};

function processAnswer({ state, dispatch, onFilterIncentives, answer, incentives, context }) {
  const { currentStepIndex, flowSteps } = state;
  const currentStepId = flowSteps.at(currentStepIndex);
  dispatch(addAnswer(currentStepId, answer));

  const stepSpecificProcessor = stepProcessorMap[currentStepId] || basicStepProcessor;
  stepSpecificProcessor({ state, dispatch, onFilterIncentives, answer, incentives, context });
}

function basicPostIncentivesProcessor({ dispatch, incentives }) {
  dispatch(setLoading(false));
  dispatch(updateIncentives(incentives));
}

function eligibilityCheckerProcessor({ state, dispatch, incentives }) {
  const { activeCategory, incentives: allIncentives } = state;

  const incentivesForCurrentCategory = filterOutIncentivesWithClientSideEligibilityCheck(
    incentives.filter(incentive => checkIncentiveBelongsToCategory(activeCategory, incentive))
  );

  basicPostIncentivesProcessor({ dispatch, incentives: incentivesForCurrentCategory });

  const eligibleIncentivesIds = getIncentivesWithMaxRebate(incentivesForCurrentCategory).map(({ id }) => id);

  const savedIncentivesIdsForCurrentCategory = allIncentives
    .filter(incentive => checkIncentiveBelongsToCategory(activeCategory, incentive))
    .map(({ id }) => id);

  dispatch(removeIncentivesIds(savedIncentivesIdsForCurrentCategory));
  dispatch(addUniqIncentivesIds(eligibleIncentivesIds));

  basicStepProcessor({ state, dispatch });
}

function postIncentivesUpdateProcessor({ state, dispatch, incentives, context }) {
  basicPostIncentivesProcessor({ dispatch, incentives });
  flowBuilder.rebuildFlow(state, dispatch, incentives, context);

  dispatch(rememberNewFlowIncentives(incentives));
  basicStepProcessor({ state, dispatch });
}

const stepPostModelUpdateProcessorMap = {
  [TRANSACTION_TYPE_QUESTION_ID]: postIncentivesUpdateProcessor,
};

async function processIncentivesUpdate({ state, dispatch, incentives, context }) {
  const currentStepId = state.flowSteps.at(state.currentStepIndex);
  const postModelUpdateProcess = stepPostModelUpdateProcessorMap[currentStepId] || eligibilityCheckerProcessor;
  postModelUpdateProcess({ state, dispatch, incentives, context });
}

function processBack({ state, dispatch }) {
  const { flowSteps, currentStepIndex, activeCategory } = state;
  const prevQuestionIndex = flowSteps
    .slice(0, currentStepIndex)
    .findLastIndex(stepId => questions[stepId].variant === QUESTION);
  const prevQuestionCategory =
    questions[
      flowSteps[flowSteps.slice(0, prevQuestionIndex).findLastIndex(stepId => questions[stepId].type === ELIGIBILITY)]
    ].categories[0];
  if (prevQuestionCategory !== activeCategory) {
    dispatch(updateActiveCategory(prevQuestionCategory));
  }

  dispatch(setCurrentStepIndex(prevQuestionIndex));
}

export const flowProcessor = {
  processIncentivesUpdate,
  processAnswer,
  processBack,
};
