import { pick } from 'lodash';
import { buildPersistedIncentiveId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/persisted-incentive-id';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { isLocalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-rebate';
import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';
import {
  ENGINE_TYPE_QUESTION_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  ZIP_CODE_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';

export const getEligibilityFactorId = incentive => incentive.rebateOverridesByEligibility?.[0]?.eligibilityFactor?.id;

export const getWizardStateForStoring = wizardState => {
  const { incentives, eligibleIncentiveIds, answers, wizardStateCode } = wizardState;

  return {
    incentives: incentives
      .filter(incentive => isFederalRebate(incentive) || isLocalRebate(incentive) || isOEMRebate(incentive))
      .map(incentive => buildPersistedIncentiveId(incentive, getEligibilityFactorId(incentive))),
    eligibleIncentiveIds: incentives
      .filter(incentive => eligibleIncentiveIds.includes(incentive.id))
      .map(incentive => buildPersistedIncentiveId(incentive, '', '')),
    answers: pick(answers, [ZIP_CODE_QUESTION_ID, TRANSACTION_TYPE_QUESTION_ID, ENGINE_TYPE_QUESTION_ID]),
    stateCode: wizardStateCode,
  };
};
