import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PROGRAM_TYPES } from 'site-modules/shared/constants/incentives';

import './incentive-badge.scss';

const PROGRAM_TYPE_ICONS_MAP = {
  [PROGRAM_TYPES.TAX_CREDIT]: { icon: 'icon-office', colorClasses: 'text-blue-20 bg-blue-90 overlay-white-40' },
  [PROGRAM_TYPES.STATE_REBATE]: { icon: 'icon-office', colorClasses: 'text-blue-20 bg-blue-90 overlay-white-40' },
  [PROGRAM_TYPES.EV_REBATE]: { icon: 'icon-office', colorClasses: 'text-blue-20 bg-blue-90 overlay-white-40' },
  [PROGRAM_TYPES.MUNICIPALITY_REBATE]: {
    icon: 'icon-office',
    colorClasses: 'text-blue-20 bg-blue-90 overlay-white-40',
  },
  [PROGRAM_TYPES.UTILITY_REBATE]: { icon: 'icon-lightbulb_outline', colorClasses: 'text-cool-gray-30 bg-cool-gray-80' },
  [PROGRAM_TYPES.CHARGER_INSTALLATION]: {
    icon: 'icon-battery_charging_full2',
    colorClasses: 'text-turquoise-10 bg-turquoise-90 overlay-white-40',
  },
  [PROGRAM_TYPES.EV_CHARGING]: {
    icon: 'icon-battery_charging_full2',
    colorClasses: 'text-turquoise-10 bg-turquoise-90 overlay-white-40',
  },
  [PROGRAM_TYPES.CHARGER_REBATE]: {
    icon: 'icon-battery_charging_full2',
    colorClasses: 'text-turquoise-10 bg-turquoise-90 overlay-white-40',
  },
  other: { icon: 'icon-cash3', colorClasses: 'text-green-10 bg-green-90 overlay-white-40' },
};

export function IncentiveBadge({
  mode,
  tag: Tag,
  programType,
  badgeContent,
  rootClassName,
  badgeClassName,
  showPlaceholder,
  iconClassName,
  children,
}) {
  const { icon, colorClasses } = PROGRAM_TYPE_ICONS_MAP[programType] || PROGRAM_TYPE_ICONS_MAP.other;
  const isCircle = mode === 'circle';

  return (
    !showPlaceholder && (
      <Tag className={classnames('incentive-badge d-flex', rootClassName)}>
        <span
          className={classnames('size-12 pos-r', colorClasses, badgeClassName, {
            'd-inline-block square rounded-circle': isCircle,
            'border-20 px-0_5 py-0_25': !isCircle && !badgeClassName,
          })}
        >
          <span className={classnames(icon, iconClassName, { 'pos-a center-xy': isCircle })} aria-hidden />
          {!!badgeContent && <span className="ms-0_5 fw-medium">{badgeContent}</span>}
        </span>
        {children}
      </Tag>
    )
  );
}

IncentiveBadge.propTypes = {
  programType: PropTypes.string.isRequired,
  badgeContent: PropTypes.string,
  rootClassName: PropTypes.string,
  badgeClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mode: PropTypes.oneOf(['circle', 'ellipse']),
  tag: PropTypes.string,
};

IncentiveBadge.defaultProps = {
  badgeContent: '',
  rootClassName: null,
  badgeClassName: null,
  iconClassName: null,
  showPlaceholder: null,
  children: null,
  mode: 'ellipse',
  tag: 'div',
};
