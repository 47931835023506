import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { generateSrpLinkNewMakeModel } from 'site-modules/shared/utils/srp-link-constructor';
import { getBuildAndPriceLink } from 'site-modules/shared/utils/build-and-price-link-constructor';
import { Link } from 'site-modules/shared/components/link/link';
import { FormViewDecorator } from 'site-modules/shared/components/lead-form/unified-lead-form/views/form-view-decorator';

import './base-empty-view.scss';

const LINK_CLASS_NAME = 'text-primary-darker mb-0_5';
const ICON_CLASS_NAME = 'icon small icon-arrow-right3 ms-0_25';

function BaseEmptyViewUI({ make, model, year }) {
  const makeSlug = makeNiceName(make);
  const modelSlug = makeNiceName(model);

  return (
    <Row className="base-empty-view pos-r">
      <Col xs={12} md={7} className="module d-flex flex-column bg-white p-1 p-md-1_5 rounded-12">
        <div className="heading-3 mb-2 text-blue-50">Save with Edmunds</div>
        <div className="heading-4 mb-1">Sorry!</div>
        <div className="mb-1">There are no dealers in our network near you.</div>
        {!!(make && model) && (
          <Fragment>
            <Link className={LINK_CLASS_NAME} to={generateSrpLinkNewMakeModel(makeSlug, modelSlug)}>
              Search all {model} inventory
              <i className={ICON_CLASS_NAME} />
            </Link>
            <Link className={LINK_CLASS_NAME} to={getBuildAndPriceLink({ make: makeSlug, model: modelSlug, year })}>
              Build & Price
              <i className={ICON_CLASS_NAME} />
            </Link>
          </Fragment>
        )}
      </Col>
    </Row>
  );
}

BaseEmptyViewUI.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  make: PropTypes.string,
  model: PropTypes.string,
};

BaseEmptyViewUI.defaultProps = {
  year: null,
  make: null,
  model: null,
};

export const BaseEmptyView = FormViewDecorator(BaseEmptyViewUI);
