import React, { Fragment, startTransition, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import { Button, Modal, ModalBody } from 'reactstrap';

import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { EventToolbox } from 'client/utils/event-toolbox';
import { Helper } from 'client/engagement-handlers/element-engagement-handler/helper';
import { PHOTOFLIPPER_EVENTS } from 'site-modules/shared/constants/custom-events';
import { PHOTO_TYPE, VIDEO_TYPE } from 'site-modules/shared/constants/photoflipper/photoflipper';
import { ENTER_KEY_CODE, SPACE_KEY_CODE } from 'site-modules/shared/constants/key-codes';
import { PHOTOFLIPPER_EVERY_SLIDE } from 'client/engagement-handlers/photoflipper-engagement-handler/photoflipper-engagement-handler';
import { StickyBanner } from 'site-modules/shared/components/sticky-banner/sticky-banner';

import './photoflipper-button.scss';

const DEFAULT_BUTTON_CLASSES = 'border-0 photoflipper-button text-white px-0_5 py-0_25 xsmall';

export const photoflipperAds = {
  leaderc: ['1'],
  mab: ['1'],
  mrect: ['1'],
  spot: ['0', '1'],
};

export function getPhotoflipperButton(WrappedComponent) {
  function PhotoflipperButton({
    makeSlug,
    modelSlug,
    year,
    trim,
    submodel,
    children,
    simplified,
    photoUrls,
    photoType,
    initialMediaType,
    dataTrackingId,
    wrapperClass,
    isCpo,
    vin,
    tag: Tag,
    disabled,
    className,
    inlineStyle,
    hideBtn,
    isModalOpenFromOutside,
    adhesionBanner,
    isMobile,
    ...props
  }) {
    const [isModalOpen, toggleModalOpen] = useToggle();

    const toggleModal = useCallback(
      event => {
        if (
          disabled ||
          Helper.isControlElement(get(event, 'target')) ||
          (event && event.type === 'keydown' && ![ENTER_KEY_CODE, SPACE_KEY_CODE].includes(event.keyCode))
        ) {
          return;
        }

        toggleModalOpen();
        startTransition(() => {
          EventToolbox.fireCustomEvent(PHOTOFLIPPER_EVENTS.TOGGLE, {
            isModalOpen: !isModalOpen,
            ads: photoflipperAds,
            correlatorTsShouldBeUpdated: isModalOpen,
          });
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [disabled, isModalOpen]
    );

    useEffect(() => {
      if (isModalOpenFromOutside) {
        toggleModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpenFromOutside]);

    if (!makeSlug || !modelSlug || !year) {
      return null;
    }

    /**
     * React.children.only needs to predict incorrect usage of the component.
     * We should pass only one child.
     * */
    React.Children.only(children);

    const ButtonView = hideBtn
      ? null
      : React.cloneElement(children, {
          className: children.props.className || DEFAULT_BUTTON_CLASSES,
          onClick: toggleModal,
          onKeyDown: toggleModal,
          tabIndex: '0',
          'data-tracking-id': dataTrackingId || 'open_legacy_photoflipper',
          'data-no-refresh': true,
        });

    const tagProps = Tag === Fragment ? null : { className, style: inlineStyle };

    return (
      <Tag {...tagProps}>
        {ButtonView}
        <Modal
          isOpen={isModalOpen}
          toggle={toggleModal}
          className={classNames('photoflipper-modal overflow-hidden', {
            'is-wired': !isMobile,
            adhesion: !!adhesionBanner,
            'is-mobile': isMobile,
          })}
          labelledBy="viewerTitle"
        >
          <ModalBody className="pf-body overflow-hidden p-0">
            <WrappedComponent
              make={makeSlug}
              model={modelSlug}
              year={year}
              trim={trim}
              submodel={submodel || ''}
              photoType={photoType}
              isOpen={isModalOpen}
              toggle={toggleModal}
              simplified={simplified}
              photoUrls={photoUrls}
              initialMediaType={initialMediaType}
              isCpo={isCpo}
              vin={vin}
              {...props}
            />
            <Button className="pf-close-btn btn-close" aria-label="close" onClick={toggleModal}>
              <i className="icon-cross2" aria-hidden />
            </Button>
            {adhesionBanner && (
              <StickyBanner
                noRefresh={['scroll', 'carousel']}
                refreshZones={['adhesion', PHOTOFLIPPER_EVERY_SLIDE]}
                prefixId="photoflipper"
              />
            )}
          </ModalBody>
        </Modal>
      </Tag>
    );
  }

  PhotoflipperButton.propTypes = {
    makeSlug: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    modelSlug: PropTypes.string,
    trim: PropTypes.string,
    submodel: PropTypes.string,
    photoType: PropTypes.string,
    simplified: PropTypes.bool,
    photoUrls: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          url: PropTypes.string,
          photoType: PropTypes.string,
        }),
      ])
    ),
    children: PropTypes.element.isRequired,
    initialMediaType: PropTypes.oneOf([PHOTO_TYPE, VIDEO_TYPE]),
    dataTrackingId: PropTypes.string,
    isCpo: PropTypes.bool,
    vin: PropTypes.string,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.symbol]),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inlineStyle: PropTypes.shape({}),
    isModalOpenFromOutside: PropTypes.bool,
    hideBtn: PropTypes.bool,
  };

  PhotoflipperButton.defaultProps = {
    makeSlug: null,
    year: null,
    modelSlug: null,
    trim: null,
    submodel: null,
    simplified: false,
    photoUrls: [],
    photoType: '',
    initialMediaType: PHOTO_TYPE,
    dataTrackingId: null,
    isCpo: false,
    vin: null,
    tag: 'div',
    disabled: false,
    className: null,
    inlineStyle: {},
    isModalOpenFromOutside: false,
    hideBtn: false,
  };

  return PhotoflipperButton;
}
