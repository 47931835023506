import { get, mergeWith, omit } from 'lodash';
import { CHECKING_ELIGIBILITY_PROGRAM_QUESTIONS_MAP } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { getQualifierMapper } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/qualifier-mapper-factory';
import { ELIGIBILITY_FIELD_MAP_TARGET } from 'site-modules/shared/components/incentives/incentives-wizard/constants/eligibility';

// need to add all invalid fields. (if additional fields are received in EligibilityFactor)
const OMITTED_FIELDS = ['qualifier', 'qualifierDescription', 'id'];

const getAllEligibilityFactors = incentives =>
  incentives.flatMap(incentive => {
    const rebateOverridesByEligibility = get(incentive, 'rebateOverridesByEligibility', []);
    if (!rebateOverridesByEligibility.length) return [];
    return rebateOverridesByEligibility.map(rebateEligibility => get(rebateEligibility, 'eligibilityFactor'));
  });

const getEligibilityFields = incentives => {
  const eligibilityFields = {};
  const eligibilityFactors = getAllEligibilityFactors(incentives);
  if (!eligibilityFactors.length) return eligibilityFields;

  eligibilityFactors.forEach(eligibilityFactor => {
    Object.entries(eligibilityFactor).forEach(([key, value]) => {
      if (value) {
        eligibilityFields[key] = Array.from(new Set([...get(eligibilityFields, key, []), eligibilityFactor.qualifier]));
      }
    });
  });

  return omit(eligibilityFields, OMITTED_FIELDS);
};

const mapQualifier = (qualifier, field, usageTarget) => getQualifierMapper(qualifier)(field, usageTarget);

const mapMultiQualifier = (multiQualifier, field, usageTarget) => {
  const qualifiers = multiQualifier.split(';');

  return qualifiers.flatMap(qualifier => mapQualifier(qualifier, field, usageTarget));
};

const mapQualifierSpecificFieldToQuestions = (qualifier, field, usageTarget) => {
  if (qualifier.includes(';')) {
    return mapMultiQualifier(qualifier, field, usageTarget);
  }

  return mapQualifier(qualifier, field, usageTarget);
};

const getDynamicQuestions = (programName, eligibilityFields) =>
  Array.from(
    new Set(
      Object.entries(eligibilityFields).flatMap(([field, qualifiers]) =>
        qualifiers.reduce(
          (questions, qualifier) => [
            ...questions,
            ...mapQualifierSpecificFieldToQuestions(qualifier, field, ELIGIBILITY_FIELD_MAP_TARGET.DISPLAY),
          ],
          []
        )
      )
    )
  ).concat(CHECKING_ELIGIBILITY_PROGRAM_QUESTIONS_MAP[programName] || []);

function getEligibilityFieldsByProgram(programEntries) {
  return Object.fromEntries(
    programEntries.map(([programName, { incentives }]) => {
      const eligibilityFields = getEligibilityFields(incentives);
      return [programName, eligibilityFields];
    })
  );
}

function appendQuestionsBasedOnEligibilityFields(programs, programEntries, programEligibilityFields) {
  const updatedPrograms = programs;

  programEntries.forEach(([programName, { flow }]) => {
    const dynamicQuestions = getDynamicQuestions(programName, programEligibilityFields[programName]);
    updatedPrograms[programName].flow = [...flow, ...dynamicQuestions];
  });

  return updatedPrograms;
}

const getQuestionsQualifierMap = programEligibilityFields => {
  const combinedEligibilityFields = Object.values(programEligibilityFields).reduce(
    (eligibilityFields, eligibilityFieldsFromProgram) =>
      mergeWith(eligibilityFields, eligibilityFieldsFromProgram, (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return Array.from(new Set(objValue.concat(srcValue)));
        }
        return srcValue;
      }),
    {}
  );

  return Object.entries(combinedEligibilityFields).reduce((result, [fieldName, qualifierList]) => {
    const qualifierQuestions = qualifierList.reduce(
      (questionsGroupedByQualifier, qualifier) => ({
        ...questionsGroupedByQualifier,
        [qualifier]: [
          ...(questionsGroupedByQualifier[qualifier] || []),
          ...mapQualifierSpecificFieldToQuestions(qualifier, fieldName, ELIGIBILITY_FIELD_MAP_TARGET.FILTER),
        ],
      }),
      {}
    );

    return Object.entries(qualifierQuestions).reduce(
      (questionsQualifiersMap, [qualifier, questions]) =>
        mergeWith(
          questionsQualifiersMap,
          Object.fromEntries(questions.map(question => [question, [qualifier]])),
          (objValue, srcValue) => {
            if (Array.isArray(objValue)) {
              return Array.from(new Set(objValue.concat(srcValue)));
            }
            return srcValue;
          }
        ),
      result
    );
  }, {});
};

function map(programs) {
  const programEntries = Object.entries(programs);
  const programEligibilityFields = getEligibilityFieldsByProgram(programEntries);
  const updatedFlows = appendQuestionsBasedOnEligibilityFields(programs, programEntries, programEligibilityFields);
  const eligibilityQuestionsQualifiersMap = getQuestionsQualifierMap(programEligibilityFields);

  return { programs: updatedFlows, eligibilityQuestionsQualifiersMap };
}

export const eligibilityFactorsMapper = {
  map,
};
