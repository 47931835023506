import React, { memo, useEffect } from 'react';
// Constants
import { PROGRAM_TYPES } from 'site-modules/shared/constants/incentives';
import { TrackingConstant } from 'client/tracking/constant';
import { ZIP_CODE_QUESTION_ID } from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
// Utils
import { getPlural } from 'client/utils/plural';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getTotalRebateAmount } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-rebate-amount-from-incentives';
import { getIncentiveIdEligibilityFactorIdPairs } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-incentive-id-eligibility-factor-id-pairs';
// Components
import { EligibilityIncentivesList } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/eligibility-incentives-list';
import { WizardPrice } from 'site-modules/shared/components/incentives/incentives-wizard/wizard-price';
import {
  StepProps,
  StepPropsDefaults,
} from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { WizardImage } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/wizard-image';
import { NextSteps } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/next-steps';
import { EmailReviewAnswersSection } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/email-review-answers-section';
import { ResultScreenCta } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/result-screen-cta';
import { buildEligibleIncentives } from 'site-modules/shared/components/incentives/incentives-wizard/utils/build-eligible-incentives';

import './result-screen.scss';

export const ResultScreen = memo(props => {
  const {
    stepConfig: { srpLink },
    onFinish,
    context: { make, model, bodyType },
    allIncentives,
    eligibleIncentiveIds,
    onExit,
    creativeId,
    answers,
    onStartConvexAnimation,
    flowSkipped,
    onStartOver,
  } = props;

  const eligibleIncentives = buildEligibleIncentives(allIncentives, eligibleIncentiveIds);

  const incentiveIdEligibilityFactorIdPairs = getIncentiveIdEligibilityFactorIdPairs(eligibleIncentives);

  const eligibleSubtypeIds = eligibleIncentives.map(({ subtypeId }) => subtypeId);
  const allEligibleIncentiveIds = eligibleIncentives.map(({ id }) => id);

  const total = getTotalRebateAmount(eligibleIncentives);

  const ineligibleIncentives = allIncentives.filter(
    ({ id, programType }) =>
      !eligibleIncentives.find(({ id: incId }) => incId === id) && Object.values(PROGRAM_TYPES).includes(programType)
  );
  const rebatesNumber = eligibleIncentives.length;

  useEffect(
    () => {
      if (!flowSkipped) {
        onFinish(total);
      }
      onStartConvexAnimation(true);

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
        event_data: {
          action_name: TrackingConstant.ACTION_FILL_FORM,
          subaction_name: TrackingConstant.MATCHED_INCENTIVES_COUNT,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          creative_id: creativeId,
          value: rebatesNumber,
        },
      });
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_FILL_FORM,
          subaction_name: TrackingConstant.MATCHED_INCENTIVES_SUM,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          creative_id: creativeId,
          value: total,
        },
      });

      return () => {
        onStartConvexAnimation(false);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const zip = answers[ZIP_CODE_QUESTION_ID]?.[0];

  return (
    <div className="result-screen">
      <div className="rounded-16 p-1_5">
        <div className="pos-r pt-3">
          <WizardImage bodyType={bodyType} />
          <div className="result-info mx-auto text-center">
            <h2 className="size-24 fw-bold mb-1_5">
              {rebatesNumber
                ? `Nice! We found ${rebatesNumber} ${getPlural('rebate', rebatesNumber)} that you’re eligible for.`
                : 'Unfortunately, we didn’t find any eligible rebates.'}
            </h2>
            {rebatesNumber ? (
              <div className="p-1_5 rounded-12 bg-blue-100 text-center">
                <>
                  <div className="fw-bold">You’re saving</div>
                  <WizardPrice value={total} className="fw-bold size-36" odometerWrapperClassName="odometer-wrapper" />
                  <div className="text-cool-gray-10 medium">
                    on your {make} {model}
                  </div>
                  {!!rebatesNumber && !!zip && (
                    <EmailReviewAnswersSection
                      make={make}
                      model={model}
                      eligibleSubtypeIds={eligibleSubtypeIds}
                      eligibleIncentiveIds={allEligibleIncentiveIds}
                      incentiveIdEligibilityFactorIdPairs={incentiveIdEligibilityFactorIdPairs}
                      zip={zip}
                      creativeId={creativeId}
                      onStartOver={onStartOver}
                    />
                  )}
                </>
              </div>
            ) : (
              <ResultScreenCta onExit={onExit} srpLink={srpLink}>
                Keep Shopping
              </ResultScreenCta>
            )}
          </div>

          <EligibilityIncentivesList
            eligibleIncentives={eligibleIncentives}
            ineligibleIncentives={ineligibleIncentives}
          />
        </div>
      </div>
      <NextSteps make={make} model={model} srpLink={srpLink} onExit={onExit} withSteps={!!rebatesNumber} />
    </div>
  );
});

ResultScreen.displayName = 'ResultScreen';

ResultScreen.propTypes = StepProps;
ResultScreen.defaultProps = StepPropsDefaults;
