import React from 'react';
import PropTypes from 'prop-types';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import './benefits-with-icons.scss';

export const BenefitsWithIcons = ({ benefits, isMobile }) => (
  <ul className="private-party-benefits list-unstyled my-1_5 d-flex justify-content-between">
    {benefits.map((benefit, index) => {
      const { icon, width, text, textMobile } = benefit;
      return (
        <li key={`benefit_${index + 1}`} className="benefit-item">
          <div>
            <div className="benefit-icon mb-0_25 d-flex align-items-center">
              <img src={getStaticImageUrl(icon)} alt="" loading="lazy" width={width} />
            </div>
            <div className="benefit-copy">{isMobile && textMobile ? textMobile : text}</div>
          </div>
        </li>
      );
    })}
  </ul>
);

BenefitsWithIcons.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
};

BenefitsWithIcons.defaultProps = {
  benefits: [],
  isMobile: false,
};
