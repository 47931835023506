import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
// Utils
import { getStaticImageUrl } from 'client/utils/image-helpers';
// Components
import { WizardDisclaimer } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/wizard-disclaimer';
import { ResultScreenCta } from 'site-modules/shared/components/incentives/incentives-wizard/steps/screens/components/result-screen-cta';

import './next-steps.scss';

const NEXT_STEPS_MAP = [
  {
    imgUrl: '/img/wizard/confirm-eligibilty.svg',
    title: 'Confirm eligibility',
    description: () =>
      'Confirm that you and your chosen VIN are eligible for all rebates. Some VINs may not qualify for rebates due to price or other program restrictions.',
  },
  {
    imgUrl: '/img/wizard/buy-vehicle.svg',
    title: 'Buy your vehicle',
    description: ({ make, model }) => `Purchase your chosen ${make} ${model}.`,
  },
  {
    imgUrl: '/img/wizard/submit-application.svg',
    title: 'Submit your application(s)',
    description: () =>
      'Open the program link and follow all application instructions. You must submit your details according to each program’s guidelines in order to receive any eligible amount.',
  },
];

export const NextSteps = memo(({ make, model, srpLink, onExit, withSteps }) => (
  <div className="next-steps pos-a left-0">
    {withSteps && (
      <div className="bg-white mt-2 rounded-16 py-1_5 px-1 px-md-1_5 text-center">
        <h2 className="size-24 fw-bold">Next Steps</h2>
        <ol className="list-unstyled p-0">
          {NEXT_STEPS_MAP.map(({ imgUrl, title, description }) => (
            <li key={imgUrl} className="d-flex flex-column align-items-center mt-2">
              <img src={getStaticImageUrl(imgUrl)} alt="" width={120} />
              <span className="step-title mt-1 text-cool-gray-10 size-20 fw-bold">{title}</span>
              <span className="mt-0_5">{description({ make, model })}</span>
            </li>
          ))}
        </ol>
        <ResultScreenCta wrapperClassName="mt-2" onExit={onExit} srpLink={srpLink}>
          View Inventory
        </ResultScreenCta>
      </div>
    )}
    <WizardDisclaimer classes="medium my-2" />
  </div>
));

NextSteps.displayName = 'NextSteps';
NextSteps.propTypes = {
  make: PropTypes.string,
  model: PropTypes.string,
  srpLink: PropTypes.string,
  onExit: PropTypes.func,
  withSteps: PropTypes.bool,
};
NextSteps.defaultProps = {
  make: '',
  model: '',
  srpLink: null,
  onExit: noop,
  withSteps: false,
};
