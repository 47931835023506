import { memo, useLayoutEffect } from 'react';
import { StepProps } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import PropTypes from 'prop-types';

export const BaseWizardStep = memo(props => {
  const {
    stepConfig: { autoCloseAfter, id, autoAnswer },
    onAnswer,
    children,
  } = props;
  const [setAutoCloseTimeout, clearAutoCloseTimeout] = useTimeout();

  useLayoutEffect(() => {
    if (autoCloseAfter) {
      setAutoCloseTimeout(() => {
        onAnswer(autoAnswer || []);
      }, autoCloseAfter);
    }

    return () => {
      clearAutoCloseTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCloseAfter, id]);

  return children;
});

BaseWizardStep.displayName = 'BaseWizardStep';

BaseWizardStep.propTypes = {
  ...StepProps,
  children: PropTypes.node,
};
BaseWizardStep.defaultProps = {
  children: null,
};
