import {
  MODAL_BG_HEIGHT,
  MODAL_MOBILE_BG_HEIGHT,
  MODAL_THANK_YOU_BG_HEIGHT,
  PRIVATE_PARTY_THANK_YOU,
} from 'site-modules/shared/constants/private-party/private-party';

export const checkIsDisabled = items => !Object.values(items).some(item => item);

export function getBgHeight(step, isMobile) {
  if (step === PRIVATE_PARTY_THANK_YOU) {
    return MODAL_THANK_YOU_BG_HEIGHT;
  }
  return isMobile ? MODAL_MOBILE_BG_HEIGHT : MODAL_BG_HEIGHT;
}
