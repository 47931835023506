import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
/* Constants */
import {
  PRIVATE_PARTY_LISTING_SETUP,
  PRIVATE_PARTY_SELL_CAR_CTA,
  PRIVATE_PARTY_SELL_CAR_TED6628,
  SELL_CAR_STEPS_TED6628,
} from 'site-modules/shared/constants/private-party/private-party';
import { PRIVATE_PARTY_EVENTS } from 'site-modules/shared/constants/private-party/private-party-events';
/* Utils */
import { getStaticImageUrl } from 'client/utils/image-helpers';
/* Tracking */
import { EventToolbox } from 'client/utils/event-toolbox';
import { EngagementHandler } from 'client/engagement-handlers';
import { PrivatePartyEngagementHandler } from 'client/engagement-handlers/private-party-engagement-handler/private-party-engagement-handler';
/* Components */
import { SellCarBenefits } from 'site-modules/shared/components/private-party/sell-car-benefits/sell-car-benefits';
import { SellCarCta } from 'site-modules/shared/components/private-party/sell-car-cta/sell-car-cta';
import { PrivatePartyModal } from 'site-modules/shared/components/private-party/private-party-modal/private-party-modal';
import { SellCarHeader } from 'site-modules/shared/components/private-party/sell-car-header/sell-car-header';
import { SellCarSteps } from 'site-modules/shared/components/private-party/sell-car-steps/sell-car-steps';
import { BenefitsWithIcons } from 'site-modules/shared/components/private-party/benefits-with-icons/benefits-with-icons';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { Link } from 'site-modules/shared/components/link/link';

import './private-party-sell-car-used-core.scss';

EngagementHandler.register(PrivatePartyEngagementHandler);

export function PrivatePartySellCarUsedCore({ ted6628Recipe, isMobile }) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { header, imageSrc, imageSrcMobile, benefits, benefitsMobile } = PRIVATE_PARTY_SELL_CAR_TED6628[ted6628Recipe];
  const isTED6628Chal1 = ted6628Recipe === 'chal-1';
  const isTED6628Chal2 = ted6628Recipe === 'chal-2';
  const isTED6628Chal3 = ted6628Recipe === 'chal-3';
  const isTED6628Chal4 = ted6628Recipe === 'chal-4';
  const isTED6628Chal1Chal2 = isTED6628Chal1 || isTED6628Chal2;

  const handlePrivatePartyModalOpen = useCallback(
    e => {
      if (isTED6628Chal3) {
        e.preventDefault();
      }
      EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.START_LISTING, { value: PRIVATE_PARTY_SELL_CAR_CTA });
      setIsModalOpened(true);
    },
    [isTED6628Chal3]
  );
  const image = isMobile && isTED6628Chal1Chal2 ? imageSrcMobile : imageSrc;

  return (
    <div
      data-tracking-parent="edm-entry-private-party"
      className={classnames('private-party-entry-point-wrapper', {
        'pt-1': isTED6628Chal1Chal2 || isTED6628Chal3 || (isMobile && isTED6628Chal4),
      })}
    >
      <div
        className={classnames('bg-white', {
          'rounded-8': isMobile && isTED6628Chal1Chal2,
          'rounded-12': (!isMobile && isTED6628Chal1Chal2) || isTED6628Chal3 || isTED6628Chal4,
          'px-1 py-1 py-md-1_5': isTED6628Chal1Chal2,
          'px-1 py-1': isTED6628Chal3,
          'px-1_5 py-1': isTED6628Chal4,
        })}
      >
        {!isTED6628Chal3 && (
          <SellCarHeader classNames={classnames('mb-0', { 'size-24': !isTED6628Chal4, 'size-16': isTED6628Chal4 })} />
        )}
        {isTED6628Chal2 && <SellCarSteps steps={SELL_CAR_STEPS_TED6628} isUsedCore />}
        {(isTED6628Chal1 || isTED6628Chal2) && (
          <div className="d-flex flex-column flex-md-row justify-content-between mt-1_5">
            <img
              src={getStaticImageUrl(image)}
              className="mb-1_5 mb-md-0 me-md-2"
              width={isMobile ? 239 : 167}
              height={isMobile ? 159 : 135}
              alt=""
              loading="lazy"
            />
            <div>
              <SellCarBenefits
                benefits={isMobile ? benefitsMobile : benefits}
                wrapperClasses="mb-0_5"
                iconClasses="text-blue-50"
                benefitItemClasses="pb-0_5"
              />
              <SellCarCta ctaText={PRIVATE_PARTY_SELL_CAR_CTA} onClick={handlePrivatePartyModalOpen} />
            </div>
          </div>
        )}
        {isTED6628Chal3 && (
          <Link
            className="text-decoration-none d-flex justify-content-between align-items-center"
            onClick={handlePrivatePartyModalOpen}
            to=""
          >
            <img src={getStaticImageUrl(imageSrc)} width={40} alt="" loading="lazy" />
            <div className="ms-0_75 me-1 benefits-container">
              <h2 className="fw-bold text-blue-40 size-16 mb-0_25">{header}</h2>
              <ul className="ps-1 ps-md-0 benefits-list size-14 mb-0 d-md-flex">
                {benefits.map((benefit, index) => (
                  <li key={`benefit_${index + 1}`} className="pe-md-2">
                    <ContentFragment>{benefit}</ContentFragment>
                  </li>
                ))}
              </ul>
            </div>

            <i className="icon-arrow-right4 text-primary" aria-hidden />
          </Link>
        )}
        {isTED6628Chal4 && (
          <>
            <BenefitsWithIcons benefits={benefits} isMobile={isMobile} />
            <SellCarCta ctaText={PRIVATE_PARTY_SELL_CAR_CTA} onClick={handlePrivatePartyModalOpen} />
          </>
        )}

        <PrivatePartyModal
          isModalOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          isMobile={isMobile}
          initialStep={PRIVATE_PARTY_LISTING_SETUP}
        />
      </div>
    </div>
  );
}

PrivatePartySellCarUsedCore.propTypes = {
  ted6628Recipe: PropTypes.string,
  isMobile: PropTypes.bool,
};

PrivatePartySellCarUsedCore.defaultProps = {
  ted6628Recipe: '',
  isMobile: false,
};
