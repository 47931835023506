import {
  ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID,
  LOWER_INCOME_YEAR_QUESTION_ID,
  TAX_FILING_STATUS_FEDERAL_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { ELIGIBILITY_FIELD_MAP_TARGET } from 'site-modules/shared/components/incentives/incentives-wizard/constants/eligibility';
import { mapBase } from 'site-modules/shared/components/incentives/incentives-wizard/flow-builders/qualifier-question-mappers/base-mapper';

const ELIGIBILITY_FIELD_DISPLAY_QUESTIONS_MAP = {
  maxIncome: [LOWER_INCOME_YEAR_QUESTION_ID, ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID],
  taxFilingStatus: [TAX_FILING_STATUS_FEDERAL_QUESTION_ID],
};

const ELIGIBILITY_FIELD_FILTER_QUESTIONS_MAP = {
  maxIncome: [ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID],
  taxFilingStatus: [TAX_FILING_STATUS_FEDERAL_QUESTION_ID],
};

export const mapFilingCurrentYear = (field, target = ELIGIBILITY_FIELD_MAP_TARGET.DISPLAY) => {
  const fieldQuestionMap =
    target === ELIGIBILITY_FIELD_MAP_TARGET.DISPLAY
      ? ELIGIBILITY_FIELD_DISPLAY_QUESTIONS_MAP
      : ELIGIBILITY_FIELD_FILTER_QUESTIONS_MAP;

  return fieldQuestionMap[field] || mapBase(field, target);
};
