import React, { Fragment } from 'react';
import { get, maxBy } from 'lodash';
import { getPriceString } from 'site-modules/shared/utils/price-utils';

export const getPricingLabel = (trim, isDisplayLabel) => {
  const msrp = trim?.pricing?.totalMsrp || trim?.pricing?.baseMsrp;
  const msrpText = isDisplayLabel ? (
    <Fragment>
      {' '}
      - <strong>{getPriceString(msrp)} MSRP</strong>
    </Fragment>
  ) : (
    ` - ${getPriceString(msrp)} MSRP`
  );
  return msrp ? msrpText : '';
};

export const getTrimLabel = ({ trimSlug, styleId, recommendedTrimSlug, mostPopularStyleId }) => {
  switch (true) {
    case !!recommendedTrimSlug && trimSlug === recommendedTrimSlug: {
      return " (Editors' Pick)";
    }
    case styleId === mostPopularStyleId: {
      return ' (Most Popular)';
    }
    default: {
      return '';
    }
  }
};

export const getTrimsWithLabels = (trims, { recommendedTrimSlug } = {}) => {
  const mostPopularStyleId = get(maxBy(trims, 'shareBySalesSum'), 'styleId');

  return trims
    .map(trim => {
      const trimLabel = getTrimLabel({
        trimSlug: trim.trim,
        styleId: trim.styleId,
        recommendedTrimSlug,
        mostPopularStyleId,
      });

      return {
        ...trim,
        label: `${trim.trimName}${trimLabel}${getPricingLabel(trim)}`,
        displayLabel: (
          <Fragment>
            {trim.trimName}
            {trimLabel}
            {getPricingLabel(trim, true)}
          </Fragment>
        ),
      };
    })
    .sort((a, b) => get(a, 'pricing.baseMsrp', 0) - get(b, 'pricing.baseMsrp', 0));
};
