import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { internationalZipPickerMethods } from 'site-modules/shared/components/international-zip-picker-methods/international-zip-picker-methods';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { ZipInput } from 'site-modules/shared/components/zip-input/zip-input';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';

import './international-zip-picker.scss';

const ZIP_PICKER_CREATIVE_ID = 'international-zip-cta';

export function InternationalZipCodeForm({
  colConfig,
  placeholder,
  onZipChangeInProgress,
  onBtnClick,
  showActiveSpinner,
  btnClasses,
  btnTitle,
  trackingId,
  trackingValue,
}) {
  const onZipFocus = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        subaction_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        action_category: 'user',
        action_cause: 'link_click',
        creative_id: ZIP_PICKER_CREATIVE_ID,
      },
    });
  }, []);

  const onZipChange = useCallback(zipValue => {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        subaction_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
        action_category: 'user',
        action_cause: 'user_input',
        creative_id: ZIP_PICKER_CREATIVE_ID,
        value: zipValue,
      },
    });
  }, []);

  return (
    <Row>
      <Col {...colConfig.zip}>
        <ZipInput
          placeholder={placeholder}
          wrapperClasses="d-flex international-zip-input-wrapper zip-location-icon"
          inputClasses="international-zip-input ps-1"
          labelClasses="d-none"
          label="ZIP Code"
          onValidate={validation.validateZip}
          onZipChangeInProgress={onZipChangeInProgress}
          trackingCbOnZipChange={onZipChange}
          onZipFocus={onZipFocus}
          forceEmptyZip
        />
      </Col>
      <Col {...colConfig.btn}>
        <Button
          onClick={onBtnClick}
          className={classNames('submit-zip-btn px-0_75 py-0_5 w-100', btnClasses)}
          color="primary"
          rel="nofollow"
          disabled={showActiveSpinner}
          data-tracking-id={trackingId}
          data-tracking-value={trackingValue}
          data-tracking-disabled={!trackingId}
        >
          {showActiveSpinner ? <Spinner color="gray" className="align-baseline" size={16} /> : btnTitle}
        </Button>
      </Col>
    </Row>
  );
}

InternationalZipCodeForm.propTypes = {
  onZipChangeInProgress: PropTypes.func.isRequired,
  onBtnClick: PropTypes.func.isRequired,
  colConfig: PropTypes.shape({
    zip: PropTypes.shape({}),
    btn: PropTypes.shape({}),
  }),
  placeholder: PropTypes.string,
  btnClasses: PropTypes.string,
  btnTitle: PropTypes.string,
  showActiveSpinner: PropTypes.bool,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
};

InternationalZipCodeForm.defaultProps = {
  colConfig: {
    zip: {
      xs: 6,
      xl: 5,
    },
    btn: {
      xs: 6,
      md: 5,
      xl: 4,
    },
  },
  placeholder: '',
  btnClasses: '',
  btnTitle: 'See all inventory',
  showActiveSpinner: false,
  trackingId: '',
  trackingValue: 'See all inventory',
};

function InternationalZipPickerUI({
  modelName,
  className,
  btnClassName,
  onZipChangeInProgress,
  onBtnClick,
  showActiveSpinner,
  btnTitle,
  subTitle,
  colConfig,
  isFullWidth,
}) {
  return (
    <Row>
      <Col xs={12} lg={isFullWidth ? undefined : 8} data-tracking-parent={ZIP_PICKER_CREATIVE_ID}>
        {!!modelName && <div className="heading-3 mb-1">Find savings on the {modelName} for sale near you</div>}
        <div className={classNames('international-zip-picker-wrapper', className)}>
          <Row className="align-items-end">
            <Col xs={12} md={isFullWidth ? undefined : 7} className="d-flex flex-column justify-content-center">
              <div className="heading-4 pb-0_25">
                <i className="icon-location2 text-primary" aria-hidden />
                Oops, we can’t seem to locate you.
              </div>
              <div className={classNames('mb-0_75', { 'mb-md-0': !isFullWidth })}>{subTitle}</div>
            </Col>
            <Col xs={12} md={isFullWidth ? undefined : 5}>
              <InternationalZipCodeForm
                placeholder="Zip code"
                onZipChangeInProgress={onZipChangeInProgress}
                onBtnClick={onBtnClick}
                showActiveSpinner={showActiveSpinner}
                btnClasses={btnClassName}
                btnTitle={btnTitle}
                colConfig={colConfig}
                trackingId="provide_zip_code"
                trackingValue={btnTitle}
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

InternationalZipPickerUI.propTypes = {
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  modelName: PropTypes.string,
  showActiveSpinner: PropTypes.bool,
  onZipChangeInProgress: PropTypes.func.isRequired,
  onBtnClick: PropTypes.func.isRequired,
  btnTitle: PropTypes.string,
  subTitle: PropTypes.string,
  isFullWidth: PropTypes.bool,
  colConfig: PropTypes.shape({
    zip: PropTypes.shape({}),
    btn: PropTypes.shape({}),
  }),
};

InternationalZipPickerUI.defaultProps = {
  className: 'rounded p-1 p-md-1_5',
  btnClassName: 'btn-outline-primary-b size-16',
  modelName: '',
  showActiveSpinner: false,
  btnTitle: 'See Pricing',
  subTitle: 'Please enter U.S. ZIP code to see vehicles for sale',
  isFullWidth: false,
  colConfig: { zip: { xs: 6 }, btn: { xs: 6 } },
};

export const InternationalZipPicker = internationalZipPickerMethods(InternationalZipPickerUI);
