import { useCallback, useEffect, useReducer } from 'react';
import { wizardStateManagerReducer } from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/wizard-state-manager-reducer';
import { getInitialWizardState } from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/initial-state';
import {
  startOver,
  updateIncentives,
} from 'site-modules/shared/components/incentives/incentives-wizard/state-manager/actions';

export function useIncentivesWizardStateManager({
  flowProcessor,
  flowBuilder,
  incentives,
  initialStepIndex,
  initialEligibleIncentivesIds,
  onFilterIncentives,
  context,
  savedAnswers,
}) {
  const [state, dispatch] = useReducer(
    wizardStateManagerReducer,
    getInitialWizardState({ incentives, initialStepIndex, initialEligibleIncentivesIds, savedAnswers })
  );

  useEffect(() => {
    if (!incentives) {
      return;
    }

    if (!state.flowSteps.length) {
      flowBuilder.buildFlow(state, dispatch, incentives, context);
      dispatch(updateIncentives(incentives));
      return;
    }

    flowProcessor.processIncentivesUpdate({ state, dispatch, incentives, context });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incentives]);

  const handleAnswer = useCallback(
    answer => {
      flowProcessor.processAnswer({ state, dispatch, onFilterIncentives, answer, incentives, context });
    },
    [flowProcessor, state, onFilterIncentives, incentives, context]
  );

  const handleBack = useCallback(() => {
    flowProcessor.processBack({ state, dispatch });
  }, [flowProcessor, state]);

  const handleStartOver = useCallback(() => {
    dispatch(startOver(getInitialWizardState({ initialStepIndex })));
  }, [initialStepIndex]);

  return { state, onAnswer: handleAnswer, onBack: handleBack, onStartOver: handleStartOver };
}
