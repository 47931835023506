import { PROGRAM_TYPES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/program-types';
import { flow as flowFn, get, set } from 'lodash';
import {
  CUSTOMER_OF_QUESTION_ID,
  ENGINE_TYPE_QUESTION_ID,
} from 'site-modules/shared/components/incentives/incentives-wizard/questions/questions';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { getUtilityEvChargingOfferRebates } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-utility-ev-charging-offer-rebates';

function filterOutChargerInstallationQuestions(programs) {
  const updatedPrograms = programs;
  const chargerInstallation = updatedPrograms[PROGRAM_TYPES.CHARGER_INSTALLATION];
  const chargerInstallationIncentives = get(chargerInstallation, 'incentives', []);

  if (chargerInstallation && !getUtilityEvChargingOfferRebates(chargerInstallationIncentives).length) {
    const updatedFlow = updatedPrograms[PROGRAM_TYPES.CHARGER_INSTALLATION].flow.filter(
      question => question !== CUSTOMER_OF_QUESTION_ID
    );
    set(updatedPrograms, `[${PROGRAM_TYPES.CHARGER_INSTALLATION}].flow`, updatedFlow);
  }

  return updatedPrograms;
}

function filterOutEngineTypeQuestion(context) {
  return programs => {
    if (context.type === PUB_STATES_LOWERCASE.USED && context.engineTypes?.length > 1) {
      return programs;
    }

    return Object.fromEntries(
      Object.entries(programs).map(([program, programFlow]) => [
        program,
        { ...programFlow, flow: programFlow.flow.filter(step => step !== ENGINE_TYPE_QUESTION_ID) },
      ])
    );
  };
}

export function filterOutRedundantQuestions(context) {
  return flowFn([filterOutChargerInstallationQuestions, filterOutEngineTypeQuestion(context)]);
}
