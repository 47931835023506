import { EventToolbox } from 'client/utils/event-toolbox';
import { PRIVATE_PARTY_EVENTS } from 'site-modules/shared/constants/private-party/private-party-events';
import {
  PRIVATE_PARTY_ENTRY_CREATIVE_ID,
  PRIVATE_PARTY_SURVEY_CREATIVE_ID,
} from 'site-modules/shared/constants/private-party/private-party';
import { TrackingConstant } from 'client/tracking/constant';

function fireWidgetViewTracking({ detail: { creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
    event_data: {
      action_name: TrackingConstant.ACTION_WIDGET_VIEW,
      action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
      creative_id: creativeId,
    },
  });
}

function fireStartListingTracking({ detail: { value = 'Continue' } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_VIEW_CONTENT,
      subaction_name: TrackingConstant.START_PRIVATE_PARTY_LISTING,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: PRIVATE_PARTY_ENTRY_CREATIVE_ID,
      value,
    },
  });
}

function fireSubmitSurveyTracking({ detail: { value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      subaction_name: TrackingConstant.SUBMIT_SURVEY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: PRIVATE_PARTY_SURVEY_CREATIVE_ID,
      value,
    },
  });
}

function fireClosePopupTracking({ detail: { creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_SHOW_CONTENT,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.CLOSE_POPUP,
      creative_id: creativeId,
      value: 'close',
    },
  });
}

function fireEmailInputTracking({ detail: { isValid, creativeId } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: isValid ? TrackingConstant.EMAIL_INPUT_SUCCESS : TrackingConstant.EMAIL_INPUT_FAIL,
      creative_id: creativeId,
    },
  });
}

function fireEnterEmailYMMSTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_FILL_FORM,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.ENTER_EMAIL_YMMY,
      creative_id: creativeId,
      value,
    },
  });
}

function fireYearSelectTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_MMY_SELECT,
      action_cause: TrackingConstant.SELECT_CHANGE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.YEAR_SELECT,
      creative_id: creativeId,
      value,
    },
  });
}

function fireMakeSelectTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_MMY_SELECT,
      action_cause: TrackingConstant.SELECT_CHANGE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.MAKE_SELECT,
      creative_id: creativeId,
      value,
    },
  });
}

function fireModelSelectTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_MMY_SELECT,
      action_cause: TrackingConstant.SELECT_CHANGE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.MODEL_SELECT,
      creative_id: creativeId,
      value,
    },
  });
}

function fireStyleSelectTracking({ detail: { creativeId, value } }) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_MMY_SELECT,
      action_cause: TrackingConstant.SELECT_CHANGE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      subaction_name: TrackingConstant.STYLE_SELECT,
      creative_id: creativeId,
      value,
    },
  });
}

export const PrivatePartyEngagementHandler = {
  init: () => {
    EventToolbox.on(PRIVATE_PARTY_EVENTS.WIDGET_VIEW, fireWidgetViewTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.START_LISTING, fireStartListingTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.SUBMIT_SURVEY, fireSubmitSurveyTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.CLOSE_POPUP, fireClosePopupTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.EMAIL_INPUT, fireEmailInputTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.ENTER_EMAIL_YMMS, fireEnterEmailYMMSTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.YEAR_SELECT, fireYearSelectTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.MAKE_SELECT, fireMakeSelectTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.MODEL_SELECT, fireModelSelectTracking);
    EventToolbox.on(PRIVATE_PARTY_EVENTS.STYLE_SELECT, fireStyleSelectTracking);
  },
};
