import React, { Fragment, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { EditorialReviewEntities } from 'client/data/models/editorial-review';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { CORE_GALLERY_SIZE } from 'site-modules/core-page/utils/constants';
import { TopRatedRibbon } from 'site-modules/core-page/components/top-rated-ribbon/top-rated-ribbon';
import { withCoreMediaWrapper } from 'site-modules/shared/components/core-page/core-media-wrapper/core-media-wrapper';
import { buildSpinPhotosPath, PhotoflipperModel } from 'client/data/models/photoflipper';
import { getPhotoflipperProps } from 'site-modules/core-page/utils/photo-gallery';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { PhotoflipperButtonAsync } from 'site-modules/shared/components/photoflipper-core/photoflipper-button/photoflipper-button-async';
import { PhotoGalleryPhoto } from './photo-gallery-photo';
import { PhotoGalleryRow } from './photo-gallery-row';

import './photo-gallery.scss';

export function PhotoGalleryUI({
  params,
  vehicle,
  editorialReview,
  isPreprod,
  isUsed,
  isMobile,
  coreMedia,
  isConnect,
  hasSpinPhotos,
}) {
  const isTrackedPhotoProvider = useRef(false);

  const hasRowPhotos = !isMobile && !isConnect;

  const trackPhotosProvider = useCallback(
    rowPhotos => {
      if (isTrackedPhotoProvider.current || !coreMedia || (hasRowPhotos && !rowPhotos)) {
        return;
      }

      isTrackedPhotoProvider.current = true;

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_LIST,
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
          creative_id: 'photoflipper',
          photo_type: [coreMedia.heroImage]
            .concat(rowPhotos)
            .filter(v => v)
            .map(photo => get(photo, 'provider', ''))
            .join(),
        },
      });
    },
    [coreMedia, hasRowPhotos]
  );

  useEffect(trackPhotosProvider);

  if (!coreMedia) {
    return null;
  }

  const { heroImage, allMedia } = coreMedia;
  const photoflipperParams = { ...params, submodel: vehicle.submodels.name };
  const photoflipper360Props = getPhotoflipperProps({
    photoNum: 1,
    params: photoflipperParams,
    altMedia: allMedia,
    isPreprod,
    hasSpinPhotos,
    is360Tab: true,
  });

  return (
    <div className="photo-gallery pos-r mb-lg-0_25">
      <div className="outer-wrapper">
        {heroImage && (
          <Fragment>
            <PhotoGalleryPhoto
              photoData={heroImage}
              params={photoflipperParams}
              isPreprod={isPreprod}
              media={allMedia}
              isHeroPhoto
              hasSpinPhotos={hasSpinPhotos}
            />
            {isMobile && !isConnect && !hasSpinPhotos && (
              <i
                className="icon-images text-white rounded-8 p-0_75 mt-0_5 me-0_5"
                style={{ position: 'absolute', top: '0', right: '0' }}
                aria-hidden
              />
            )}
          </Fragment>
        )}
        {!isUsed && (
          <TopRatedRibbon
            className="mt-0_25"
            vehicleParams={{
              makeSlug: params.make,
              modelSlug: params.model,
              year: params.year,
              submodelSlug: vehicle.submodels.slug,
            }}
          />
        )}
        {hasSpinPhotos && (
          <PhotoflipperButtonAsync tag={Fragment} {...photoflipper360Props}>
            <button
              className="icon-360-container p-1 border-0 rounded-8 mt-0_5 me-0_5"
              data-tracking-id="open_photoflipper_360"
              data-tracking-parent="360-indication-top"
              style={{ position: 'absolute', top: '0', right: '0' }}
            >
              <img src={getStaticImageUrl('/icons/360.svg')} alt={'360°'} width={isMobile ? 50 : undefined} />
            </button>
          </PhotoflipperButtonAsync>
        )}
      </div>
      {hasRowPhotos && (
        <PhotoGalleryRow
          editorialReview={editorialReview}
          params={photoflipperParams}
          vehicle={vehicle}
          isPreprod={isPreprod}
          photoSize={CORE_GALLERY_SIZE.DESKTOP.ROW}
          trackPhotosProvider={trackPhotosProvider}
          hasSpinPhotos={hasSpinPhotos}
        />
      )}
    </div>
  );
}

PhotoGalleryUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  editorialReview: EditorialReviewEntities.EditorialReview,
  coreMedia: PropTypes.shape({
    heroImage: PropTypes.shape({
      num: PropTypes.number,
      title: PropTypes.string,
      src: PropTypes.string,
      isLifestylePhoto: PropTypes.bool,
    }),
    allMedia: PropTypes.shape({
      photos: PropTypes.arrayOf(PropTypes.shape({})),
      videos: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  isPreprod: PropTypes.bool,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
  isConnect: PropTypes.bool,
  hasSpinPhotos: PropTypes.bool,
};

PhotoGalleryUI.defaultProps = {
  editorialReview: null,
  coreMedia: null,
  isPreprod: false,
  isUsed: false,
  isMobile: false,
  isConnect: false,
  hasSpinPhotos: false,
};

export const PhotoGallery = connectToModel(withCoreMediaWrapper(PhotoGalleryUI), {
  hasSpinPhotos: bindToPath(
    ({
      params: { make, model, year },
      vehicle: {
        submodels: { name: submodel },
      },
      isUsed,
    }) => !isUsed && buildSpinPhotosPath({ make, model, year, submodel }),
    PhotoflipperModel,
    spins => !!get(spins, 'length')
  ),
});
