import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import Label from 'reactstrap/lib/Label';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildSubmodelsPath, VehicleModel } from 'client/data/models/vehicle-v2';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { getSubmodelName } from 'site-modules/shared/utils/core-page/page-helpers';
import { venomHistory } from 'client/utils/history/venom-history';
import { Select } from 'site-modules/shared/components/select/select';

export const ANCHOR_ID = 'features-highlights';

function SubmodelTrimSelectUI({ params, className, submodels, selectedSubmodelId, currentTrim, onTrimChange, trims }) {
  const onSelectSubmodel = useCallback(
    ({ submodelSlug }) =>
      venomHistory.push(`/${params.make}/${params.model}/${params.year}/${submodelSlug}#${ANCHOR_ID}`),
    [params]
  );

  if (!get(submodels, 'length') && !get(trims, 'length')) {
    return null;
  }

  const submodelsArray =
    submodels &&
    submodels.map(({ slug }) => ({
      label: getSubmodelName(slug, submodels),
      value: `${params.year}|${slug}`,
      submodelSlug: slug,
    }));
  const submodelName = submodelsArray && get(submodelsArray.find(({ value }) => value === selectedSubmodelId), 'label');

  return (
    <div className={classnames('submodel-trim-select d-flex align-items-center', className)}>
      {get(submodels, 'length', 0) > 1 && (
        <div className="d-flex align-items-center me-1">
          <Label for="features-submodel" className="mb-0">
            Type:{' '}
          </Label>
          <div className="pos-r ms-0_25">
            <Select
              id="features-submodel"
              name="features-submodel"
              inputClassName="border-0 pos-a top-0 left-0 w-100 h-100"
              value={selectedSubmodelId}
              options={submodelsArray}
              onChange={onSelectSubmodel}
              data-tracking-id="type_select"
            />
            <div className="pos-r select-overlay rounded bg-white text-primary-darker">
              {submodelName} <i className="icon-arrow-down3 size-10" aria-hidden />
            </div>
          </div>
        </div>
      )}
      {get(trims, 'length', 0) > 1 && (
        <div className="d-flex align-items-center">
          <Label for="features-trim" className="mb-0">
            Trim:{' '}
          </Label>
          <div className="pos-r ms-0_25">
            <Select
              id="features-trim"
              name="features-trim"
              inputClassName="border-0 pos-a top-0 left-0 w-100 h-100"
              options={trims}
              value={currentTrim.trimName}
              valueKey="trimName"
              labelKey="trimName"
              onChange={onTrimChange}
              data-tracking-id="trim_select"
            />
            <div className="pos-r select-overlay rounded bg-white text-primary-darker">
              {currentTrim.trimName} <i className="icon-arrow-down3 size-10" aria-hidden />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

SubmodelTrimSelectUI.propTypes = {
  params: CorePageParams.isRequired,
  onTrimChange: PropTypes.func.isRequired,
  currentTrim: PropTypes.shape({ trim: PropTypes.string, styleId: PropTypes.number }).isRequired,
  className: PropTypes.string,
  submodels: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, slug: PropTypes.string })),
  selectedSubmodelId: PropTypes.string,
  trims: PropTypes.arrayOf(PropTypes.shape({ trim: PropTypes.string, styleId: PropTypes.number })),
};

SubmodelTrimSelectUI.defaultProps = {
  className: null,
  submodels: null,
  selectedSubmodelId: null,
  trims: null,
};

export const SubmodelTrimSelect = connectToModel(SubmodelTrimSelectUI, {
  submodels: bindToPath(({ params }) => buildSubmodelsPath(params), VehicleModel),
});
