export const getInitialWizardState = ({
  incentives = [],
  initialStepIndex = 0,
  initialEligibleIncentivesIds = [],
  savedAnswers = {},
}) => ({
  incentives,
  eligibleIncentiveIds: initialEligibleIncentivesIds,
  flowSteps: [],
  stepMeta: {},
  currentStepIndex: initialStepIndex,
  activeCategory: '',
  error: null,
  isLoading: false,
  answers: savedAnswers,
  eligibilityQuestionsQualifiersMap: {},
  flowIncentives: incentives,
});
