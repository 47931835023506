import React from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { SafetyEntities } from 'client/data/models/safety';
import { safetyMap, SAFETY_MAP_CONSTANTS } from 'client/data/models/safety-map';
import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import { isNhtsaRatingsEmpty } from './utils';

const NOT_RATED = 'Not Rated';

/**
 * Get render ready value. If value not defined or empty - return 'Not Rated'.
 * @param {number|string|null|undefined} value - value to display
 * @param {string} appendix - value to add after value
 *
 * @returns {string}
 */
const getFormattedValue = (value, appendix) => (!(isNil(value) || value === '') ? `${value}${appendix}` : NOT_RATED);

export function formatNhtsaRatingItem(type, value) {
  let resultStr;

  switch (type) {
    case SAFETY_MAP_CONSTANTS.RATING:
      resultStr = getFormattedValue(value, ' / 5');
      break;
    case SAFETY_MAP_CONSTANTS.PERCENTAGE:
      resultStr = getFormattedValue(value, '%');
      break;
    case SAFETY_MAP_CONSTANTS.STRING:
    default:
      resultStr = value || 'No Tip';
      break;
  }

  return resultStr;
}

export function SafetyNhtsaRatings({ nhtsaRatings }) {
  if (isNhtsaRatingsEmpty(nhtsaRatings)) {
    return null;
  }

  const { overall = 0 } = nhtsaRatings;

  return (
    <div className="safety-nhtsa-ratings">
      <div className="d-flex justify-content-between justify-content-md-start">
        <p className="d-inline-block heading-5 mb-0_5">NHTSA Overall Rating</p>
        {!!overall && <RatingStars rating={overall} starClass="text-primary-darker" className="ms-0_5" />}
      </div>
      <div className="size-16 mb-0_5">
        The National Highway Transportation Safety Administration offers independent analysis.
      </div>
      <Row>
        {
          <Col tag="ul" xs={12} md={8} lg={6} className="list-unstyled py-0 px-0_5 mb-0">
            {Object.keys(safetyMap.nhtsaRatings).map(ratingGroupKey => {
              const ratingGroup = safetyMap.nhtsaRatings[ratingGroupKey];

              return (
                <li key={ratingGroupKey} className="pt-1 mb-1_5">
                  <div className="heading-5 mb-0_5">{ratingGroup.name}</div>
                  {Object.keys(ratingGroup.values).map(ratingItemKey => {
                    const ratingItem = ratingGroup.values[ratingItemKey];
                    const ratingItemValue = get(nhtsaRatings, `${ratingGroupKey}.${ratingItemKey}`);

                    return (
                      <div key={ratingItemKey} className="d-flex justify-content-between mb-0_5">
                        <span>{ratingItem.name}</span>
                        <span>{formatNhtsaRatingItem(ratingItem.type, ratingItemValue)}</span>
                      </div>
                    );
                  })}
                </li>
              );
            })}
          </Col>
        }
      </Row>
    </div>
  );
}

SafetyNhtsaRatings.propTypes = {
  nhtsaRatings: SafetyEntities.NhtsaRatings,
};

SafetyNhtsaRatings.defaultProps = {
  nhtsaRatings: null,
};
