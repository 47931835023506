import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { StepProps } from 'site-modules/shared/components/incentives/incentives-wizard/prop-types/step-props';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';
import { fireScreenTracking } from 'site-modules/shared/components/incentives/incentives-wizard/utils/wizard-tracking';

import './base-screen-step.scss';

export const BaseScreenStep = memo(props => {
  const { stepConfig, stepMeta, children, context, ...rest } = props;
  const { creativeId } = rest;

  useEffect(() => {
    fireScreenTracking({
      stepId: stepConfig.id,
      trackingSubaction: stepConfig.trackingSubaction(stepMeta),
      creativeId,
    });
  }, [stepConfig, creativeId, stepMeta]);

  return (
    <BaseWizardStep stepConfig={stepConfig} context={context} stepMeta={stepMeta} {...rest}>
      <div className="base-screen-step">
        <h2 className={classnames('mb-1_5 text-center size-24 fw-bold', stepConfig.titleClassName)}>
          {stepConfig.title(context)}
        </h2>
        {children}
      </div>
    </BaseWizardStep>
  );
});

BaseScreenStep.displayName = 'BaseScreenStep';

BaseScreenStep.propTypes = {
  ...StepProps,
  children: PropTypes.node,
};
BaseScreenStep.defaultProps = {
  children: null,
};
