import { useCallback, useEffect } from 'react';
import {
  fireAnswerTracking,
  fireQuestionTracking,
} from 'site-modules/shared/components/incentives/incentives-wizard/utils/wizard-tracking';

export const useQuestionTracking = ({ stepConfig, creativeId, stepMeta }) => {
  useEffect(() => {
    fireQuestionTracking({
      trackingSubaction: stepConfig.trackingSubaction(stepMeta),
      creativeId,
    });
  }, [stepConfig, creativeId, stepMeta]);

  const handleAnswerTracking = useCallback(
    value => {
      fireAnswerTracking({
        trackingSubaction: stepConfig.trackingSubaction(stepMeta),
        creativeId,
        stepId: stepConfig.id,
        value,
      });
    },
    [creativeId, stepConfig, stepMeta]
  );

  return [handleAnswerTracking];
};
