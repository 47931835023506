import { stubTrue } from 'lodash';
import { isFederalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-federal-rebate';
import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import { isLocalRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-local-rebate';
import { isOEMRebate } from 'site-modules/shared/components/incentives/incentives-wizard/utils/is-oem-rebate';

const getEligibilityFederalRebatesStatus = (eligibleIncentiveIds, allIncentives) =>
  !!eligibleIncentiveIds.filter(incentiveId => isFederalRebate(allIncentives.find(({ id }) => id === incentiveId)))
    .length;
const getEligibilityLocalRebatesStatus = (eligibleIncentiveIds, allIncentives) =>
  !!eligibleIncentiveIds.filter(incentiveId => {
    const incentive = allIncentives.find(({ id }) => id === incentiveId);
    return isLocalRebate(incentive) || isOEMRebate(incentive);
  }).length;

const checkFunctionMap = {
  [CATEGORIES.FEDERAL]: getEligibilityFederalRebatesStatus,
  [CATEGORIES.LOCAL]: getEligibilityLocalRebatesStatus,
  [CATEGORIES.OEM]: stubTrue,
};

export const checkCategoryIncentivesEligible = (category, eligibleIncentiveIds, allIncentives) => {
  const checkFunction = checkFunctionMap[category];
  return checkFunction(eligibleIncentiveIds, allIncentives);
};
