import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SELL_CAR_STEPS, STATUS } from 'site-modules/shared/constants/private-party/private-party';

import './sell-car-steps.scss';

export function SellCarSteps({ isMobile, steps, isUsedCore }) {
  const currentSteps = isMobile ? steps.filter((step, index) => index !== 1) : steps;
  return (
    <div
      className={classnames('steps-container mb-1_5 mx-auto', {
        'used-core': isUsedCore,
      })}
    >
      <ul className="d-flex justify-content-between p-0 my-1_5 steps-list">
        {currentSteps.map(({ step, status, textClass, indicatorClass }) => {
          const isChecked = status === STATUS.CHECKED;
          const isActive = status === STATUS.ACTIVE;
          const isDisabled = status === STATUS.DISABLED;
          return (
            <li
              className={classnames('step-item d-flex flex-column align-items-center pos-r', {
                disabled: isDisabled,
              })}
              key={step}
            >
              <div
                className={classnames(
                  'step-indicator d-flex rounded-50 align-items-center justify-content-center',
                  indicatorClass,
                  { active: isActive }
                )}
              >
                {isChecked && <i aria-hidden className="icon-checkmark text-white text-center" />}
              </div>
              <div className={classnames('step-name text-center', textClass)}>{step}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

SellCarSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape()),
  isMobile: PropTypes.bool,
  isUsedCore: PropTypes.bool,
};

SellCarSteps.defaultProps = {
  steps: SELL_CAR_STEPS,
  isMobile: false,
  isUsedCore: false,
};
