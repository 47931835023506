import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import { get, noop } from 'lodash';
/* Constants */
import { PRIVATE_PARTY_EVENTS } from 'site-modules/shared/constants/private-party/private-party-events';
import {
  PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
  PRIVATE_PARTY_SURVEY,
  SELL_CAR_STEPS_TED6586,
} from 'site-modules/shared/constants/private-party/private-party';
import { PUB_STATES } from 'client/constants/pub-states';
import { DROPDOWN_NAMES } from 'site-modules/shared/components/vehicle-dropdowns/constants';
/* Utils */
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { EventToolbox } from 'client/utils/event-toolbox';
/* Components */
import { Link } from 'site-modules/shared/components/link/link';
import { SellCarSteps } from 'site-modules/shared/components/private-party/sell-car-steps/sell-car-steps';
import { YearDropdown } from 'site-modules/shared/components/ymms-dropdowns/year-dropdown/year-dropdown';
import { MakeDropdown } from 'site-modules/shared/components/ymms-dropdowns/make-dropdown/make-dropdown';
import { ModelDropdown } from 'site-modules/shared/components/ymms-dropdowns/model-dropdown/model-dropdown';
import { StylesDropdown } from 'site-modules/shared/components/ymms-dropdowns/styles-dropdown/styles-dropdown';

import './private-party-listing-setup.scss';

export function PrivatePartyListingSetup({ onClick, isMobile }) {
  const [isStyleSelected, setIsStyleSelected] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [style, setStyle] = useState({});
  const isAppExtensionPage = useSelector(state => get(state, 'pageContext.isAppExtensionPage'));
  const extension = isAppExtensionPage ? 'app' : 'html';
  const pubStates = [PUB_STATES.USED, PUB_STATES.NEW_USED];
  const cta = 'Continue to Car Condition';

  useEffect(() => {
    setIsEnabled(isEmailValid && isStyleSelected);
  }, [isEmailValid, isStyleSelected]);

  const handleBlur = useCallback(({ currentTarget: { value } }) => {
    const isValid = validation.validateEmail(value);
    EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.EMAIL_INPUT, {
      isValid,
      creativeId: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
    });
    setIsEmailValid(isValid);
  }, []);

  const handleClick = useCallback(() => {
    EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.ENTER_EMAIL_YMMS, {
      creativeId: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
      value: cta,
    });

    onClick(PRIVATE_PARTY_SURVEY);
  }, [onClick]);

  const setYear = useCallback(({ year }) => {
    setVehicle({
      year,
      make: null,
      model: null,
    });

    EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.YEAR_SELECT, {
      value: year,
      creativeId: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
    });
  }, []);

  const setMake = useCallback(
    ({ niceName }) => {
      setVehicle({
        ...vehicle,
        make: niceName,
        model: null,
      });

      EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.MAKE_SELECT, {
        value: niceName,
        creativeId: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
      });
    },
    [vehicle]
  );

  const setModel = useCallback(
    ({ niceId }) => {
      setVehicle({
        ...vehicle,
        model: niceId,
      });
      EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.MODEL_SELECT, {
        value: niceId,
        creativeId: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
      });
    },
    [vehicle]
  );

  const changeStyle = useCallback(newStyle => {
    setStyle(newStyle);
    setIsStyleSelected(true);
    EventToolbox.fireCustomEvent(PRIVATE_PARTY_EVENTS.STYLE_SELECT, {
      value: newStyle?.name,
      creativeId: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
    });
  }, []);

  return (
    <div
      className="private-party-modal-content bg-white py-1 py-md-2 px-1 px-md-2_5 mt-1_5"
      data-tracking-parent={PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID}
    >
      <h2 className="size-24 mb-1 text-center">Let&apos;s Set Up Your Listing</h2>
      <SellCarSteps steps={SELL_CAR_STEPS_TED6586} />
      <div>
        <fieldset>
          <legend className="size-16 fw-bold">Email</legend>
          <Input
            className="size-16 mb-1 py-0_5"
            placeholder={isMobile ? 'Enter email' : 'Enter your email address'}
            type="email"
            id="email"
            name="email"
            aria-label="Enter Email"
            onBlur={handleBlur}
          />
        </fieldset>
        <fieldset>
          <legend className="size-16 fw-bold">Your Car</legend>
          <Row className="mb-md-1">
            <Col xs={12} md={6} className="mb-1 mb-md-0">
              <YearDropdown
                name="select-year"
                toggle="Year"
                valueKey="year"
                labelKey="year"
                labelText="Year"
                value={vehicle?.year}
                onChange={setYear}
                isStyled
                pubStates={pubStates}
                isLabelHidden
                id="year"
              />
            </Col>
            <Col xs={12} md={6} className="mb-1 mb-md-0">
              <MakeDropdown
                id="make"
                year={vehicle?.year}
                name="select-make"
                toggle="Make"
                valueKey="niceName"
                labelKey="name"
                labelText="Make"
                onChange={setMake}
                value={vehicle?.make}
                isStyled
                isLabelHidden
                pubStates={pubStates}
                disabled={!vehicle.year}
                enableSpinner={!!vehicle?.year}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="mb-1 mb-md-0">
              <ModelDropdown
                make={vehicle?.make}
                year={vehicle?.year}
                id="model"
                name={DROPDOWN_NAMES.MODEL}
                toggle="Model"
                valueKey="niceId"
                labelKey="name"
                labelText="Model"
                value={vehicle?.model}
                onChange={setModel}
                isStyled
                isLabelHidden
                pubStates={pubStates}
                disabled={!vehicle.make}
                enableSpinner={!!vehicle?.make && !!vehicle?.year}
              />
            </Col>
            <Col xs={12} md={6}>
              <StylesDropdown
                id="style"
                name={DROPDOWN_NAMES.STYLE}
                make={vehicle?.make}
                model={vehicle?.model}
                year={vehicle?.year}
                value={style?.slug}
                toggle="Style"
                valueKey="slug"
                labelKey="name"
                labelText="Style"
                onChange={changeStyle}
                pubStates={pubStates}
                isStyled
                isLabelHidden
                hasOptionTruncate
                withoutSubmodel
                disabled={!vehicle.model}
                enableSpinner={!!vehicle?.make && !!vehicle?.model && !!vehicle?.year}
              />
            </Col>
          </Row>
        </fieldset>

        <div className="d-flex justify-content-center">
          <Button
            disabled={!isEnabled}
            onClick={handleClick}
            className={classnames('text-white my-1 my-md-1_5 text-transform-none size-16 fw-bold py-0_5 px-2', {
              'bg-cool-gray-60': !isEnabled,
              'bg-blue-50': isEnabled,
            })}
          >
            {cta}
          </Button>
        </div>
      </div>
      <div className="size-12 text-cool-gray-40 d-flex justify-content-between align-items-center form-disclaimer mx-auto">
        <i className="icon-shield-check text-cool-gray-50 size-24 me-0_25" aria-hidden />
        <span>
          By clicking Continue, I accept the terms of the{' '}
          <Link
            to={`/about/visitor-agreement.${extension}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-underline"
          >
            Edmunds Visitor Agreement. Our collection and use of your personal information.
          </Link>
        </span>
      </div>
    </div>
  );
}

PrivatePartyListingSetup.propTypes = {
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

PrivatePartyListingSetup.defaultProps = {
  onClick: noop,
  isMobile: false,
};
