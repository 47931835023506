export const PRIVATE_PARTY_EVENTS = {
  WIDGET_VIEW: 'private-party.widget_view',
  START_LISTING: 'private-party.start_listing',
  SUBMIT_SURVEY: 'private-party.submit_survey',
  CLOSE_POPUP: 'private-party.close_popup',
  EMAIL_INPUT: 'private-party.email_input',
  ENTER_EMAIL_YMMS: 'private-party.enter_email_ymms',
  YEAR_SELECT: 'private-party.year_select',
  MAKE_SELECT: 'private-party.make_select',
  MODEL_SELECT: 'private-party.model_select',
  STYLE_SELECT: 'private-party.style_select',
};
