export const STATUS = { CHECKED: 'checked', ACTIVE: 'active', DISABLED: 'disabled' };

export const SELL_CAR_STEPS = [
  {
    status: STATUS.CHECKED,
    step: 'Car \nDetails',
    textClass: 'text-turquoise-10',
    indicatorClass: 'bg-turquoise-40',
  },
  {
    status: STATUS.CHECKED,
    step: 'Condition',
    textClass: 'text-turquoise-10',
    indicatorClass: 'bg-turquoise-40',
  },
  {
    status: STATUS.CHECKED,
    step: 'Pricing \nReport',
    textClass: 'text-turquoise-10',
    indicatorClass: 'bg-turquoise-40',
  },
  {
    status: STATUS.ACTIVE,
    step: 'Set \nPrice',
    textClass: 'text-blue-50 fw-bold',
    indicatorClass: 'bg-white',
  },
  {
    status: STATUS.DISABLED,
    step: 'Upload \nPhotos',
    textClass: 'text-cool-gray-50',
    indicatorClass: 'bg-cool-gray-70',
  },
];

export const PRIVATE_PARTY_ENTRY_CREATIVE_ID = 'edm-entry-private-party';
export const PRIVATE_PARTY_SURVEY_CREATIVE_ID = 'private-party-survey';
export const PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID = 'private-party-email-ymms';

export const PRIVATE_PARTY_SURVEY = 'privatePartySurvey';
export const PRIVATE_PARTY_THANK_YOU = 'privatePartyThankYou';
export const PRIVATE_PARTY_LISTING_SETUP = 'privatePartyListingSetup';

export const PRIVATE_PARTY_CREATIVE_ID = {
  [PRIVATE_PARTY_LISTING_SETUP]: PRIVATE_PARTY_EMAIL_YMMS_CREATIVE_ID,
  [PRIVATE_PARTY_SURVEY]: PRIVATE_PARTY_SURVEY_CREATIVE_ID,
  [PRIVATE_PARTY_THANK_YOU]: PRIVATE_PARTY_ENTRY_CREATIVE_ID,
};

export const PRIVATE_PARTY_SURVEY_LIST = [
  "Having the buyer's identity verified",
  'Having a secure payment method',
  'Having online paperwork and documentation',
  'Completing paperwork',
  'Online price negotiation',
  'I trust Edmunds',
];
export const OTHER = 'other';

export const MODAL_THANK_YOU_BG_HEIGHT = 74;
export const MODAL_BG_HEIGHT = 212;
export const MODAL_MOBILE_BG_HEIGHT = 270;

/* START */
/* TODO: Should be removed when clean up TED-6586-PrivateParty-Vaporware-UCL */

export const PRIVATE_PARTY_SELL_CAR_TED6586 = {
  'chal-1': {
    imageSrc: '/img/car-buying/private-party/lady-in-chair.png',
    benefits: [
      `List your car on one of the largest automotive marketplaces for <strong>FREE</strong>`,
      'Sellers typically make more selling to individuals rather than dealers',
      'Secure and Safe—Identity verification, secure online transaction available and more',
    ],
  },
  'chal-2': {
    benefits: [
      {
        title: 'List your car for FREE',
        description: 'Have your car seen on one of the largest automotive marketplaces',
        imgSrc: '/img/car-buying/private-party/phone.svg',
        width: 60,
        imgSrcMobile: '/img/car-buying/private-party/phone-mobile.svg',
      },
      {
        title: 'Secure online transaction option',
        description:
          'Access a secure online transaction platform with buyer financing options, identity verification, and more',
        imgSrc: '/img/car-buying/private-party/handshake.svg',
        width: 120,
        imgSrcMobile: '/img/car-buying/private-party/handshake-mobile.svg',
      },
      {
        title: 'Sell your car for more',
        description: "You'll typically get a higher price for your car than selling to dealers",
        imgSrc: '/img/car-buying/private-party/sell-car.svg',
        width: 110,
        imgSrcMobile: '/img/car-buying/private-party/sell-car-mobile.svg',
      },
    ],
  },
};

export const SELL_CAR_STEPS_TED6586 = [
  {
    status: STATUS.ACTIVE,
    step: 'Your \nInfo',
    textClass: 'text-blue-50 fw-bold',
    indicatorClass: 'bg-white',
  },
  {
    status: STATUS.DISABLED,
    step: 'Car \nCondition',
    textClass: 'text-cool-gray-50',
    indicatorClass: 'bg-cool-gray-70',
  },
  {
    status: STATUS.DISABLED,
    step: 'Upload \nPhotos',
    textClass: 'text-cool-gray-50',
    indicatorClass: 'bg-cool-gray-70',
  },
  {
    status: STATUS.DISABLED,
    step: 'Set \nPrice',
    textClass: 'text-cool-gray-50',
    indicatorClass: 'bg-cool-gray-70',
  },
];

export const PRIVATE_PARTY_SELL_CAR_CTA = 'List My Car to Sell';
export const IMG_WIDTH_MOBILE = 58;

/* TODO: Should be removed when clean up TED-6628-PrivateParty-Vaporware-UsedCore */
export const PRIVATE_PARTY_SELL_CAR_TED6628 = {
  'chal-1': {
    imageSrc: '/img/car-buying/private-party/lady-in-chair-chilling.png',
    imageSrcMobile: '/img/car-buying/private-party/lady-in-chair.png',
    benefits: [
      `List your car on one of the largest auto marketplaces for <strong>FREE</strong>`,
      'Sellers typically make more selling to individuals versus dealers',
      'Identity verification, secure online payment and more',
    ],
    benefitsMobile: [
      `List your car for <strong>FREE</strong>`,
      'Make more selling to an individual',
      'Secure online payment available',
    ],
  },
  'chal-2': {
    imageSrc: '/img/car-buying/private-party/lady-in-chair-chilling.png',
    imageSrcMobile: '/img/car-buying/private-party/lady-in-chair.png',
    benefits: [
      `List your car on one of the largest auto marketplaces for <strong>FREE</strong>`,
      'Sellers typically make more selling to individuals versus dealers',
      'Identity verification, secure online payment and more',
    ],
    benefitsMobile: [
      `List your car for <strong>FREE</strong>`,
      'Make more selling to an individual',
      'Secure online payment available',
    ],
  },
  'chal-3': {
    header: 'Sell your car on Edmunds',
    benefits: [`List your car for <strong>FREE</strong>`, 'Identity verification', 'Secure payment available'],
    imageSrc: '/img/car-buying/private-party/icon_coin_falling_into_hand.svg',
  },
  'chal-4': {
    benefits: [
      {
        icon: '/img/car-buying/private-party/car_on_phone.svg',
        width: 26,
        text: 'List your car \nfor FREE',
        textMobile: 'List your \ncar for \nFREE',
      },
      { icon: '/img/car-buying/private-party/security_shield_check.svg', width: 27, text: 'Secure \npayment' },
      {
        icon: '/img/car-buying/private-party/car_coin.svg',
        width: 24,
        text: 'Sell your car \nfor more',
        textMobile: 'Sell your \ncar for more',
      },
    ],
  },
};

export const SELL_CAR_STEPS_TED6628 = [
  {
    status: STATUS.ACTIVE,
    step: 'Car \nDetails',
    textClass: 'text-blue-40 fw-bold size-14',
    indicatorClass: 'bg-blue-90 pos-r',
  },
  {
    status: STATUS.DISABLED,
    step: 'Set \nPrice',
    textClass: 'text-cool-gray-40 size-14 pt-0_75',
    indicatorClass: 'bg-cool-gray-70',
  },
  {
    status: STATUS.DISABLED,
    step: 'Upload \nPhotos',
    textClass: 'text-cool-gray-40 size-14 pt-0_75',
    indicatorClass: 'bg-cool-gray-70',
  },
  {
    status: STATUS.DISABLED,
    step: 'List \nCar',
    textClass: 'text-cool-gray-40 size-14 pt-0_75',
    indicatorClass: 'bg-cool-gray-70',
  },
];
