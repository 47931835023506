import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get, uniqBy } from 'lodash';
import classnames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildFuelCostsPath, FeatureSpecsEntities, VehicleEntities, VehicleModel } from 'client/data/models/vehicle-v2';
import { getEvTaxCreditAmountPath, IncentiveModel } from 'client/data/models/incentives';
import { INCENTIVE_TYPES } from 'site-modules/shared/constants/incentives';
import { FEATURE_SPEC_TOOLTIP, FEATURE_SPEC_TOOLTIP_MAP } from 'site-modules/shared/constants/features-specs';
import { formatStyleDetails, getFeatureValue, isAvailable } from 'site-modules/shared/utils/features-specs/utils';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { numberWithCommasForString } from 'site-modules/shared/utils/string';
import { withTargetText } from 'site-modules/shared/utils/url-utils';
import { capitalizeEachWord } from 'client/utils/string-utils';
import { getDealsUrl, getEvIncentivesUrl } from 'site-modules/shared/utils/core-link-constructor';
import { Link } from 'site-modules/shared/components/link/link';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Select } from 'site-modules/shared/components/select/select';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { CoreIncentivesWizard } from 'site-modules/core-page/components/core-incentives-wizard/core-incentives-wizard';

const DRIVETRAIN_SELECT = 'features-drivetrain';

function FeaturesListUI({
  vehicleParams,
  className,
  totalMsrp,
  styleId,
  stylesFeatures,
  styleEvTestedData,
  fuelCostsData,
  evTaxRebateAmount,
  onStyleChange,
  disableEvIncentivesWizard,
  showTaxRebates,
  isUsed,
  isMobile,
}) {
  const { makeName, modelName, makeSlug, modelSlug, year } = vehicleParams;
  const styleFeatures = stylesFeatures.find(({ id }) => id === styleId);
  const {
    baseMSRP,
    mpg,
    totalSeating,
    driveType,
    engineType,
    basicWarranty,
    measurements: { cargoAllSeats },
    electric: { mpge, isElectric, isPluginElectric, timeToCharge, eRange, batteryWarranty },
    truck: { isTruck, maxTowingCapacity },
  } = formatStyleDetails(styleFeatures);
  const costPerMonth = get(fuelCostsData, 'fuelCosts.costPerMonth', 0);
  const evTestedRange = get(styleEvTestedData, 'range');

  const onMpgeTooltipOpen = useCallback(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_VIEW_CONTENT,
        subaction_name: TrackingConstant.VIEW_TOOLTIP,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        creative_id: 'features-and-specs',
        value: 'MPGe',
      },
    });
  }, []);

  const evRebatesFragment = (
    <Fragment>
      {getPriceString(evTaxRebateAmount, 'Not available')}
      <div className="small">
        <Link
          to={
            isUsed
              ? getEvIncentivesUrl({
                  makeName,
                  modelName,
                  vehCondition: 'USED',
                  incentiveType: 'Tax Credit',
                })
              : withTargetText(
                  getDealsUrl(vehicleParams, { styleId: styleFeatures.id, section: INCENTIVE_TYPES.OTHER }),
                  { text: 'federal credit' }
                )
          }
          rel={isUsed ? 'nofollow' : undefined}
          className="text-primary-darker"
          data-tracking-id="view_ev_tax_credit"
        >
          Based on income
        </Link>
      </div>
    </Fragment>
  );

  const driveTrainOptions = useMemo(
    () =>
      uniqBy(
        stylesFeatures
          .filter(({ trim }) => trim === styleFeatures.trim)
          .map(({ id, features }) => ({
            id,
            driveTrain: capitalizeEachWord(getFeatureValue(features, 'standard["Drive Train"]', 'Drive type')),
          })),
        'driveTrain'
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [styleFeatures.trim]
  );

  const featuresMap = [
    {
      label: 'Total MSRP',
      show: !!totalMsrp,
      datapoints: [totalMsrp],
      value: getPriceString(totalMsrp),
    },
    {
      key: 'Base MSRP',
      label: (
        <Fragment>
          Base MSRP <div className="small">Excludes Destination Fee</div>
        </Fragment>
      ),
      show: !totalMsrp && !!baseMSRP,
      datapoints: [baseMSRP],
      value: getPriceString(baseMSRP),
    },
    {
      label: 'EV Tax Credits & Rebates',
      show: showTaxRebates,
      datapoints: [],
      value: (
        <Fragment>
          {disableEvIncentivesWizard ? (
            evRebatesFragment
          ) : (
            <Experiment name="core-6558-ev-specs" showDefault>
              <Recipe name="ctrl" isDefault>
                {evRebatesFragment}
              </Recipe>
              <Recipe name="chal">
                <CoreIncentivesWizard
                  styleId={styleId}
                  makeName={makeName}
                  modelName={modelName}
                  makeSlug={makeSlug}
                  modelSlug={modelSlug}
                  year={year}
                  engineType={engineType}
                  isUsed={isUsed}
                  isMobile={isMobile}
                />
              </Recipe>
            </Experiment>
          )}
        </Fragment>
      ),
    },
    {
      label: 'Engine Type',
      show: true,
      datapoints: [engineType],
      value: engineType,
    },
    {
      label: 'Edmunds Tested Electric Range',
      show: !!evTestedRange,
      datapoints: [evTestedRange],
      valueClassName: 'd-flex flex-column justify-content-center align-items-end',
      value: (
        <Fragment>
          {evTestedRange} miles
          {isAvailable(eRange) && <div className="small">vs EPA Range {eRange.replace('mi.', 'miles')}</div>}
        </Fragment>
      ),
    },
    {
      label: 'EPA Electric Range',
      show: !evTestedRange && (isElectric || isPluginElectric),
      datapoints: [eRange],
      value: `${eRange.replace('mi.', 'miles')}`,
    },
    {
      label: 'Combined MPG',
      show: !isElectric && !isPluginElectric,
      datapoints: [mpg.combined],
      value: `${mpg.combined} MPG`,
    },
    {
      key: 'Combined MPGe',
      label: (
        <Fragment>
          Combined MPGe <div className="small">(Electric + Gas)</div>
        </Fragment>
      ),
      show: isPluginElectric,
      datapoints: [mpge.combined],
      value: (
        <Fragment>
          {mpge.combined} MPGe
          <TooltipItem
            id="ev-mpge-tooltip"
            className="top-0"
            item={{
              text: FEATURE_SPEC_TOOLTIP[FEATURE_SPEC_TOOLTIP_MAP.MPGE_FULL],
              placement: 'top',
              boundariesElement: 'viewport',
            }}
            onTooltipOpen={onMpgeTooltipOpen}
          />
        </Fragment>
      ),
    },
    {
      label: 'Cost to Drive',
      show: true,
      datapoints: [costPerMonth],
      value: `${getPriceString(costPerMonth)}/month`,
    },
    {
      label: 'Total Charging Time (240V)',
      show: isElectric || isPluginElectric,
      datapoints: [timeToCharge],
      value: `${timeToCharge.replace('hr.', 'hours')}`,
    },
    {
      label: 'Seating',
      show: true,
      datapoints: [totalSeating],
      value: `${totalSeating} seats`,
    },
    {
      key: 'Cargo Capacity',
      label: (
        <Fragment>
          Cargo Capacity <div className="small">All Seats In Place</div>
        </Fragment>
      ),
      show: true,
      datapoints: [cargoAllSeats],
      value: cargoAllSeats,
    },
    {
      key: 'Drivetrain',
      label:
        driveTrainOptions.length > 1 ? (
          <label className="mb-0" htmlFor={DRIVETRAIN_SELECT}>
            Drivetrain
          </label>
        ) : (
          'Drivetrain'
        ),
      show: true,
      datapoints: [driveType],
      valueClassName: classnames('text-capitalize', {
        'd-flex justify-content-end align-items-start': driveTrainOptions.length > 1,
      }),
      value:
        driveTrainOptions.length > 1 ? (
          <div className="drivetrain-select pos-r">
            <Select
              id={DRIVETRAIN_SELECT}
              name={DRIVETRAIN_SELECT}
              inputClassName="border-0 pos-a top-0 left-0 w-100 h-100"
              options={driveTrainOptions}
              value={capitalizeEachWord(driveType)}
              valueKey="driveTrain"
              labelKey="driveTrain"
              aria-label="Select a drivetrain"
              onChange={onStyleChange}
              data-tracking-id="drivetrain_select"
            />
            <div className="pos-r select-overlay rounded bg-white text-primary-darker fw-normal">
              {driveType} <i className="icon-arrow-down3 size-10" aria-hidden />
            </div>
          </div>
        ) : (
          driveType
        ),
    },
    {
      label: 'Max Towing Capacity',
      show: isTruck,
      datapoints: [maxTowingCapacity],
      value: maxTowingCapacity,
    },
    {
      label: 'Warranty',
      show: true,
      datapoints: [basicWarranty],
      value: numberWithCommasForString(basicWarranty.replace('yr.', 'years ').replace('mi.', 'miles')),
    },
    {
      label: 'EV Battery Warranty',
      show: isElectric || isPluginElectric,
      datapoints: [batteryWarranty],
      value: numberWithCommasForString(batteryWarranty.replace('yr.', 'years ').replace('mi.', 'miles')),
    },
  ].filter(({ show, datapoints, value }) => show && datapoints.every(isAvailable) && value);

  const breakIndex = Math.ceil(featuresMap.length / 2);

  return (
    <table className={classnames('features-list w-100', className)}>
      <tbody
        style={
          isMobile
            ? {}
            : {
                display: 'grid',
                gridTemplateColumns: '50% 50%',
                gridTemplateRows: `repeat(${breakIndex}, auto)`,
                gridAutoFlow: 'column',
                marginLeft: '-1rem',
                marginRight: '-1rem',
              }
        }
      >
        {featuresMap.map(({ key, label, value, valueClassName }, index) => (
          <tr
            key={key || label}
            className={classnames('h-100 d-flex', {
              'px-1': !isMobile,
              'border-end': !isMobile && index < breakIndex,
              'border-bottom': (isMobile || index + 1 !== breakIndex) && index + 1 !== featuresMap?.length,
            })}
          >
            <th scope="row" className="col-auto fw-normal py-1" style={{ maxWidth: '60%' }}>
              {label}
            </th>
            <td className={classnames('col fw-bold py-1', valueClassName)} align="right">
              {value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

FeaturesListUI.propTypes = {
  vehicleParams: PropTypes.shape({
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    makeName: PropTypes.string,
    modelName: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  styleId: PropTypes.number.isRequired,
  onStyleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  totalMsrp: PropTypes.number,
  stylesFeatures: PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity),
  styleEvTestedData: VehicleEntities.StyleEvTestedData,
  fuelCostsData: VehicleEntities.StyleFuelCostData,
  evTaxRebateAmount: PropTypes.number,
  disableEvIncentivesWizard: PropTypes.bool,
  showTaxRebates: PropTypes.bool,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

FeaturesListUI.defaultProps = {
  className: null,
  totalMsrp: null,
  stylesFeatures: undefined,
  styleEvTestedData: undefined,
  fuelCostsData: null,
  evTaxRebateAmount: null,
  disableEvIncentivesWizard: false,
  showTaxRebates: false,
  isUsed: false,
  isMobile: false,
};

export const propsAreEqual = (prevProps, { fuelCostsData, evTaxRebateAmount, showTaxRebates }) =>
  !fuelCostsData || (showTaxRebates && evTaxRebateAmount === null);
export const FeaturesList = connectToModel(React.memo(FeaturesListUI, propsAreEqual), {
  fuelCostsData: bindToPath(({ styleId }) => buildFuelCostsPath(styleId), VehicleModel),
  evTaxRebateAmount: bindToPath(
    ({ styleId, showTaxRebates, isUsed }) => showTaxRebates && getEvTaxCreditAmountPath(styleId, isUsed),
    IncentiveModel
  ),
  disableEvIncentivesWizard: bindToPath('disable-ev-incentives-wizard', FeatureFlagModel),
});
