import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export function SellCarBenefits({ benefits, wrapperClasses, iconClasses, benefitItemClasses }) {
  return (
    <ul className={classnames('list-unstyled', wrapperClasses)}>
      {benefits.map((item, index) => (
        <li
          key={`benefit_${index + 1}`}
          className={classnames('benefit-item d-flex align-items-center', benefitItemClasses)}
        >
          <i aria-hidden className={classnames('icon-checkmark me-0_75', iconClasses)} />
          <ContentFragment>{item}</ContentFragment>
        </li>
      ))}
    </ul>
  );
}

SellCarBenefits.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
  wrapperClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  benefitItemClasses: PropTypes.string,
};

SellCarBenefits.defaultProps = {
  benefits: [],
  wrapperClasses: '',
  iconClasses: 'text-green-50',
  benefitItemClasses: 'py-0_5',
};
