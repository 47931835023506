import React, { memo } from 'react';
import { BaseWizardStep } from 'site-modules/shared/components/incentives/incentives-wizard/steps/base-wizard-step';

export const CheckingEligibilityScreen = memo(props => (
  <BaseWizardStep {...props}>
    <div style={{ minHeight: '517px' }} />
  </BaseWizardStep>
));

CheckingEligibilityScreen.displayName = 'CheckingEligibilityScreen';
